<template>
    <div class="parent">
        <v-container>
            
            <!-- About us -->
            <!-- <v-card flat>
                <v-img max-width="500" class="white--text align-end" src="https://img.freepik.com/free-vector/about-us-concept-illustration_114360-669.jpg?size=626&ext=jpg"></v-img>
            </v-card>
    
            <h3 style="text-align:center;color: black;">Present your soft skill set </h3>
            <h3 style="text-align:center;color: #2196F3;">with Preskilet !</h3>
            <br> -->
            
            <!-- Ad -->
            <!-- <v-card flat>
                <v-img max-width="500" class="white--text align-end" :src="vectorImages.homePageAnimtaion"></v-img>
            </v-card>
            <br> -->
            
            <v-layout row wrap justify-space-around>
                        <!-- Present your soft skill set -->
                        <!-- <v-col>
                            <v-card flat>
                                    <div>
                                        <br>
                                            <h3 style="text-align:center;color: black;">Present your soft skill set </h3>
                                            <h3 style="text-align:center;color: #2196F3;">with Preskilet !</h3>
                                        <br>
                                    </div>
                            </v-card>
                        </v-col> -->
    
                <br><br>
    
                <!-- clear float -->
                <v-flex xs12 md12></v-flex>
                
                <!-- Problem -->
                <v-flex xs12 md5>
                    <v-card flat>
                        <v-img class="white--text align-end allimages" src="https://image.freepik.com/free-vector/man-front-camera-recording-video-share-it-internet-vloger-shares-bradcast-blog-video-log-video-bloging-web-television-embedded-video-concept-violet-palette_335657-3274.jpg"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: red;">Problem</h3>
                                <v-card-subtitle v-text="'Preskilet Pvt. Ltd. addresses the challenge many students and fresh graduates face in standing out in today’s competitive job market. Traditional resumes often fail to showcase a candidate’s true potential, and many lack the confidence and communication skills necessary for interviews. Preskilet offers a solution by enabling students to create video profiles that go beyond the limitations of paper resumes. Through video resumes, project overviews, and skill demonstrations, candidates can present themselves in a more engaging and personalized way. Our platform also includes the Industry Education Bridge (IEB), a set of activities designed to improve students communication, presentation, and interview-cracking skills. This comprehensive approach helps students boost their confidence, improve their soft skills, and showcase their abilities effectively to recruiters, making them truly job-ready.'"></v-card-subtitle>                            
                            </div>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- Solution -->
                <v-flex xs12 md5>
                    <v-card flat>
                        <v-img class="white--text align-end allimages" src="https://img.freepik.com/free-vector/business-idea-concept-with-people_52683-28609.jpg?size=626&ext=jpg"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: #2196F3;">Our Solution !</h3>
                                <v-card-subtitle v-text="'Preskilet offers a comprehensive solution that empowers students to present themselves confidently and effectively to potential employers. Our platform allows users to create dynamic video profiles, including video resumes, project videos, internship experience summaries, and skill demonstrations, providing a more engaging way to showcase their capabilities. This helps recruiters better understand candidates personalities, communication skills, and real-world experience. Additionally, we have introduced the Industry Education Bridge (IEB), a structured program that guides students through a series of activities designed to enhance their communication, presentation, and interview skills. Through this dual approach of video profiles and targeted skill-building activities, Preskilet helps students improve their employability and stand out in the competitive job market.'"></v-card-subtitle>
                            </div>
                        </div>
                    </v-card>
                </v-flex>
                
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br><br>

                <!-- vision -->
                <v-flex xs12 md5>
                    <v-card flat>
                        <v-img class="white--text align-end allimages" src="https://img.freepik.com/premium-vector/vision-business-word_18591-73766.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1721779200&semt=ais_user"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: red;">Our Vision</h3>
                                <v-card-subtitle v-text="'To empower students by enhancing their communication, presentation, and interview skills, helping them build confidence through video profiles and live resumes, and bridging the gap between academic learning and industry expectations.'"></v-card-subtitle>                            
                            </div>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- mission -->
                <v-flex xs12 md5>
                    <v-card flat>
                        <v-img class="white--text align-end allimages" src="https://img.freepik.com/premium-vector/gradient-our-mission-concept-illustrated_23-2149081667.jpg"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: #2196F3;">Our Mission</h3>
                                <v-card-subtitle v-text="'To provide an innovative platform where students can improve their soft skills, showcase their talents, and connect with recruiters through personalized video profiles, live resumes, and industry-focused activities, ensuring equal opportunities for all.'"></v-card-subtitle>
                            </div>
                        </div>
                    </v-card>
                </v-flex>
                
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br><br>
    
                <!-- Our Journey Until Now -->
                <v-flex xs12 md11>
                    <v-card flat>
                        <v-img class="hidden-md-and-up white--text align-end allimages" src="https://klizos.com/wp-content/uploads/revenue-and-business-growth-GIF1-klizos-solutions-1.gif"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: green;">Our journey until now !</h3>
                                <v-card-subtitle v-text="'Preskilet journey began with a vision to bridge the gap between students and the job market by leveraging the power of video to enhance employability. Since its inception, Preskilet has steadily evolved into a platform that not only offers innovative video profile creation but also equips students with the necessary soft skills through our Industry Education Bridge (IEB) program. Over the years, we have collaborated with esteemed institutions like MIT ACSC and MIT ADT, helping students build confidence and improve their presentation skills. Our platform has successfully placed students in top organizations like Dentsu International, and we have continuously refined our offerings based on feedback from industry professionals and recruiters. With the launch of additional products like Curiculo Live and the introduction of tailored apps like Preskilet School and Preskilet Club, we continue to grow and adapt to the ever-changing needs of the job market, staying focused on empowering students to succeed.'"></v-card-subtitle>
                            </div>
    
                               <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                                            <v-img style="border-radius: 10px;" src="https://klizos.com/wp-content/uploads/revenue-and-business-growth-GIF1-klizos-solutions-1.gif"></v-img>
                                        </v-avatar>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br><br>
    
    
                <!-- Absolutely Free -->
                <!-- <v-flex xs12 md11>
                    <v-card flat>
                        <v-img class="hidden-md-and-up white--text align-end allimages" src="https://cdn.dribbble.com/users/1046956/screenshots/5931773/giftboxanimation6x8.gif"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: green;">Our gift to you - "Absolutely Free !"</h3>
                                <v-card-subtitle v-text="'A lot of people are technically very sound in their respective field but because they lack soft skills it affects adversely in thier career! Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates animi facilis magni ducimus quas fugit nisi incidunt dolor iste, ab rerum nesciunt at fugiat, laudantium numquam ratione vel corrupti vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates animi facilis magni ducimus quas fugit nisi incidunt dolor iste, ab rerum nesciunt at fugiat, laudantium numquam ratione vel corrupti vitae.'"></v-card-subtitle>
                            </div>
    
                               <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                                            <v-img style="border-radius: 10px;" src="https://cdn.dribbble.com/users/1046956/screenshots/5931773/giftboxanimation6x8.gif"></v-img>
                                        </v-avatar>
                        </div>
                    </v-card>
                </v-flex> -->
    
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br>
    
                <!-- Benefits -->
                <v-flex xs12 md11>
                    <v-card flat style="background: white;">
                        <div>
                            <br>
                            <h3 style="text-align:center;color: #2196F3;">What Benefits do you get ? </h3>
                            <br>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br>
    
                <!-- For colleges -->
                <v-flex xs12 md5>
                    <v-card flat>
                        <v-img class="white--text align-end allimages" src="https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/About%20us%2Fcollege.jpg?alt=media&token=e3989a23-2647-4bcd-b820-5cb56e7ade15"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: #2196F3;">For colleges !</h3>
                                <v-card-subtitle v-text="'Colleges partnering with Preskilet gain several significant benefits that enhance their students career readiness and overall employability. By integrating Preskilet into their curriculum, institutions can provide students with a platform to improve their communication, presentation, and interview skills through industry-focused activities. This, in turn, helps boost placement rates and improves the institution’s reputation for producing job-ready graduates. Additionally, colleges can offer students access to cutting-edge tools like video profiles and live textual resumes, giving them a competitive advantage in the job market. The platform also fosters a collaborative learning environment where students can watch and learn from their peers’ videos, further enhancing their skills and confidence.'"></v-card-subtitle>                            
                            </div>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- For recruiters -->
                <v-flex xs12 md5>
                    <v-card flat>
                        <v-img class="white--text align-end allimages" src="https://www.talentvine.com.au/wp-content/uploads/2019/03/how-to-shortlist-candidates.png"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: #2196F3;">For Recruiters !</h3>
                                <v-card-subtitle v-text="'Recruiters using Preskilet benefit from a more efficient and insightful hiring process. By accessing candidates video profiles, including video resumes and skill demonstrations, recruiters can gain a deeper understanding of a candidate’s communication abilities, personality, and real-world experience beyond what traditional resumes offer. This helps in faster and more accurate shortlisting of candidates. Additionally, Preskilet’s platform enables recruiters to view curated candidates who have already improved their soft skills through our Industry Education Bridge (IEB) activities, ensuring they are interview-ready and possess strong presentation skills. This streamlined approach saves time and helps recruiters identify the best-fit candidates more effectively.'"></v-card-subtitle>
                            </div>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br>
    
                <!-- For Teachers -->
                <v-flex xs12 md5>
                    <v-card flat>
                        <v-img class="white--text align-end allimages" src="https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/About%20us%2Fteacher.jpg?alt=media&token=b5ab599f-bfd1-4c01-9049-a8315d2d6208"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: #2196F3;">For Teachers !</h3>
                                <v-card-subtitle v-text="'The platform’s Industry Education Bridge (IEB) activities allow teachers to integrate real-world skill-building exercises into their teaching, helping students become more confident and job-ready. Additionally, teachers can monitor student progress, offer constructive feedback on their video submissions, and foster a collaborative environment where students learn from each other’s performances. This results in more engaged, prepared, and employable students.'"></v-card-subtitle>                            
                            </div>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- For Students -->
                <v-flex xs12 md5>
                    <v-card flat>
                        <v-img class="white--text align-end allimages" src="https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/About%20us%2Fstudent.jpg?alt=media&token=859fe7f9-4c3e-4f18-8ee5-d3ca57708463"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: #2196F3;">For Students !</h3>
                                <v-card-subtitle v-text="'Students gain immense benefits from Preskilet by developing the skills and tools needed to stand out in the competitive job market. Through the platform, they can create dynamic video profiles that showcase their communication abilities, project experiences, and top skills in a personalized and engaging way. By completing the Industry Education Bridge (IEB) activities, students enhance their communication, presentation, and interview-cracking skills, boosting their confidence for real-world job interviews. Preskilet also provides a unique opportunity for students to learn from their peers by watching and giving feedback on each others videos. With access to both video profiles and live textual resumes through Curiculo Live, students have a comprehensive portfolio to share with recruiters, improving their chances of landing their desired job.'"></v-card-subtitle>
                            </div>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br>
    
                <!-- Something more for you ! -->
                <v-flex xs12 md11>
                    <v-card flat style="background: white;">
                        <div>
                            <br>
                            <h3 style="text-align:center;color: #2196F3;">Something more for you ! </h3>
                            <br>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br>
    
                <!-- Other Features -->
                <v-flex xs12 md5>
                    <v-card flat>
                        <v-img class="white--text align-end allimages" src="https://cdn.dribbble.com/users/1830537/screenshots/6493112/app_1.gif"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: #2196F3;">Other Features !</h3>
                                <v-card-subtitle v-text="'Preskilet offers a variety of features to enhance the student experience, including the ability to create engaging video profiles for showcasing skills, and Curiculo Live, which generates live, shareable textual resumes that automatically update. Students can engage in collaborative learning by watching, grading, and commenting on peer videos, fostering skill development through shared insights. Additionally, they gain access to over 20+ recruiters offering placement packages ranging from 4 LPA to 45 LPA, along with earning digital certifications, awards, and medals to motivate their progress. The platform also plans to introduce tailored applications like Preskilet School and Preskilet Club to meet specific educational needs.'"></v-card-subtitle>                            
                            </div>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- Lets start -->
                <v-flex xs12 md5>
                    <v-card flat>
                        <v-img class="white--text align-end allimages" src="https://cdn.dribbble.com/users/2288370/screenshots/7199414/login.gif"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: #2196F3;">Lets start !</h3>
                                <v-card-subtitle v-text="'At Preskilet, we believe that every student has the potential to succeed in the job market. By harnessing the power of video and innovative learning tools, we empower students to showcase their unique talents and improve their employability. Whether you are ready to create your first video profile, participate in our Industry Education Bridge activities, or connect with recruiters, the journey to becoming job-ready begins here. Join us today and take the first step toward a brighter future!'"></v-card-subtitle>
                            </div>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br>
    
                <!-- Our little story ! -->
                <v-flex xs12 md11>
                    <v-card flat style="background: white;">
                        <div>
                            <br>
                            <h3 style="text-align:center;color: #2196F3;">Our little story ! </h3>
                            <br>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br>
    
                <!-- Founders -->
                <v-flex xs12 md11>
                    <v-card flat>
                        <!-- <v-img class="hidden-md-and-up white--text align-end allimages" src="https://mitaoe.ac.in/Entrepreneurial-Development-Foundation/mostartups/new-startup-24.png"></v-img> -->
                        <div class="d-flex flex-no-wrap justify-space-between">
                            
                            <div>
                                <br>
                                <!-- <h3 style="text-align:center;color: green;">Founders !</h3> -->
                                <v-card-subtitle v-text="'Preskilet was born out of a passion for empowering students and bridging the gap between education and employability. Recognizing the struggles many fresh graduates face in presenting themselves effectively in a competitive job market, our founders envisioned a platform that would revolutionize the way students showcase their skills and experiences. Since our inception, we have been dedicated to fostering a culture of confidence, collaboration, and continuous learning. With the support of educational institutions and industry professionals, we have grown from a small startup into a vibrant community where students can enhance their communication skills, build impressive video profiles, and connect with leading recruiters. As we continue to innovate and expand our offerings, our commitment remains unwavering: to equip every student with the tools they need to succeed and thrive in their careers.'"></v-card-subtitle>
                            </div>
    
                               <!-- <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                                            <v-img style="border-radius: 10px;" src="https://mitaoe.ac.in/Entrepreneurial-Development-Foundation/mostartups/new-startup-24.png"></v-img>
                                </v-avatar> -->
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br>
    
                <!-- wanna meet us ? -->
                <v-flex xs12 md11>
                    <v-card flat style="background: white;">
                        <div>
                            <br>
                            <h3 style="text-align:center;color: #2196F3;">Wanna meet / join us ?</h3>
                            <br>
                        </div>
                    </v-card>
                </v-flex>
    
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br>
    
                <!-- Visit/contact us -->
                <v-flex xs12 md11>
                    <v-card flat>
                        <v-img class="hidden-md-and-up white--text align-end allimages" src="https://cdn.dribbble.com/users/330915/screenshots/9080033/2_share.gif"></v-img>
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <br>
                                <h3 style="text-align:center;color: green;">Visit/contact us !</h3>
                                <v-card-subtitle v-text="'We would love to hear from you! Whether you’re a student eager to enhance your employability, an educator looking to enrich your curriculum, or a recruiter seeking fresh talent, Preskilet is here to support you. Visit our website for more information on our platform and services, or reach out to us directly through the contact form. You can also connect with us on social media to stay updated on the latest news, tips, and success stories. Address: Bismilla building , Wakad gaothan , Behind Alamgir masjid, Pune-411057. Mail Us at - preskilet@gmail.com . Let’s work together to create a brighter future for students everywhere!'"></v-card-subtitle>
                            </div>
    
                               <v-avatar class="ma-3 hidden-sm-and-down" size="125" tile>
                                            <v-img style="border-radius: 10px;" src="https://cdn.dribbble.com/users/330915/screenshots/9080033/2_share.gif"></v-img>
                                        </v-avatar>
                        </div>
                    </v-card>
                </v-flex>
                
                <!-- clear float -->
                <v-flex xs12 md12></v-flex><br>
    
    
                <br><br><br><br><br><br><br><br><br><br>
    
            </v-layout>
        </v-container>    
    </div>
    </template>
    
    <style scoped>
    .parent{
    /* background: #2196F3; */
    background: white;
    margin-top: -10px;
    }
    .allimages{
        height: 300px;
    }
    h2 {
        margin-bottom   : 3%;
    }
    
    </style>
    
    <script>
    
    import { mapGetters } from "vuex";
    
    import firebase from "firebase/app";
    import "firebase/firebase-analytics";
    var firebaseAnlytics;
    import utilities from "@/helpers/utilities";
    
    export default {
        
        name        : "AboutUs",
        
        computed    : { ...mapGetters({ userData: "User/userData" }) },
         data() {
            return {
    vectorImages            : utilities.vectorImages
            }
        },
        
        created() {
    
            // FIREBASE ANLYTICS
            firebaseAnlytics = firebase.analytics();
            firebaseAnlytics.logEvent("About Us Page");
            
            this.$store.commit("User/toggleProgressLoader", false);
            this.$store.dispatch("User/updateUserToken", null, { root: true });
            this.$store.commit("User/isSignedIn");
            
        }
        
    };
    
    </script>
    
<template>
    <div>

        <div id="fullscreen-container" v-if="isAuthenticated">
            <iframe
                
                style="background: #F1F5F4;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);width: 100vw;height: 100vh;"
                :src="anlyticsURL">
            </iframe>
    
        </div>

    </div>
    
</template>

<style scoped>
#fullscreen-container {
    position: fixed;
    margin-top: -3%;
}
</style>

<script>
import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

export default {

    name: "MITADTAnalytics",

    computed: { ...mapGetters({ userData: "User/userData" }) },

    mounted() {

        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; }
        else { this.cardOutlineFlag = true; }

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("MITADTAnalytics Page");

        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

        let password;
        const correctPassword = "973059";
        do {
        password = prompt("Enter your password:");
        } while (password !== correctPassword);

        this.isAuthenticated = true;

    },

    data() {
        return {
            isAuthenticated : false,
            anlyticsURL: "",
            anlyticsURL: "https://charts.mongodb.com/charts-preskilet-mmlrn/embed/dashboards?id=01e0f8c2-58fe-4663-a64e-798415c3921c&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=true&scalingWidth=fixed&scalingHeight=fixed",
        };

    },

    methods: {
        hideHeader() {
            var frame = document.getElementsByTagName("iframe");
            console.log("frame: ", frame);
            console.log("frame.contentDocument: ", frame.contentDocument);

            header = frame.contentDocument.getElementsByTagName("header");
            console.log("frame.contentDocument: ", frame.contentDocument);

            header.remove();
            footer = frame.contentDocument.getElementsByTagName("footer");
            footer.remove();
        }

    }

};
</script>
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import apis from "./helpers/apiConfig";
// Google Adsense
import Ads from 'vue-google-adsense'

const config = apis.configKeys[apis.env].firebaseConfig;
const vapidKey = apis.configKeys[apis.env].vapidKey;

// import { config } from "./helpers/firebaseConfig";
firebase.initializeApp(config);
firebase.analytics();

if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  messaging.usePublicVapidKey(vapidKey);
}

Vue.config.productionTip = false;

import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(VuePlyr, {
  plyr: {},
});

// Google Adsense
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

import ExportExcelSheetVueJs from "export-excel-sheet-vue-js";
Vue.use(ExportExcelSheetVueJs);
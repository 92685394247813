<template>
    <div class="home">
      <v-container>
        <v-layout row wrap justify-space-around>
          <v-flex xs11 md11>
            <v-row justify="center" class="text-center ma-3">
              <v-card-title v-text="'Payment Declaration'"></v-card-title>
            </v-row>
  
            <v-card color="white" outlined>
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-text>
                    <div class="paymentdeclaration">
                      <h2>Preskilet pricing</h2>
                      <p>
                      Thank you for choosing our services! Before you proceed with the payment, please review the details of the plans available:
                       <br>
                       <ul>
                        <li>
                          Plan 1: Basic Plan
                          <ul>
                            <li>
                              Ability to upload 6 videos.
                            </li>
                            <li>
                              Price: Rs. 199
                            </li>
                          </ul>
                        </li>

                        <li>
                          Plan 2: Premium Plan
                          <ul>
                            <li>
                              Ability to upload 12 videos.
                            </li>
                            <li>
                              Price: Rs. 299
                            </li>
                          </ul>
                        </li>
                       </ul>   
                      </p>
                      <br />
                      <h2>Total Amount Calculation</h2>
                      <p>
                        For both plans, the total amount to be paid is calculated as follows:
                        <ul>
                          <li>
                            Amount: Rs. 199 (Basic) / Rs. 299 (Premium)
                          </li>
                          <li>
                            SGST: 9% of the amount
                          </li>
                          <li>
                            CGST: 9% of the amount
                          </li>
                          <li>
                            Processing Fee: 3% of the amount
                          </li>
                          <li>
                            The total amount displayed during payment will include the base price along with applicable taxes and processing fee.
                          </li>
                        </ul>
                      </p>
                      <br />
                    </div>
                  </v-card-text>
                </div>
              </div>
            </v-card>
            <br />
            <br />
            <br />
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </template>
  
  <style scoped>
  h2 {
    margin-bottom: 3%;
  }
  </style>
  
  <script>
  //vuex dependancy
  import { mapGetters } from "vuex";
  
  export default {
    name: "PaymentDeclaration",
    computed: {
      ...mapGetters({
        userData: "User/userData"
      })
    },
    created() {
      this.$store.commit("User/toggleProgressLoader", false);
      this.$store.dispatch("User/updateUserToken", null, { root: true });
      this.$store.commit("User/isSignedIn");
    }
  };
  </script>
  
<template>
<div>
    
    <v-container>

        <v-layout row wrap justify-space-around>

            <v-flex xs11 md6>

                <v-card
                    flat
                    v-if="peopleFollowing.length === 0 && groupsFollowing.length === 0"
                    class="mx-auto"
                    max-width="400"
                >

                    <v-img class="white--text align-end" :src="emptyStateImage"></v-img>

                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-title
                            class="headline"
                            v-text="'Following'"
                            ></v-card-title>

                            <v-card-subtitle v-text="emptyStateMessage"></v-card-subtitle>

                        </div>

                    </div>

                </v-card>

                             <v-card-title v-if="groupsFollowing.length != 0">Groups that you follow</v-card-title>
                               <v-card
                    class="mx-auto my-12 ma-3"
                    outlined
                    v-for="item in groupsFollowing"
                    :key="item._id"
                >

                    <v-list-item :key="item._id">

                        <v-list-item-content>                            
                            <v-list-item-title>{{ item.groupId.name }}</v-list-item-title>
                            <br />
                            <v-list-item-subtitle class="text-wrap">
                                  {{item.groupId.subject}}
                                {{item.groupId.type}}

                                {{item.groupId.academicYear}}
                                {{item.groupId.div}}
                                {{item.groupId.batch}}

                                {{item.groupId.department}}

                                {{item.groupId.year}} <br />
                                {{item.groupId.description}}
                            </v-list-item-subtitle>

                        </v-list-item-content>

                    </v-list-item>

                    <v-card-actions>

                        <v-btn
                            color="deep-purple lighten-2"
                            text
                            router
                            :to="'/' + item.groupId.userId"
                        >Show creator of Group
                        </v-btn>

                    </v-card-actions>

                </v-card>
                             
                             
<!--                <v-card-title v-if="peopleFollowing.length != 0">People that you follow</v-card-title>

                <v-card
                    class="mx-auto my-12 ma-3"
                    outlined
                    v-for="item in peopleFollowing"
                    :key="item._id"
                >

                    <v-list-item :key="item._id">

                        <v-list-item-avatar size="60">
                            <v-img :src="item.profile.profilePhoto"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-card-title>{{ item.profile.name }}</v-card-title>
                        </v-list-item-content>

                    </v-list-item>

                    <v-card-actions>

                        <v-btn
                            color="deep-purple lighten-2"
                            text
                            router
                            :to="'/' + item._id"
                        >show more
                        </v-btn>

                    </v-card-actions>

                </v-card>
-->


            </v-flex>

            <v-flex xs11 md4>

                <v-card 
                    :flat="cardFlatFlag"
                    :outlined="cardOutlineFlag"
                    class="mx-auto sticky"
                    v-if="userData.profile.account === 'Student'"
                >
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>


                    </v-list-item>
                    
                        <div class="ad-container">
                            <Adsense
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="9450697966"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
        
        
                </v-card>

            </v-flex>

        </v-layout>

    </v-container>

</div>
</template>

<style scoped>
.sticky{
position: relative;
}



.RightSideAd{
    padding-left: 0px;
    padding-right: 0px;
}

/* for desktop */
@media screen and (min-width: 650px) {
.RightSideAd{
    padding-left: 4%;
    padding-right: 4%;
}

.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 2;
    width: auto !important;
}

  .sticky2{
    position: -webkit-sticky;
    position: sticky;
    top: 27rem;
    z-index: 2;
    width: auto !important;
    margin-top: 280%;
}
}

.form-container {
  padding   : 20px;
}

</style>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;


// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

export default {
    
    name: "Following",
    
    computed: { ...mapGetters({ userData: "User/userData" }) },
    
    mounted() {

        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Following Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        
        let endPoint = apis.urls.following;
        this.$store.commit("User/toggleProgressLoader", true);
    
        axios(endPoint, {
            method          : "get",
            withCredentials : true,
            headers         : { authorization: this.userData.token }
        })
        .then((res) => {
            
            let data = res.data.result.data;
            this.peopleFollowing = data;
            this.$store.commit("User/toggleProgressLoader", false);

            // FIREBASE ANLYTICS
            firebaseAnlytics.logEvent("Following displayed");

        })
        .catch((err) => {
            
            this.$store.commit("User/toggleProgressLoader", false);
            console.log("Error in Following",err);
            let errorMsg = utilities.errorHandler(err);
            this.$store.commit("User/showErrorAlert", errorMsg);
        
        });

        this.getFollowingGroups();
    },

    data() {
        
        return {
            cardFlatFlag    : true,
            cardOutlineFlag : false,
            peopleFollowing: [],
            groupsFollowing: [],
            emptyStateImage: utilities.emptyState.following.image,
            emptyStateMessage: utilities.emptyState.following.message,
        };

    },

    methods: {
        getFollowingGroups(){
            
            let endPoint = apis.urls.getFollowingGroups;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                console.log("Groups data : ",res);
                
                let data = res.data.result.data;
                this.groupsFollowing = data;
                this.$store.commit("User/toggleProgressLoader", false);
               
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Following Groups Displayed");
            
            }) 
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Following Groups",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        }
    }

};

</script>

import Vue from "vue";
import VueRouter from "vue-router";

// Views
import Home from "@/views/Home.vue";
import StaticPagesTemplate from "@/staticPagesModule/index";
import Feed from "@/views/Feed.vue";
import Explore from "@/components/ExploreVideos.vue";
import Trending from "@/views/Trending.vue";
import Signin from "@/views/Signin.vue";
import SignOut from "@/views/SignOut.vue";
import Profile from "@/views/Profile.vue";
import SelectAccountType from "@/views/SelectAccountType.vue";
import Pret from "@/views/Pret.vue";
import SubmissionVideoForTask from "@/views/SubmissionVideoForTask.vue";
import EditPret from "@/views/EditPret.vue";
import  CustomRubrics    from    "@/views/CustomRubrics.vue";
import SavedPrets from "@/views/SavedPrets.vue";
import Search from "@/views/Search.vue";
import Preskilet from "@/views/Preskilet.vue";
import Watch from "@/views/Watch.vue";
import TestAd from "@/views/TestAd.vue";
import Followers from "@/views/Followers.vue";
import Following from "@/views/Following.vue";
import Groups from "@/views/Groups.vue";
import Tasks from "@/views/Tasks.vue";
import TaskPrets from "@/views/TaskPrets.vue";
import GroupRequests from "@/views/GroupRequests.vue"
import GroupFollowers from "@/views/GroupFollowers.vue"
import HowToUse from "@/views/HowToUse.vue"
import AddCredits from "@/views/AddCredits.vue"
import ProxyUserMode from "@/views/ProxyUserMode.vue"
import Payments from "@/views/Payments.vue"
import JobListing from "@/views/JobListing.vue"

import PaymentDeclaration from "@/views/PaymentDeclaration.vue"
import Analytics from "@/views/Analytics.vue"

// Static Views
import Termsandconditions from "@/views/Termsandconditions.vue";
import Privacypolicy from "@/views/Privacypolicy.vue";
import AboutUs from "@/views/AboutUs.vue";
import ContactUs from "@/views/ContactUs.vue";

// information views
import PreskiletInformation from "@/views/PreskiletInformation.vue"
import CuriculoLiveInformation from "@/views/CuriculoLiveInformation.vue"
import IEBInformation from "@/views/IEBInformation.vue"
import Sales from "@/views/Sales.vue"

// ADT Analytics
import MITADTAnalytics from "@/views/MITADTAnalytics.vue"


Vue.use(VueRouter);

const routes = [
	{ path: "/", name: "Home", component: Home },

	// information views
	{ path: "/staticPagesTemplate", name: "StaticPagesTemplate", component: StaticPagesTemplate },
	{ path: "/preskiletInformation", name: "PreskiletInformation", component: PreskiletInformation },
	{ path: "/curiculoLiveInformation", name: "CuriculoLiveInformation", component: CuriculoLiveInformation },
	{ path: "/iebInformation", name: "IEBInformation", component: IEBInformation },
	{ path: "/sales", name: "Sales", component: Sales },
	{ path: "/mitadtanalytics", name: "MITADTAnalytics", component: MITADTAnalytics },




	{ path: "/explore", name: "Explore", component: Explore },
	{ path: "/how-to-use-preskilet", name: "HowToUse", component: HowToUse },

	{ path: "/feed", name: "Feed", component: Feed },
	{ path: "/trending", name: "Trending", component: Trending },
	{ path: "/googleadtest", name: "TestAd", component: TestAd },
	{
		path: "/signin",
		name: "Signin",
		component: Signin,
	},
	{
		path: "/profile",
		name: "Profile",
		component: Profile,
	},
	{
		path: "/selectaccounttype",
		name: "SelectAccountType",
		component: SelectAccountType,
	},
	{
		path: "/pret",
		name: "Pret",
		component: Pret,
	},
	{
		path: "/submissionVideoForTask",
		name: "submissionVideoForTask",
		component: SubmissionVideoForTask,
	},
	{
		path: "/editpret",
		name: "EditPret",
		component: EditPret,
	},
  {
    path: "/customRubrics",
    name: "CustomRubrics",
    component: CustomRubrics,
  },
	{
		path: "/savedprets",
		name: "SavedPrets",
		component: SavedPrets,
	},
	{
		path: "/followers",
		name: "Followers",
		component: Followers,
	},
	{
		path: "/following",
		name: "Following",
		component: Following,
	},
	{
		path: "/groups",
		name: "Groups",
		component: Groups,
	},
	{
		path: "/tasks",
		name: "Tasks",
		component: Tasks,
	},
	{
		path: "/grouprequests",
		name: "GroupRequests",
		component: GroupRequests,
	},
	{
		path: "/groupfollowers",
		name: "GroupFollowers",
		component: GroupFollowers,
	},
	{
		path: "/search/",
		name: "Search",
		component: Search,
	},
	//common menu items links that is static pages
	{
		path: "/termsandconditions",
		name: "Termsandconditions",
		component: Termsandconditions,
	},
	{
		path: "/privacypolicy",
		name: "Privacypolicy",
		component: Privacypolicy,
	},
	{
		path: "/aboutus",
		name: "AboutUs",
		component: AboutUs,
	},
	{
		path: "/contactus",
		name: "ContactUs",
		component: ContactUs,
	},
	{
		path: "/jobListing",
		name: "jobListing",
		component: JobListing,
	},
	{
		path: "/payments",
		name: "Payments",
		component: Payments,
	},
	{
		path: "/paymentdeclaration",
		name: "PaymentDeclaration",
		component: PaymentDeclaration,
	},

	// common menu items ended *****
	{
		path: "/signout",
		name: "SignOut",
		component: SignOut,
	},
	{
		path: "/task/prets/:q",
		name: "TaskPrets",
		component: TaskPrets,
	},

	{
		path: "/search/:q",
		name: "SearchByParams",
		component: Search,
	},
	{
		path: "/watch",
		name: "Watch",
		component: Watch,
	},
	{
		path: "/addcredits",
		name: "AddCredits",
		component: AddCredits
	},
	{
		path: "/proxyUserMode",
		name: "ProxyUserMode",
		component: ProxyUserMode
	},
	{
		path: "/analytics/:institutionId",
		name: "Analytics",
		component: Analytics,
	},
	{
		path: "/:email",
		name: "Preskilet",
		component: Preskilet,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;

<template>
<div>

     <v-dialog v-model="signinDialogue" persistent max-width="600">
          <v-card>
            <v-card-actions>
              <v-card-title>You need to Sign in </v-card-title>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="signinDialogue = false">
                Later
              </v-btn>
              <v-btn color="green darken-1" text router to='/signin'>
                Sign in
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
        
    <v-flex xs12 md12>
        
          
        <v-sheet
    v-if="exploreVideosArray.length === 0"
    class="pa-3"
  >
    <v-skeleton-loader
      class="mx-auto"
      max-width="300"
      type="card"
    >
    </v-skeleton-loader>
    <br/>
    <v-card-title>
    <center>
Finding some good presentation videos for you...

    </center>

    </v-card-title>
      </v-sheet>

        <div v-if="exploreVideosArray.length != 0">
           
            <div v-for="(item, index) in exploreVideosArray"
            :key="item._id">

            <!-- videos -->
            <v-card            
            :flat="cardFlatFlag"
            :outlined="cardOutlineFlag"
            class="mx-auto my-6"
        >
            
            <v-list-item two-line>
                
                <v-list-item-avatar><img :src="item.userId.profile.profilePhoto" /></v-list-item-avatar>
                
                <v-list-item-content>
                    
                    <router-link style="text-decoration: none;color:black;" :to="'/' + item.userId._id">
                        <v-list-item-title>{{ item.userId.profile.name }}</v-list-item-title>
                    </router-link>
                    
                    <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                
                </v-list-item-content>
                
                <v-btn color="primary" size="35" tile large icon router :to="'/watch?v=' + item._id">
                    <v-icon left> mdi-compass-outline</v-icon>
                </v-btn>
            
            </v-list-item>
            
            <!-- <vue-plyr :options="options">
                <video :data-poster="item.thumbnailUrl" :src="item.videoUrl" controls playsinline style="width: 100%;height:auto;" ></video>
            </vue-plyr> -->

    <router-link style="text-decoration: none;color:black;" :to="'/watch?v=' + item._id">
                    
                        <v-img 
                            :src="item.thumbnailUrl"
                            class="white--text align-end videoRecommendationThubmnail"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        >
                            <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="white--text">
                                {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="white--text"
                                >{{ item.viewsLength }} Views</v-list-item-subtitle
                                >
                            </v-list-item-content>
                            </v-list-item>
                        </v-img>
                    
                    </router-link>
            <v-card-actions>
                <v-list-item class="grow">
                    <v-row align="center" class="pa-0 ma-0">
                        
                <!--     <v-icon 
                            @click="unLikePret(index)" 
                            style="cursor:pointer;" 
                           v-if="item.likes.includes(userId)"
                            v-if='false'
                            color="red" 
                            size="30"
                        >
                            mdi-heart
                        </v-icon>
                        
                        <v-icon
                            @click="likePret(index)"
                            
                            v-if='false'
                            style="cursor:pointer;"
                            color="whitesmoke"
                            size="30"
                        >
                            mdi-heart-outline
                        </v-icon> 
                -->

                        
                        <v-spacer></v-spacer>  
              
                        <v-icon 
                            @click="unsave(item._id)"
                            v-if="savedprets.includes(item._id)" 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark
                        </v-icon>


                        <v-icon 
                            @click="save(item._id)"
                            v-if="!savedprets.includes(item._id)" 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark-outline
                        </v-icon>
                    
                    </v-row>

                </v-list-item>

            </v-card-actions>
        
        </v-card>

<!-- ads -->
  <!-- <v-card   v-if="index % 5 == 0 && index != 0"
            :flat="cardFlatFlag"
            :outlined="cardOutlineFlag"
            class="mx-auto my-6"
        >
            
            <v-list-item two-line>
                
                <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                
                <v-list-item-content>
                    
                        <v-list-item-title>Google Ads</v-list-item-title>
                    
                    <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                
                </v-list-item-content>
                
                <v-btn color="primary" size="35" tile large icon router >
                    <v-icon left> mdi-compass-outline</v-icon>
                </v-btn>
            
            </v-list-item>
            
<div  class="ad-container">
                <InFeedAdsense
                    style="display:block"
                    data-ad-format="fluid"
                    data-ad-layout-key="-6t+ed+2i-1n-4w"
                    data-ad-client="ca-pub-2789565594210548"
                    data-ad-slot="2543843993"
                ></InFeedAdsense>
            </div>

   
            <v-card-actions>
                <v-list-item class="grow">
                    <v-row align="center" class="pa-0 ma-0">
                        
                        <v-icon 
                            style="cursor:pointer;" 
                            color="whitesmoke" 
                            size="30"
                        >
                            mdi-heart-outline
                        </v-icon>
                        
                       
                        
                        <v-spacer></v-spacer>  
              
                                            <v-icon 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark-outline
                        </v-icon>
                    
                    </v-row>

                </v-list-item>

            </v-card-actions>
        
        </v-card> -->

<!-- Follow Suggestions -->
        <v-flex v-if="suggestData.length != 0 && index === 0" xs12 md12 hidden-md-and-up>
                
                <v-sheet flat class="mx-auto">
                    <v-slide-group class="pa-0">
                        
                        <div v-for="item in suggestData" :key="item._id">
                            <v-slide-item v-slot="{ toggle }">
                                
                                <v-card outlined @click="toggle" class="pa-3 ma-3" style="max-width: 200px;">
                                    

                                    <v-img 
                                    :src="item.profile.profilePhoto"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                    height="150px"
                                    width="150px"
                                    style="border-radius: 50%;margin-left: 11px;"
                                    ></v-img>

                                    <router-link style="text-decoration: none;color:black;" :to="'/' + item._id">

                                    <v-list two-line class="pa-0" style="margin-left: 11px;">
                                        <v-list-item>                                                
                                            <v-list-item-content class="pa-0" v-bind:class="{ limitedLength: isLimitedLength }">
                                                <v-list-item-title>{{ item.profile.name }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.followers.length }} Followers</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    
                                    </router-link>

                                    
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn v-if="item.msg === 'follow'" color="primary"  style="width: 100%;" @click="followPreskilet(item._id)">{{item.msg}}</v-btn>
                                        <v-btn v-if="item.msg === 'following'" icon style="width: 100%;color: grey;" >{{item.msg}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                     
                            </v-slide-item>
                        </div>
                
                    </v-slide-group>
                
                </v-sheet>
            
        </v-flex>


        </div>
        <div class="text-center">
            
            <v-progress-circular
                v-if="loadMoreVideosProgress"
                :size="50"
                color="blue"
                dark
                indeterminate>
            </v-progress-circular>
            
            
            <br><br>
        

             <v-btn
            rounded
            color="primary"
            dark
            v-if="noMorevideos"
            >
                No More Videos
            </v-btn>
            <br><br>
        
        </div>
            </div>

               

    </v-flex>


</div>
</template>

<style scoped>

.videoRecommendationThubmnail{
width: 100%;
height:350px;
}

/* for desktop */
@media screen and (min-width: 650px) {
.videoRecommendationThubmnail{
width: 100%;
height:450px;
}

}



#create .v-speed-dial {
  position  : absolute;
}

#create .v-btn--floating {
  position  : relative;
}
.tooltip {
  position  : relative;
  display   : inline-block;
}

.tooltip .tooltiptext {
  visibility        : hidden;
  width             : 140px;
  background-color  : #555;
  color             : #fff;
  text-align        : center;
  border-radius     : 6px;
  padding           : 5px;
  position          : absolute;
  z-index           : 1;
  bottom            : 150%;
  left              : 50%;
  margin-left       : -75px;
  opacity           : 0;
  transition        : opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content       : "";
  position      : absolute;
  top           : 100%;
  left          : 50%;
  margin-left   : -5px;
  border-width  : 5px;
  border-style  : solid;
  border-color  : #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility    : visible;
  opacity       : 1;
}

</style>

<script>

import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

import VuePlyr from "vue-plyr";

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)


export default {
    name        : "ExploreVideos",
    
    components  : { "vue-plyr": VuePlyr },
    
    data() {
        return {
            
            loadMoreVideosProgress  : false,
            excludeVideoIDs        : [],
            signinDialogue: false,
            suggestData             : [],
            isLimitedLength         : true,

            
            savedprets      : [],
            likedAds        : [],
            
            options         : { controls: [ "play-large", "mute", "current-time", "duration" ], hideControls: false },
            
            userId          : "",
            vectorImages    : utilities.vectorImages,
            exploreVideosArray: [],
            shareableLink   : "https://preskilet.com/",
            dialog          : false,
            cardFlatFlag    : true,
            cardOutlineFlag : false,
            noMorevideos: false,
            apiAlreadyCalled : false
        }
    },
    
    computed    : { ...mapGetters({ userData: "User/userData" }) },
    
    created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("ExploreVideos (Component)");

        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
            var that = this;
        
        $(window).scroll(function() {
            // For Desktop
            if($(window).scrollTop() + $(window).height() == $(document).height()) {

                if(that.noMorevideos === false && that.apiAlreadyCalled === false){
                    that.apiAlreadyCalled = true;
                    // that.exploreVideos();
                }

                if(that.noMorevideos === true)
                {
                    console.log("There are no more videos");
                    //   that.$store.commit("User/showErrorAlert", "There are no more videos");

                }

            }
            
            // For Mobile
             if ($(window).scrollTop() >= $(document).height() - $(window).height() - 100) {
                if(that.noMorevideos === false && that.apiAlreadyCalled === false){
                    that.apiAlreadyCalled = true;
                    // that.exploreVideos();
                }

                if(that.noMorevideos === true)
                {
                    console.log("There are no more videos");
                }
            }
        });
        
    },
    
    mounted() {
        
        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }
        
        // this.exploreVideos();
        // this.getSuggestions() 
    },
    
    methods: {

            exploreVideos() {

            this.loadMoreVideosProgress = true;

            let endPoint = apis.urls.exploreVideos;

            
            axios(endPoint, {
                method  :   "post",
                data  : { excludeVideoIDs : this.excludeVideoIDs },
                withCredentials :   true,
                headers         : { authorization : this.userData.token }
            })
            .then((res) => {

                this.apiAlreadyCalled = false;

                console.log("Explore videos data : ", res);
                
                this.loadMoreVideosProgress = false;
                
                var videosData = res.data.result.data.feed;
                this.userId     = res.data.result.data.userId;
                this.savedprets = res.data.result.data.saved;

                
                if(videosData.length === 0) {
                    console.log("no more videos flag set : ", this.noMorevideos);
                    this.noMorevideos = true;
                    console.log("There are no more videos");

                    // this.$store.commit("User/showErrorAlert", "There are no more videos");
                } else {
                    
                    if(this.excludeVideoIDs.length === 0){
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Explore Video Displayed");
                    } else {
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Explore Video (Load more Video) button clicked");
                    }
                    
                    for (let i = 0; i < videosData.length; i++) {
                        const element = videosData[i];
                        this.exploreVideosArray.push(element)
                        this.excludeVideoIDs.push(element._id);
                    }

                    console.log("excludeVideoIDs 2 : ",this.excludeVideoIDs);


                }
            
            })
            .catch((err)    => { console.log("exploreVideos() Error : ", err)   });

        },
        
        save(videoId){
            
            if (!this.userData.signedIn) {
                this.signinDialogue = true
            }else
            {
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.savePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId },
                withCredentials :   true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                this.savedprets.push(videoId)
                
                this.$store.commit("User/toggleProgressLoader", false);
                            
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Saved on Explore Component");

            })
            .catch((err) => {

                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);

            });
        }

        },
        
        unsave(videoId){
                        if (!this.userData.signedIn) {
                this.signinDialogue = true
            }else
            {

            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.unSavePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId },
                withCredentials :   true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                this.savedprets.pop(videoId)
                
                this.$store.commit("User/toggleProgressLoader", false);
                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Unsaved on Explore Component");

            })
            .catch((err) => {

                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
                
            });
            }
        },
        
        likePret(index) {
            
            if (!this.userData.signedIn) {
                this.signinDialogue = true
            }else
            {

            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.likePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId : this.exploreVideosArray[index]._id },
                withCredentials :   true,
                headers         : { authorization : this.userData.token }
            })
            .then((res) => {
                
                this.exploreVideosArray[index].likes.push(this.userId);
                this.$store.commit("User/toggleProgressLoader", false);
                // this.$store.commit("User/showSuccessAlert", res.data.result);

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Liked on Explore Component");

            })
            .catch((err) => {

                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);

            });
            }
        },
        
        unLikePret(index) {

                        if (!this.userData.signedIn) {
                this.signinDialogue = true
            }else
            {

            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.unLikePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId : this.exploreVideosArray[index]._id},
                withCredentials :   true,
                headers         : { authorization : this.userData.token }
            })
            .then((res) => {
                
                this.exploreVideosArray[index].likes.pop(this.userId);
                this.$store.commit("User/toggleProgressLoader", false);
                // this.$store.commit("User/showSuccessAlert", res.data.result);

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Unliked on Explore Component");

            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);

            });
            }
        },
        followPreskilet(uid) {
              console.log("my uid : ", uid);
                
                this.$store.commit("User/toggleProgressLoader", true);

                let data        = { userId: uid };
                let endPoint    = apis.urls.follow;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {

                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result);


                    this.suggestData.forEach(x => {
                        if(JSON.stringify(x._id) === JSON.stringify(uid)){
                            x.msg = "following";
                        }
                        
                    });

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Follow Operation on Home from suggestions");                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

        },
        getSuggestions() {
            
            let endPoint = apis.urls.getSuggestions;
            
            axios(endPoint, {
                method          :   "get",
                withCredentials :   true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                if (res.data.result) {
                    
                    this.userId = res.data.result._id;
                    var userAlreadyFollowing = res.data.result.userFollowing;
                    var tempSuggestData = res.data.result.suggest;
                    
                    // To put all the data in one single array
                    let finalCycle = [];
                    for (let i = 0; i < tempSuggestData.length; i++) {
                    for (let j = 0; j < tempSuggestData[i].following.length; j++) {
                        // To not show the user the himself as a suggestions
                        if (tempSuggestData[i].following[j]._id != this.userId) {
                            finalCycle.push(tempSuggestData[i].following[j]);
                        }
                    }
                    }
                    
                    for (let jm = 0; jm < finalCycle.length; jm++) {
                        // console.log("docs ", jm, " ", finalCycle[jm])
                    }
                    
                    // Removing all the duplicates from the array
                    for (let i = 0; i < finalCycle.length; i++) {
                    for (let j = 0; j < finalCycle.length; j++) {
                        if (i != j) {
                        if (finalCycle[i]._id == finalCycle[j]._id) {
                            finalCycle.splice(j, 1);
                        }
                        }
                    }
                    }
                    
                    for (let jm = 0; jm < finalCycle.length; jm++) {
                        // console.log("docs ", jm, " ", finalCycle[jm])
                    }
                    
                    var uniqueChars = [];
                    
                    function doesFollow(myid) {
                    for (let i = 0; i < userAlreadyFollowing.length; i++) {
                        if (userAlreadyFollowing[i]._id == myid) {
                        return true;
                        }
                    }
                    return false;
                    }

                    var uniqueChars = [];
                    for (let j = 0; j < finalCycle.length; j++) {
                    if (!doesFollow(finalCycle[j]._id)) {
                        uniqueChars.push(finalCycle[j]);
                    }
                    }
                    this.suggestData = uniqueChars;
                    
                    var suggestDataWithMsg = uniqueChars.map((x)=>{
                        
                        return {
                        email : x.email,
                        followers : x.followers,
                        profile: x.profile,
                        _id : x._id,
                        msg: "follow"
                    }
                    });

                console.log("suggestions : ", suggestDataWithMsg);
                
                suggestDataWithMsg = suggestDataWithMsg.sort(() => Math.random() - 0.5)
                
                this.suggestData = suggestDataWithMsg;

                console.log("shuffled : ", this.suggestData);


                    if (this.suggestData.length == 1) {
                    this.isLimitedLength = false;
                    }


                }

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Suggestions Displayed on Home Page");
            
            })
            .catch((err) => { console.log("getSuggestions Error : ", err) });
        
        }

    }
    
};

</script>

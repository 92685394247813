<template>
<div class="text-center">
    
    <v-overlay :value="userData.ProgressLoader">
        
        <v-progress-circular
        color="red"
        indeterminate
        size="64"
        ></v-progress-circular>

    </v-overlay>

</div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    
    name        : "ProgressLoader",
    computed    : { ...mapGetters({ userData    : "User/userData" }) }

};

</script>

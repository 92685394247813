<template>
<div class="home">
    
    <v-container>
        
        <v-layout row wrap justify-space-around>
        <v-flex xs12 md6>

<div class="home" v-if="userData.signedIn">
    
    <v-flex xs12 md12>
        
        <v-card flat>
            
            <div class="d-flex flex-no-wrap justify-space-between">
            
                <div>
                    <v-card-title class="headline" >Task Name : {{taskData.name}}</v-card-title>
                    <v-card-subtitle>Task Description : {{taskData.description}}</v-card-subtitle>
                </div>
                
                <v-avatar size="125" tile> <v-img :src="vectorImages.signout"></v-img></v-avatar>
            </div>
        
        </v-card>
        
        


            <v-card flat v-if="feedData.length === 0">
            
            <div class="d-flex flex-no-wrap justify-space-between">
            
                <div>
                    <v-card-title class="headline" >Nothing here yet !</v-card-title>
                    <v-card-subtitle><b> No videos has been uploaded yet, by your students </b>, Make sure that your students (/Group members) select this Group and Task while uploading the video</v-card-subtitle>
                </div>
                
            </div>
        
        </v-card>

         <v-card flat v-if="feedData.length != 0">
            
            <div class="d-flex flex-no-wrap justify-space-between">
            
                <div>
                    <v-card-title class="headline" >Get Excel of your Grades !</v-card-title>
                    <v-card-subtitle>Once you Grade the videos from here, you can get a separate Excel sheet of containing all the grades given by you for the video of students. To download the Excel sheet go to the 'Groups' section from menu.</v-card-subtitle>
                </div>
                
            </div>
        
        </v-card>
       
        <div v-if="feedData.length != 0">
           
            <div v-for="(item, index) in feedData"
            :key="item._id">

            <v-card            
            :flat="cardFlatFlag"
            :outlined="cardOutlineFlag"
            class="mx-auto my-6"
        >
            
            <v-list-item two-line>
                
                <v-list-item-avatar><img :src="item.userId.profile.profilePhoto" /></v-list-item-avatar>
                
                <v-list-item-content>
                    
                    <router-link style="text-decoration: none;color:black;" :to="'/' + item.userId._id">
                        <v-list-item-title>{{ item.userId.profile.name }}</v-list-item-title>
                    </router-link>
                    
                    <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                
                </v-list-item-content>

                <a 
                    :href="'/watch?v=' + item._id"
                    style="text-decoration:none;"
                    class="pa-2"
                    target="_blank"
                >
                   Submission No. {{ index + 1 }}
                </a>

            
            </v-list-item>
            
            <!-- <vue-plyr :options="options">
                <video :data-poster="item.thumbnailUrl" :src="item.videoUrl" controls playsinline style="width: 100%;height:auto;" ></video>
            </vue-plyr> -->

    <router-link style="text-decoration: none;color:black;" :to="'/watch?v=' + item._id" target="_blank">
                    
                        <v-img 
                            :src="item.thumbnailUrl"
                            class="white--text align-end videoRecommendationThubmnail"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        >
                            <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="white--text">
                                {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="white--text"
                                >{{ item.viewsLength }} Views</v-list-item-subtitle
                                >
                            </v-list-item-content>
                            </v-list-item>
                        </v-img>
                    
                    </router-link>
            <v-card-actions>
                <v-list-item class="grow">
                    <v-row align="center" class="pa-0 ma-0">
                        
                        <v-icon 
                            @click="unLikePret(index)" 
                            style="cursor:pointer;" 
                            v-if="item.likes.includes(userId)" 
                            color="red" 
                            size="30"
                        >
                            mdi-heart
                        </v-icon>
                        
                        <v-icon
                            @click="likePret(index)"
                            v-if="!item.likes.includes(userId)"
                            style="cursor:pointer;"
                            color="whitesmoke"
                            size="30"
                        >
                            mdi-heart-outline
                        </v-icon>
                        
                        <v-spacer></v-spacer>  
              
                        <v-icon 
                            @click="unsave(item._id)"
                            v-if="savedprets.includes(item._id)" 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark
                        </v-icon>


                        <v-icon 
                            @click="save(item._id)"
                            v-if="!savedprets.includes(item._id)" 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark-outline
                        </v-icon>
                    
                    </v-row>

                </v-list-item>

            </v-card-actions>
        
        </v-card>

<!-- ads -->
  <!-- <v-card   v-if="index % 4 == 0 && index != 0"
            :flat="cardFlatFlag"
            :outlined="cardOutlineFlag"
            class="mx-auto my-6"
        >
            
            <v-list-item two-line>
                
                <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                
                <v-list-item-content>
                    
                        <v-list-item-title>Google Ads</v-list-item-title>
                    
                    <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                
                </v-list-item-content>
                
                <v-btn color="primary" size="35" tile large icon router >
                    <v-icon left> mdi-compass-outline</v-icon>
                </v-btn>
            
            </v-list-item>
            
<div  class="ad-container">
                <InFeedAdsense
                    style="display:block"
                    data-ad-format="fluid"
                    data-ad-layout-key="-6t+ed+2i-1n-4w"
                    data-ad-client="ca-pub-2789565594210548"
                    data-ad-slot="2873874849"
                ></InFeedAdsense>
            </div>
   
            <v-card-actions>
                <v-list-item class="grow">
                    <v-row align="center" class="pa-0 ma-0">
                    
                        <v-icon
                            style="cursor:pointer;"
                            color="whitesmoke"
                            size="30"
                        >
                            mdi-heart-outline
                        </v-icon>
                        
                        <v-spacer></v-spacer>  
          

                        <v-icon 
                            style="cursor:pointer;" 
                            color="primary"
                            size="30"
                        >
                            mdi-bookmark-outline
                        </v-icon>
                    
                    </v-row>

                </v-list-item>

            </v-card-actions>
        
        </v-card> -->


  
  



        </div>
       
        <br>
        <br>
        <br>
            </div>
    
    </v-flex>

</div>
            </v-flex>
            
            <v-flex xs12 md5 hidden-sm-and-down>

                  <!-- !!! Google sticky ad on desktop  -->
                 <v-card  class="mx-auto sticky" v-if="userData.signedIn && userData.profile.account === 'Student'">
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>

                    </v-list-item>

                        <div class="ad-container">
                            <Adsense
                                class="adsbygoogle"
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="1177649794"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
                       
                </v-card> 

            </v-flex>
        
        </v-layout>
    
    </v-container>

</div>
</template>
<style scoped>
.videoRecommendationThubmnail{
width: 100%;
height:350px;
}

/* for desktop */
@media screen and (min-width: 650px) {
.videoRecommendationThubmnail{
width: 100%;
height:450px;
}

}

/* for desktop */
@media screen and (min-width: 650px) {

    .sticky{
    position: -webkit-sticky;
    position: sticky;
top: 35px;
    z-index: 2;
    width: auto !important;
}

  .sticky2{
    position: -webkit-sticky;
    position: sticky;
    top: 400px;
    z-index: 2;
    width: auto !important;
    margin-top: 255%;

}

}


#create .v-speed-dial {
  position  : absolute;
}

#create .v-btn--floating {
  position  : relative;
}
.tooltip {
  position  : relative;
  display   : inline-block;
}

.tooltip .tooltiptext {
  visibility        : hidden;
  width             : 140px;
  background-color  : #555;
  color             : #fff;
  text-align        : center;
  border-radius     : 6px;
  padding           : 5px;
  position          : absolute;
  z-index           : 1;
  bottom            : 150%;
  left              : 50%;
  margin-left       : -75px;
  opacity           : 0;
  transition        : opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content       : "";
  position      : absolute;
  top           : 100%;
  left          : 50%;
  margin-left   : -5px;
  border-width  : 5px;
  border-style  : solid;
  border-color  : #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility    : visible;
  opacity       : 1;
}

</style>

<script>

import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import VuePlyr from "vue-plyr";

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)


export default {
    name        : "Feed",
    
    components  : { "vue-plyr": VuePlyr },
    
    data() {
        return {
            
            savedprets      : [],
            
            options         : { controls: [ "play-large", "mute", "current-time", "duration" ], hideControls: false },
            
            userId          : "",
            vectorImages    : utilities.vectorImages,
            feedData: [],
            dialog          : false,
            cardFlatFlag    : true,
            cardOutlineFlag : false,
            taskId          : this.$route.params.q,
            taskData        : ""
        }
    },
    
    computed    : { ...mapGetters({ userData: "User/userData" }) },
    
    created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("TaskPrets Page");

        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

    },
    
    mounted() {
        
        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }
        
        let endPoint        = apis.urls.getTaskVideos;

        this.$store.commit("User/toggleProgressLoader", true);
        
        axios(endPoint, {
            method          :   "get",
            params          : { "taskId" : this.taskId },
            withCredentials :   true,
            headers         : { authorization : this.userData.token }
        })
        .then((res) => {
            console.log("res : ",res);
            
            if (res.data.result) {
                
                let data        = res.data.result.data;
                this.userId     = data.userId;
                this.feedData   = data.taskFeed;
                this.savedprets = data.saved;
                this.taskData   = data.taskData
            
            }
            
            this.$store.commit("User/toggleProgressLoader", false);

            // FIREBASE ANLYTICS
            firebaseAnlytics.logEvent("TaskPrets Displayed");

        })
        .catch((err) => {

            console.log("Error in FeedVideos.vue", err);
            let errorMsg = utilities.errorHandler(err);
            console.log(errorMsg);
            this.$store.commit("User/toggleProgressLoader", false);
        
        });
      
    },
    
    methods: {
        
        save(videoId){
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.savePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId },
                withCredentials :   true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                this.savedprets.push(videoId)
                
                this.$store.commit("User/toggleProgressLoader", false);

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Saved on TaskPrets Page");

            })
            .catch((err) => {

                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);

            });

        },
        
        unsave(videoId){
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.unSavePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId },
                withCredentials :   true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                
                this.savedprets.pop(videoId)
                
                this.$store.commit("User/toggleProgressLoader", false);

                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Unsaved on TaskPrets Page");

            })
            .catch((err) => {

                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
                
            });
        
        },
        
        likePret(index) {
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.likePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId : this.feedData[index]._id },
                withCredentials :   true,
                headers         : { authorization : this.userData.token }
            })
            .then((res) => {
                
                this.feedData[index].likes.push(this.userId);
                this.$store.commit("User/toggleProgressLoader", false);
                // this.$store.commit("User/showSuccessAlert", res.data.result);

                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Liked on TaskPrets Page");

            })
            .catch((err) => {

                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);

            });
        
        },
        
        unLikePret(index) {
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            let endPoint = apis.urls.unLikePret;
            
            axios(endPoint, {
                method          :   "post",
                data            : { videoId : this.feedData[index]._id},
                withCredentials :   true,
                headers         : { authorization : this.userData.token }
            })
            .then((res) => {
                
                this.feedData[index].likes.pop(this.userId);
                this.$store.commit("User/toggleProgressLoader", false);
                // this.$store.commit("User/showSuccessAlert", res.data.result);

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Unliked on TaskPrets Page");

            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);

            });

        },
    
    }
    
};

</script>

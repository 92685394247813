var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",style:(`background-color:${_vm.mergedConfig.core.colors.primary};margin-top: -3%; padding-bottom: -50%`)},[_c('v-container',[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":_vm.mergedConfig.core.info.cols,"md":_vm.mergedConfig.core.info.md,"order-md":_vm.mergedConfig.core.info.orderMd,"order":_vm.mergedConfig.core.info.order}},[_c('v-card',{staticClass:"responsive-margin-block1",style:(`background-color:${_vm.mergedConfig.core.colors.primary};`),attrs:{"max-width":"500","flat":""}},[_c('v-card-title',{staticClass:"text-h3"},[_c('p',{staticStyle:{"font-family":"'Poppins', sans-serif","font-weight":"700","font-size":"52px","line-height":"125%"}},[_c('span',{style:(`color:${_vm.mergedConfig.core.colors.primary};-webkit-text-stroke: 2px ${_vm.mergedConfig.core.colors.secondary}`)},[_vm._v(" "+_vm._s(_vm.mergedConfig.info.title1)+" ")]),_c('span',{style:(`color:${_vm.mergedConfig.core.colors.secondary};-webkit-text-stroke: 1px ${_vm.mergedConfig.core.colors.primary}`)},[_vm._v(" "+_vm._s(_vm.mergedConfig.info.title2)+" ")])])]),(_vm.mergedConfig.info.subTitle)?_c('v-card-subtitle',[_c('p',{style:(`color:${_vm.mergedConfig.core.colors.third};
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin: 0px;`)},[_vm._v(" "+_vm._s(_vm.mergedConfig.info.subTitle)+" ")])]):_vm._e(),(_vm.mergedConfig.info.ctaButton.isShow)?_c('v-card-actions',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":_vm.mergedConfig.info.ctaButton.link}},[_c('v-btn',{style:(`color:${_vm.mergedConfig.core.colors.secondary};`),attrs:{"outlined":"","rounded":_vm.mergedConfig.info.ctaButton.isRounded}},[_vm._v(" "+_vm._s(_vm.mergedConfig.info.ctaButton.title)+" ")])],1)],1):_vm._e(),_vm._l((_vm.mergedConfig.videos),function(videoItem){return _c('span',[_c('v-card-actions',{style:(`color:${_vm.mergedConfig.core.colors.third};
                font-size: 18px;
              font-weight: 400;
              line-height: 40px;
              margin: 0px;`)},[_vm._v(" "+_vm._s(videoItem.title)+" ")]),_c('v-card-actions',{staticStyle:{"margin-top":"5%"}},[_c('iframe',{style:(`border: 5px double ${_vm.mergedConfig.core.colors.secondary};border-radius: 10px;`),attrs:{"width":"560","height":"315","src":videoItem.URL,"title":"YouTube video player","frameborder":"0","allow":"","allowfullscreen":""}})])],1)})],2)],1),_c('v-col',{attrs:{"cols":_vm.mergedConfig.core.media.cols,"md":_vm.mergedConfig.core.media.md,"order-md":_vm.mergedConfig.core.media.orderMd,"order":_vm.mergedConfig.core.media.order}},[_c('div',{staticClass:"responsive-margin-block2"},_vm._l((_vm.mergedConfig.images),function(imageItem){return _c('span',[_c('v-img',{staticStyle:{"margin-left":"auto","margin-right":"auto","margin-bottom":"15%","height":"100%","width":"100%"},attrs:{"eager":"","src":imageItem.URL}})],1)}),0)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
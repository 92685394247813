<template>
    <div>
    
    
        <v-fab-transition>
            <v-btn
                @click="goBack"
                color="primary"
                style="position: fixed; bottom: 6%; right: 5%; z-index: 1000"
                dark
                absolute
                bottom
                right
                fab
                v-if="iosBackButtonFlag"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
        </v-fab-transition>
        
        <v-container style="padding-top: 0px !important">
            
            <!-- Sign in with us Dialogue -->
            <v-dialog v-model="signInWithUsDialog" persistent max-width="300">
                <v-card>
                    <v-card-title class="headline"> Create Account with us </v-card-title>
                    <v-card-text>Sign in with Google to uploade Prets, Comment, Flag or like Prets or To follow people.</v-card-text>
                    
                    <v-card-actions>
                    
                        <v-spacer></v-spacer>
                        
                        <v-btn color="green darken-1" text @click="signInWithUsDialog = false"> Not Now </v-btn>
                        <v-btn color="green darken-1" text router @click="signInWithUsRedirect"> Create </v-btn>
                    
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <!-- Grade Dialogue -->
            <v-dialog v-model="gradeDialog" max-width="350">
                <v-card>
                    <v-card-title class="headline">Select the Grades </v-card-title>
                    <p v-if="!defaultGradingParameters" style="color: red;margin: 2%;">Note: This grading parameters & gradings given by you are only visible to you, you can share this with students by downloading the excel sheet from group section.</p>
                    <v-list two-line class="ma-0 pa-0">

                        <v-list-item v-for="(item, key) in gradeParameters">
                            <span style="color: #1976d2 !important">{{ key }}</span>
                            <v-spacer></v-spacer>
                            <v-rating v-model="gradeParameters[key]" color="primary" background-color="primary darken-1"></v-rating>
                        </v-list-item>
                    
                    </v-list>

                    <p v-if="!defaultGradingParameters && videoUploadedAfterDeadline" style="color: red;margin: 2%;">
                        <b>Note: This video is uploaded after deadline.</b> <br />
                        <ul>
                            <li>Task Deadline Date : {{ new Date(this.watchResults.date) }}</li>
                            <li>Video upload Date  : {{ new Date(this.taskData.deadline) }} </li>
                        </ul>
                    </p>
                    
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="gradeDialog = false">Cancel</v-btn>
                        <v-btn color="green darken-1" text @click="gradePret">Grade</v-btn>
                    </v-card-actions>
                
                </v-card>
            
            </v-dialog>
            
            <!-- Share dialogue -->
            <v-dialog v-model="shareDialog" persistent max-width="560">
                <v-card>
                    <v-card-title class="headline">Copy link</v-card-title>
                    <v-card-text> Please upadate your browser so you can directly share your resume, otherwise you can use the below link, click on copy button to copy the link</v-card-text>
                    
                    <v-text-field id="myLink" v-model="shareableLink" class="ma-3"></v-text-field>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        
                        <div class="tooltip">
                            <button>
                                <span class="tooltiptext" id="myTooltip">Copy to clipboard</span>
                                <v-btn color="green darken-1" text @click="copyLink" @mouseout="outFunc" >Copy</v-btn>
                            </button>
                        </div>
                        
                        <v-btn color="green darken-1" text @click="shareDialog = false">Close</v-btn>
                    </v-card-actions>
                
                </v-card>
            
            </v-dialog>
            
            <!-- Flag dialogue -->
            <v-dialog v-model="flagDialog" max-width="350">
                <v-card>
                    <v-card-title class="headline">Mark as Inappropriate ?</v-card-title>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="flagDialog = false">No</v-btn>
                        <v-btn color="green darken-1" text @click="flagPret">Yes</v-btn>
                    </v-card-actions>
                
                </v-card>
            </v-dialog>
            
            <!-- Comment Dialogue delete -->
            <v-dialog v-model="commentDeleteDialog" max-width="350">
                <v-card>
                    <v-card-title class="headline">Are you sure ?</v-card-title>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="commentDeleteDialog = false">    No  </v-btn>
                        <v-btn color="green darken-1" text @click="deleteComment()">                Yes </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <!-- ------------------------------------------------------------------------------------------------------------------------------------ -->
            <v-layout row wrap justify-space-around>
                <v-flex xs12 md12>
                    
                    <!-- Video card started -->
                    <v-card v-if="watchResults.length != 0" :key="watchResults._id" flat class="mx-auto">
  
                        <div class="video-container">
  <vue-plyr :options="options" class="playerHeight">
    <video
      class="player"
      style="--plyr-color-main: #2196f3;"
      :src="watchResults.videoUrl"
      controls
      playsinline
      :data-poster="watchResults.thumbnailUrl"
    ></video>
  </vue-plyr>
</div>


                        
                        <!-- Video options -->
                        <v-list-item three-line>
                            
                            <!-- Title and all views and likes details -->
                            <v-list-item-content>
    
                                <v-list-item-title> {{ watchResults.title }}</v-list-item-title>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-subtitle v-bind="attrs" v-on="on">{{ watchResults.score }} Video Score </v-list-item-subtitle>
                                    </template>
                                    
                                    <span>Video Score = Avarage Grade  * Grade Length + Views + comments.</span>
                                </v-tooltip>
    
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-subtitle v-bind="attrs" v-on="on">{{ watchResults.viewsLength }} views </v-list-item-subtitle>
    
                                    </template>
                                    
                                    <span>How many users has viewed this video.</span>
                                </v-tooltip>
    
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-subtitle v-bind="attrs" v-on="on">{{ watchResults.blankViewsLength }} Blank-Views</v-list-item-subtitle>
                                    </template>
                                    
                                    <span>How much time this video has been served.</span>
                                </v-tooltip>
    
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-subtitle v-bind="attrs" v-on="on">{{ watchResults.likesLength }} Likes </v-list-item-subtitle>
                                    </template>
                                    
                                    <span>How many users has liked this video.</span>
                                </v-tooltip>
    
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-subtitle v-bind="attrs" v-on="on">{{ gradeData.gradeCount }} Grades </v-list-item-subtitle>
                                    </template>
                                    
                                    <span>How many users has graded this video.</span>
                                </v-tooltip>
    
                                
                                
                                
                            
                            </v-list-item-content>
                            
                            <!-- All icons -->
                            <v-btn  color="red"     icon    @click="unLikePret" v-if="likeFlag"     ><v-icon>mdi-heart              </v-icon></v-btn>
                            <v-btn  color="primary" icon    @click="likePret"   v-if="unLikeFlag"   ><v-icon>mdi-heart-outline      </v-icon></v-btn>
                            <v-btn  color="primary" icon    @click="flagDialog = true"              ><v-icon>mdi-flag               </v-icon></v-btn>
                            <v-btn  color="primary" icon    @click="share()"                        ><v-icon>mdi-share-variant      </v-icon></v-btn>
                            <v-btn  color="primary" icon    @click="unsave()"   v-if="savedFlag"    ><v-icon>mdi-bookmark           </v-icon></v-btn>
                            <v-btn  color="primary" icon    @click="save()"     v-if="unsavedFlag"  ><v-icon>mdi-bookmark-outline   </v-icon></v-btn>
                            
                            <v-btn color="primary" icon @click="showDescriptionFlag = !showDescriptionFlag">
                                <v-icon>{{ showDescriptionFlag ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                            </v-btn>
                        
                        </v-list-item>
                        
                        <!-- Deleted the expection  -->
                        
                        <v-expand-transition>
                            
                            <div v-show="showDescriptionFlag">
                                <v-card-text>
                                    
                                    Uploaded on   :   {{ pretUpladeDate }}            <br/>
                                    Full Title  :   {{ watchResults.title }}        <br/>
                                    Description :   {{ watchResults.description }}  <br/> <br/>
                                    
                                    <v-list two-line class="ma-0 pa-0">
                                        
                                        <v-list-item>
                                            <span style="color: #1976d2 !important">Language Proficiency</span>
                                            <v-spacer></v-spacer>
                                            <v-rating readonly v-model="avgGrades.language" color="primary" background-color="primary darken-1"></v-rating>
                                        </v-list-item>
    
                                        <v-list-item>
                                            <span style="color: #1976d2 !important">Voice Modulation</span>
                                            <v-spacer></v-spacer>
                                            <v-rating readonly v-model="avgGrades.voice" color="primary" background-color="primary darken-1"></v-rating>
                                        </v-list-item>
    
                                        <v-list-item>
                                            <span style="color: #1976d2 !important">Eye Contact, Hand Gestures and Body Movement </span>
                                            <v-spacer></v-spacer>
                                            <v-rating readonly v-model="avgGrades.gestures" color="primary" background-color="primary darken-1"></v-rating>
                                        </v-list-item>
    
                                        <v-list-item>
                                            <span style="color: #1976d2 !important">Confidence</span>
                                            <v-spacer></v-spacer>
                                            <v-rating readonly v-model="avgGrades.confidence" color="primary" background-color="primary darken-1"></v-rating>
                                        </v-list-item>
    
                                        <v-list-item>
                                            <span style="color: #1976d2 !important">Content Delivery and Knowledge</span>
                                            <v-spacer></v-spacer>
                                            <v-rating readonly v-model="avgGrades.content" color="primary" background-color="primary darken-1"></v-rating>
                                        </v-list-item>
    
                                        <v-list-item>
                                            <span style="color: #1976d2 !important">Avarage Grading</span>
                                            <v-spacer></v-spacer>
                                            <v-rating readonly  v-model="avgVideoGrade" color="primary" background-color="primary darken-1"></v-rating>
                                        </v-list-item>
                                    
                                    </v-list>
                                
                                </v-card-text>
                            </div>
                        
                        </v-expand-transition>

                        <v-chip v-if="watchResults.tags" v-for="(item, index) in watchResults.tags"
                                class="ma-2"
                                >
                            #{{item}}
                        </v-chip>
                        
                        <!-- Grading -->
                        <v-list-item two-line>
                            
                            <v-rating readonly v-model="avgVideoGrade" color="primary" background-color="primary darken-1"></v-rating>
                            <v-spacer></v-spacer>
                            
                            <v-btn  v-if="!gradedFlag"  class="ma-2"    outlined    color="primary" @click="gradeDialog = true" >   Grade   </v-btn>
                            <v-btn  v-if="gradedFlag"   class="ma-2"    text        color="grey"                                >   Graded  </v-btn>
                        
                        </v-list-item>
                        
                        <v-list-item two-line>
                            
                            <v-list-item-avatar>
                                <img :src="watchResults.userId.profile.profilePhoto" />
                            </v-list-item-avatar>
                            
                            <v-list-item-content>
                            
                                <router-link style="text-decoration: none; color: black" :to="'/' + watchResults.userId._id">
                                    <v-list-item-title>{{ watchResults.userId.profile.name }}</v-list-item-title>
                                </router-link>
                                
                                <v-list-item-subtitle v-if='false'>{{ watchResults.userId.followers.length }} Followers</v-list-item-subtitle>
                            
                            </v-list-item-content>
                            
                            <v-btn @click="followPreskilet"     v-if="false"   class="ma-2" text color="primary">  Follow </v-btn>
                            <v-btn @click="unFollowPreskilet"   v-if="false" class="ma-2" text color="grey">     UNFollow </v-btn>
                        
                        </v-list-item>
    
    
                        
    
                        <!-- This video is a submission -->
    
                      
    
                        <v-alert
                            v-if="watchResults.taskId && watchResults.institutionId"
                            border="left"
                            elevation="1"
                            colored-border
                            type="info"
                            style="margin: 5px 25px 1px 15px;"
                        >
                            <span style="font-size: 12px;">This video is a submission for task created by </span>
                            <a
                                :href="watchResults.institutionId.websiteLink"
                                style="text-decoration:none;"
                                class="pa-2"
                                target="_blank"
                                >{{watchResults.institutionId.name}} 
                                <v-icon color="primary" size="25">mdi-launch</v-icon>
                                </a>
                        
                        </v-alert>
    
                        <v-alert
                            v-if="!watchResults.taskId"
                            border="left"
                            elevation="1"
                            colored-border
                            type="info"
                            style="margin: 5px 25px 1px 15px;"
                            >
                            <span>This video is voluntarily Uploaded </span>
                        </v-alert> 
                        
                        <v-card-actions>
                            <v-btn color="primary" text> Comments </v-btn>
                            <v-spacer></v-spacer>
                            
                            <v-btn color="primary" icon @click="showCommentsFlag = !showCommentsFlag">
                                <v-icon>{{ showCommentsFlag ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                            </v-btn>
                        
                        </v-card-actions>
                        
                        <v-expand-transition>
                            <div v-show="showCommentsFlag">
                                <v-card-text>
                                    <v-textarea
                                        ref="form"
                                        :rules="commentRules"
                                        v-model="comment"
                                        rows="1"
                                        auto-grow
                                        label="Comment here ..."
                                        counter="2000"
                                        clearable
                                        append-outer-icon="mdi-send"
                                        @click:append-outer="validate"
                                    ></v-textarea>
                                    
                                    <!-- comments starting here -->
    
                                    <p v-if="commentsPret.length == 0">No comments</p>
                                    
                                    <v-card 
                                        v-for="(commentItem,Index) in commentsPret.slice().reverse()"
                                        :key="commentItem._id"
                                        flat
                                        class="mx-auto"
                                    >
                                        <v-list-item three-line>
                                            
                                            <v-list-item-avatar><img :src="commentItem.userId.profile.profilePhoto" /> </v-list-item-avatar>
                                            
                                            <v-list-item-content>
                                            
                                                <router-link style="text-decoration: none; color: black" :to="'/' + commentItem.userId._id">
                                                    <v-list-item-title>{{ commentItem.userId.profile.name }}</v-list-item-title>
                                                </router-link>
                                                
                                                <v-list-item-title style="white-space: normal;display: block;color: grey;font-size: 14px;">
                                                    <p>{{ commentItem.comment }}</p>
                                                </v-list-item-title>
                                            
                                            </v-list-item-content>
                                            
                                            <v-btn 
                                                v-if="commentItem.userId._id == userId"
                                                color="whitesmoke"
                                                icon
                                                @click="deleteCommentDialogue(commentItem._id, Index)"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        
                                        </v-list-item>
                                    
                                    </v-card>
    
    
                                </v-card-text>
                            
                            </div>
                        
                        </v-expand-transition>
                        
                        <!-- Google ads after comment-->
                            
                            <div v-if="userData.signedIn && userData.profile.account === 'Student'" class="ad-container" style="width: 100%; height: 100%;">
                                <Adsense
                                    style="display:block"
                                    data-ad-client="ca-pub-2789565594210548"
                                    data-ad-slot="5364274990"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"
                                ></Adsense>
                            </div>
                        
                        <br>
                        
                    </v-card>
                    
                    <v-flex xs12 md8 v-if="watchResultsNotFound" justify-space-around>
                        <v-card
                            flat
                            class="mx-auto"
                            max-width="400"
                        >

                            <v-img class="white--text align-end" :src="emptyStateImage.videoNotFound"></v-img>

                            <div class="d-flex flex-no-wrap justify-space-between">

                                <div>

                                    <v-card-title
                                    class="headline"
                                    v-text="'Not found, link is broken or invalid video id'"
                                    ></v-card-title>

                                    <v-card-subtitle v-text="emptyStateMessage.videoNotFound"></v-card-subtitle>

                                </div>

                            </div>
                        </v-card>
                    </v-flex>

                    <v-flex xs12 md8 v-if="videoIsDeleted" justify-space-around>
                        <v-card
                            flat
                            class="mx-auto"
                            max-width="400"
                        >

                            <v-img class="white--text align-end" :src="emptyStateImage.videoIsDeleted"></v-img>

                            <div class="d-flex flex-no-wrap justify-space-between">

                                <div>

                                    <v-card-title
                                    class="headline"
                                    v-text="'Video deleted by user'"
                                    ></v-card-title>

                                    <v-card-subtitle v-text="emptyStateMessage.videoIsDeleted"></v-card-subtitle>

                                </div>

                            </div>
                        </v-card>
                    </v-flex>
                
                </v-flex>
                
                <!-- Recommendations started here -->
                <!-- <v-flex xs12 md3 style="margin-top: -20px"> -->
    
                    <!-- <div v-for="item in exploreVideosArray" :key="item._id">
                        
                        <v-card v-if="item._id != watchQuery" flat class="mx-auto my-6">
                            
                            <router-link style="text-decoration: none; color: black" :to="'/watch?v=' + item._id">
                            
                                <v-img
                                    :src="item.thumbnailUrl"
                                    style="width: 100%; height: 250px"
                                    class="white--text align-end videoRecommendationThubmnail"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                    height="200px"
                                >
                                
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="white--text">{{ item.title }} </v-list-item-title>
                                            <v-list-item-subtitle class="white--text">{{ item.viewsLength }} Views</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-img>
                            </router-link>
    
                            <v-list-item two-line>
                                
                                <v-list-item-avatar>
                                    <img :src="item.userId.profile.profilePhoto" />
                                </v-list-item-avatar>
    
                                <v-list-item-content>
                                    <router-link style="text-decoration: none; color: black" :to="'/' + item.userId._id">
                                        <v-list-item-title>{{ item.userId.profile.name }}</v-list-item-title>
                                    </router-link>
    
                                    <v-list-item-subtitle v-if='false'>{{ item.userId.followersLength }} Followers </v-list-item-subtitle>
                                </v-list-item-content>
                            
                            </v-list-item>
                        
                        </v-card>
                    
                    </div>
    
                  
            <div class="text-center" v-if="loadMoreVideosProgress">
                
                <v-progress-circular
                  
                    :size="50"
                    color="blue"
                    dark
                    indeterminate>
                </v-progress-circular>
                
                <br><br>
                
            </div>
            
            <div class="text-center">
    
                <v-btn
                rounded
                color="primary"
                dark
                @click="exploreVideos"
                >
                    Load More Videos
                </v-btn>
            
            </div> -->
    
                    <!-- <br><br>     
    
    
                </v-flex> -->
            
            </v-layout>
        
        </v-container>
    
    </div>
    </template>
    
    <style scoped>
    .video-container {
    position: relative;
    width: 100%;          /* Full width of parent */
    height: 500px;        /* Fixed height limit */
    background-color: black;  /* Black bars background */
    display: flex;
    justify-content: center;
    align-items: center;  /* Center the video vertically and horizontally */
    overflow: hidden;     /* Prevent video from overflowing */
    }

    .video-container video {
    max-width: 100%;      /* Restrict the width to fit parent */
    max-height: 100%;     /* Restrict the height to fit parent */
    object-fit: contain;  /* Maintain aspect ratio and add black bars if needed */
    }

    /* Full width for video controls */
    .video-container .plyr {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;          /* Extend controls to full width */
    pointer-events: none; /* Make controls span but not interfere with video click */
    }

    .video-container .plyr__controls {
    pointer-events: all;  /* Allow interactions with the controls */
    }

    /* Fullscreen handling */
    video:-webkit-full-screen,
    video:-moz-full-screen,
    video:-ms-fullscreen,
    video:fullscreen {
    width: 100%;
    height: 100%;
    max-height: none;     /* Remove height restriction in fullscreen */
    object-fit: cover;    /* Ensure video covers the entire screen */
    }

    /* Media Query for Mobile Devices */
    @media (max-width: 768px) {
    .video-container {
        height: 250px;      /* Fixed height limit for mobile */
    }

    .video-container video {
        max-height: 250px; /* Restrict the height to fit mobile view */
    }
    }


    /* for mobile */
     @media screen and (max-width: 600px) {
      .videoRecommendationThubmnail {
        border-top   : 17px solid whitesmoke;
        border-bottom   : 17px solid whitesmoke;
      }
    }
    
    /* for desktop */
    @media screen and (min-width: 650px) {
      .videoRecommendationThubmnail {
        border-radius   : 7px;
      }
    }
    
    .form-container {
      padding   : 20px;
    }
    
    .tooltip {
      position  : relative;
      display   : inline-block;
    }
    
    .tooltip .tooltiptext {
      visibility        : hidden;
      width             : 140px;
      background-color  : #555;
      color             : #fff;
      text-align        : center;
      border-radius     : 6px;
      padding           : 5px;
      position          : absolute;
      z-index           : 1;
      bottom            : 150%;
      left              : 50%;
      margin-left       : -75px;
      opacity           : 0;
      transition        : opacity 0.3s;
    }
    
    .tooltip .tooltiptext::after {
      content       : "";
      position      : absolute;
      top           : 100%;
      left          : 50%;
      margin-left   : -5px;
      border-width  : 5px;
      border-style  : solid;
      border-color  : #555 transparent transparent transparent;
    }
    
    .tooltip:hover .tooltiptext {
      visibility    : visible;
      opacity       : 1;
    }
    
    </style>
    
    <script>
    
    import { mapGetters } from "vuex";
    
    import utilities from "@/helpers/utilities";
    import apis from "../helpers/apiConfig";
    const axios = require("axios").default;
    
    import firebase from "firebase/app";
    import "firebase/firebase-analytics";
    var firebaseAnlytics;
    
    // Google Adsense    
    import Vue from "vue";
    import Ads from 'vue-google-adsense'
    Vue.use(require('vue-script2'))
    Vue.use(Ads.Adsense)
    Vue.use(Ads.InFeedAdsense)
    
    import VuePlyr from "vue-plyr";
    
    export default {
        name        :   "Watch",
        
        computed    : { ...mapGetters({ userData: "User/userData" }) },
        
        components  : { "vue-plyr": VuePlyr },
        
        watch       : { $route(to, from) { window.location.reload() } },
        
        mounted() {
    
            // FIREBASE ANLYTICS
            firebaseAnlytics = firebase.analytics();
            firebaseAnlytics.logEvent("Watch Page");
            
            this.$store.commit("User/toggleProgressLoader", false);
            this.$store.dispatch("User/updateUserToken", null, { root: true });
            this.$store.commit("User/isSignedIn");
        
            this.checkIOS();
            
            if (this.watchQuery == undefined) {
                this.$store.commit("User/toggleProgressLoader", false);
            } else {
    
                this.loadPretVideo();
                this.getAvgGrades();
                this.goUp();
    
            }
    
        },
        
        data() {
    
            return {
                loadMoreVideosProgress  : false,
                excludeVideoIDs        : [],
                
                gradeDialog : false,
                gradedFlag  : false,
    
                // grades set by user while giving the grade
                grades          : {
                    language         : 1,
                    voice       : 1,
                    gestures    : 1,
                    confidence  : 1,
                    content     : 1
                },
                
                //avgGrades of the video
                avgGrades       : {
                    language         : 0,
                    voice       : 0,
                    gestures    : 0,
                    confidence  : 0,
                    content     : 0
                },
                
                avgVideoGrade   : 0,
                
                options         : { 
                  
                    controls: [ "play-large", "play", "progress", "current-time", "duration", "mute", "volume", "fullscreen" ], 
                },
                
                showDescriptionFlag : false,
                showCommentsFlag    : true,
                watchQuery          : this.$route.query.v,
                commentDeleteDialog : false,
                watchResults        : [],
                pretUpladeDate      : "",
                watchResultsNotFound: false,
                videoIsDeleted      : false,
                
                //check ios devices to show the back button
                iosBackButtonFlag   : false,
                
                //Sharing and flag
                signInWithUsDialog  : false,
                shareDialog         : false,
                shareableLink       : "https://preskilet.com/watch?v=" + this.$route.query.v,
                flagDialog          : false,
                commentRules        : [ (v) => (v && v.length <= 2000) || "Comment must be less than 2000 characters",],
    
                // Variables to be sent from backend
                likeFlag            : false,
                unLikeFlag          : false,
                savedFlag           : false,
                savedvideos         : [],
                unsavedFlag         : false,
                
                saved               : false,
                followFlag          : false,
                unfollowFlag        : false,
                
                //addtional parameters
                userId              : "",
                videoUserId         : "",
                
                //dynamic additional parameters , not from backend
                comment             : "",
                commentsPret        : [],
                deleteCommentId     : "",
                deleteCommentIndex  : "",
                recommendationsVideosArray: [],
                exploreVideosArray  : [],
    
                gradeData           : [],
                gradeParameters     : {},
                taskData            : {},
                defaultGradingParameters: false,
                organizationRedirectURL : "",

                videoUploadedAfterDeadline: false,

                emptyStateImage: {
                    videoNotFound: utilities.emptyState.videoNotFound.image,
                    videoIsDeleted: utilities.emptyState.videoIsDeleted.image,
                },

                emptyStateMessage: {
                    videoNotFound: utilities.emptyState.videoNotFound.message,
                    videoIsDeleted: utilities.emptyState.videoIsDeleted.message
                } 
            }
        
        },
        
        methods: {
            setOrganizationUrl(){
                
                if(this.watchResults.taskId)
                {
                    
                    if (this.watchResults.taskId.organization === "MIT Art Design And Technology University (MIT ADTU) "){
                        this.organizationRedirectURL = "https://mituniversity.ac.in/";
                    } else if(this.watchResults.taskId.organization.toLowerCase().includes("trinity")){
                        this.organizationRedirectURL = "https://www.kjei.edu.in/tcoer/";
                    } else if(this.watchResults.taskId.organization.toLowerCase().includes("immense")){
                        this.organizationRedirectURL = "https://immense-spoken-english-and-soft-skills.business.site/";
                    } else if(
                        this.watchResults.taskId.organization.toLowerCase().includes("acsc") ||
                        this.watchResults.taskId.organization.toLowerCase().includes("arts") ||
                        this.watchResults.taskId.organization.toLowerCase().includes("science")
                        ){
                        this.organizationRedirectURL = "https://mitacsc.ac.in";
                    } else if(this.watchResults.taskId.organization.toLowerCase().includes("mit")){
                        this.organizationRedirectURL = "https://mitaoe.ac.in/admission.php";
                    } else if(this.watchResults.taskId.organization.toLowerCase().includes("wordsmaya")){
                        this.organizationRedirectURL = "https://wordsmaya.com/";
                    }
                    else{
                        this.organizationRedirectURL = "https://www.google.com/search?q=" + this.watchResults.taskId.organization + " admissions";
                    }
                }
            },
            
            signInWithUsRedirect() { window.location.replace("/signin") },
            
            loadPretVideo() {
                
                this.$store.commit("User/toggleProgressLoader", true);
                
                var endPoint = apis.urls.viewPret;
                
                axios(endPoint, {
                    method          :   "get",
                    params          : { videoId : this.watchQuery},
                    withCredentials :   true,
                    headers         : { authorization : this.userData.token }
                })
                .then((res) => {
    
                    console.log("pret data ", res);
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Pret Loaded on Watch Page");
                    
                    if(res.data.result.data?.isDeleted) {
                        this.$store.commit("User/showErrorAlert", "User has deleted the video");
                        this.videoIsDeleted = true;
                    } else {

                    this.watchResults   = res.data.result.data.watchResults;
                    this.savedvideos    = res.data.result.data.saved;
                    this.userId         = res.data.result.data.userId;
                    this.gradeData      = res.data.result.data.gradeData;
                    this.gradeParameters = res.data.result.data.gradeParameters;
                    this.taskData = res.data.result.data.watchResults.taskId;

                    if(this.taskData?.deadline) {
                        const videoUploadDate = new Date(this.watchResults.date);
                        const taskDeadline = new Date(this.taskData.deadline);
                        const isAfterDeadline = videoUploadDate > taskDeadline;
                        console.log("videoUploadDate ", videoUploadDate);
                        console.log("taskDeadline ", taskDeadline);
                        console.log("\n isAfterDeadline ", isAfterDeadline);
                        if(isAfterDeadline) {
                            this.videoUploadedAfterDeadline = true;
                        }
                    }

                    // this.gradeParameters = {
                    //     "Language Ability": 0,
                    //     "Voice Modulation": 0,
                    //     "Eye contact, Hand Gestures and Body Movement": 0,
                    //     "Confidence": 0,
                    //     "Content Delivery and Knowledge": 0,
                    // };

                    if(
                        Object.keys(this.gradeParameters).includes("language") &&
                        Object.keys(this.gradeParameters).includes("voice") &&
                        Object.keys(this.gradeParameters).includes("gestures") &&
                        Object.keys(this.gradeParameters).includes("confidence") &&
                        Object.keys(this.gradeParameters).includes("content")                    
                    ) {
                        this.defaultGradingParameters = true;
                    }
                    
                    this.avgVideoGrade  = this.watchResults.grades;
                    
                    
                    const UplaodDateTobeParsed = new Date(this.watchResults.date);
    
                    const monthFromDate = UplaodDateTobeParsed.getMonth() + 1;
                    
                    this.pretUpladeDate = 
                        UplaodDateTobeParsed.getDate()  +   "/" +
                        monthFromDate +   "/" +
                        UplaodDateTobeParsed.getFullYear();
    
                    this.setOrganizationUrl();
                        
                    if (this.watchResults.length == 0) {
                        this.watchResultsNotFound = true;
                    } else {
                        
                        // setting the additional parameters
                        this.videoUserId    = this.watchResults.userId._id;
                        
                        //setting comments
                        this.commentsPret   = this.watchResults.comments;
    
                        //follow unfollow buttons
                        if (this.videoUserId == this.userId) {
    
                            this.followFlag     = false;
                            this.unfollowFlag   = false;
                        
                        } else {
                            
                            if (this.watchResults.userId.followers.includes(this.userId)) {
                                this.unfollowFlag = true;
                            } else {
                                this.followFlag = true;
                            }
                        
                        }
                        
                        //Saved or unsaved pret
                        if (this.savedvideos.includes(this.watchResults._id)) {
                        
                            this.savedFlag      = true;
                            this.unsavedFlag    = false;
                        
                        } else {
    
                            this.savedFlag      = false;
                            this.unsavedFlag    = true;
                        
                        }
    
                        //like unlike heart
                        if ( this.watchResults.likes.includes(this.userId) ) {
                            
                            this.likeFlag   = true;
                            this.unLikeFlag = false;
    
                        } else {
    
                            this.likeFlag   = false;
                            this.unLikeFlag = true;
    
                        }
    
                        //Grade or graded button
                        if (this.gradeData.graded) {
                            this.gradedFlag = true
                        } else {
                            this.gradedFlag = false
                        }
                        
                        //setting the recommondations videos **Currently random videos
                        // this.exploreVideos();
                    }
                    }

                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                    this.watchResultsNotFound = true;
                
                });
    
            },
            
            unsave(){
                
                if (!this.userData.signedIn) {
                    this.signInWithUsDialog = true;
                } else {
                    
                    this.$store.commit("User/toggleProgressLoader", true);
                    this.goUp();
    
                    let data        = { videoId : this.watchResults._id };
                    let endPoint    = apis.urls.unSavePret;
                    
                    axios(endPoint, {
                        method          : "post",
                        data,
                        withCredentials : true,
                        headers         : { authorization: this.userData.token },
                    })
                    .then((res) => {
    
                        this.savedFlag      = false;
                        this.unsavedFlag    = true;
    
                        this.$store.commit("User/toggleProgressLoader", false);
                        
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Pret Unsaved on Watch Page");
                    
                    })
                    .catch((err) => {
    
                        this.$store.commit("User/toggleProgressLoader", false);
                        console.log("Error In watch",err);
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    
                    });
    
                }
    
            },
    
            save(){
            
                if (!this.userData.signedIn) {
                    this.signInWithUsDialog = true;
                } else {
    
                    this.$store.commit("User/toggleProgressLoader", true);
                    this.goUp();
    
                    let data        = { videoId: this.watchResults._id };
                    let endPoint    = apis.urls.savePret;
                    
                    axios(endPoint, {
                        method          : "post",
                        data,
                        withCredentials : true,
                        headers         : { authorization: this.userData.token },
                    })
                    .then((res) => {
    
                        this.savedFlag      = true;
                        this.unsavedFlag    = false;
    
                        this.$store.commit("User/toggleProgressLoader", false);                    
                        
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Pret Saved on Watch Page");                
                    })
                    .catch((err) => {
                    
                        this.$store.commit("User/toggleProgressLoader", false);
                        console.log("Error In Watch",err);
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    
                    });
    
                }
    
            },
    
            recommendationsVideos() {
    
                let endPoint = apis.urls.recommendations;
                this.$store.commit("User/toggleProgressLoader", true);
    
                let params = {
                    videoId     : this.watchResults._id,
                    videoUserId : this.videoUserId,
                    tags        : this.watchResults.tags,
                };
    
                var that = this;
    
                axios(endPoint, {
                    method          : "get",
                    params,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
                    this.$store.commit("User/toggleProgressLoader", false);
    
                    console.log("Recommendations : ", res.data.result.data);
                    let videoArray = res.data.result.data;
                    
                    // So same video will not be shown twice in the recommendations
                    that.recommendationsVideosArray = videoArray.filter((video)=>{
                        return video._id != this.watchQuery;
                    })
                    
                    // if videos are less than 4 then we need to add more videos
                    if (that.recommendationsVideosArray.length <= 4) {
                        that.exploreVideos();
                    }
    
                                        
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Pret Recommendations Displayed on Watch Page");
    
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error In Watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });
    
            },
    
            exploreVideos() {
                this.loadMoreVideosProgress = true;
                
                let endPoint = apis.urls.exploreVideos;
                let payloadData;
                console.log("userdata in watch : ",this.userData.signedIn)
                if(this.userData.signedIn)
                {
                   payloadData = {
                        method :   "post",
                        data   : { excludeVideoIDs : this.excludeVideoIDs },
                        withCredentials :   true,
                        headers         : { authorization : this.userData.token }
                   }
                }else
                {
                    payloadData = {
                        method :  "post",
                        data   : { excludeVideoIDs : this.excludeVideoIDs },
                        withCredentials :   true,
                        headers         : { authorization : this.userData.token }
                    }
                }
    
                
                axios(endPoint, payloadData)
                .then((res) => {
                                console.log("explore video data: ", res);
    
                    this.loadMoreVideosProgress = false;
                    
                    var videosData = res.data.result.data.feed
                    
                    if(videosData.length === 0) {
                        this.$store.commit("User/showErrorAlert", "There are no more videos");
                    } else {
                        
                        if(this.excludeVideoIDs.length === 0){
                            // FIREBASE ANLYTICS
                            firebaseAnlytics.logEvent("Explore Video on Watch Displayed");
                        } else {
                            // FIREBASE ANLYTICS
                            firebaseAnlytics.logEvent("Explore Video on Watch (Load more Video) button clicked");
                        }
                        
                        for (let i = 0; i < videosData.length; i++) {
                            const element = videosData[i];
                            this.exploreVideosArray.push(element)
                            this.excludeVideoIDs.push(element._id);
                        }
    
                        console.log("excludeVideoIDs 2 : ",this.excludeVideoIDs);
    
    
                    }
                               
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Pret Recommendations Displayed on Watch Page");
    
                    /*
    
                    // So same video will not be shown twice in the recommendations
                    let recommendationsVideos = videoArray.filter((video)=>{
                        return video._id != this.watchQuery;
                    })
    
                    console.log("this is recommendationsVideos : ",recommendationsVideos);
                    
                    
                    if(that.recommendationsVideosArray.length === 0){
                       that.recommendationsVideosArray = recommendationsVideos;
                    }
                    else{
    
                        for (let i = 0; i < recommendationsVideos.length; i++) {
                            
                            const element = recommendationsVideos[i];
    
                            let flag = 0;
    
                            for (let j = 0; j < that.recommendationsVideosArray.length; j++) {
    
                                if( JSON.stringify(element._id) === JSON.stringify(that.recommendationsVideosArray[j]._id) )
                                {
                                    flag = 1;
                                }
                                
                            }
    
                            if(flag === 0)
                            {
                                that.recommendationsVideosArray.push(element)
                            
                            }
                            
                        }
                        
    
                        //that.recommendationsVideosArray.concat(recommendationsVideos)
                        console.log("this is recommendationsVideosArray : ",that.recommendationsVideosArray);
                    }
                    
                    this.$store.commit("User/toggleProgressLoader", false);
    
                                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Pret Recommendations Displayed on Watch Page");
                    */
                
                })
                .catch((err) => {
                
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log(err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });
    
            },
    
            deleteComment() {
    
                this.commentDeleteDialog = false;
                console.log("comemnet id to be delete : ", this.deleteCommentId);
                console.log("watchquery : ", this.watchQuery);
    
                this.$store.commit("User/toggleProgressLoader", true);
    
                let data = {
                    videoId     : this.watchResults._id,
                    commentId   : this.deleteCommentId,
                };
    
                let endPoint = apis.urls.deleteComment;
                axios(endPoint, {
                    method          : "delete",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
    
                    console.log("delete comment output from back : ", res);
    
                    this.watchResults.commentsLength    -= 1;
                    this.commentsPret                   = res.data.result;
    
                    this.$store.commit("User/toggleProgressLoader", false);
    
                                        
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Comment Deleted on Watch Page");
                
                })
                .catch((err) => {
                        
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error In watch",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });
    
            },
    
            deleteCommentDialogue(commentId, Index) {
    
                this.commentDeleteDialog    = true;
                this.deleteCommentId        = commentId;
                this.deleteCommentIndex     = Index;
            
            },
    
            validate() {
    
                if (this.$refs.form.validate()) {
                    this.commentPret();
                }
    
            },
    
            copyLink() {
    
                var tooltip         = document.getElementById("myTooltip");
                tooltip.innerHTML   = "Link Copied !";
                var copiedText      = document.getElementById("myLink");
                
                copiedText.select();
                copiedText.setSelectionRange(0, 99999);
                document.execCommand("copy");
            
            },
    
            outFunc() {
    
                var tooltip         = document.getElementById("myTooltip");
                tooltip.innerHTML   = "Copy to clipboard";
            
            },
    
            share() {
    
                if (navigator.share) {
    
                    navigator
                    .share({
    
                        title   : "Preskilet",
                        text    : "Check out my live Video Profile on Preskilet",
                        url     : this.shareableLink,
                    
                    })
                    .then(() => console.log("Successful share is done"))
                    .catch((err) => {
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    });
    
                } else {
    
                    console.log("outdated browser , sharing cant be done directly, so share link using model");
                    this.shareDialog = true;
    
                }
    
            },
    
            goUp() {
                window.scrollTo(0, 0);
            },
    
            goBack() {
                window.history.back();
            },
    
            checkIOS() {
    
                if (
                    [
                    "iPad Simulator",
                    "iPhone Simulator",
                    "iPod Simulator",
                    "iPad",
                    "iPhone",
                    "iPod",
                    ].includes(navigator.platform)
                ) {
                    this.iosBackButtonFlag = true;
                }
    
            },
    
            flagPret() {
    
                this.flagDialog = false;
                
                if (!this.userData.signedIn) {
                    this.signInWithUsDialog = true;
                } else {
    
                    this.$store.commit("User/toggleProgressLoader", true);
                    this.goUp();
    
                    let data        = { videoId: this.watchResults._id };
                    let endPoint    = apis.urls.flagPret;
                    
                    axios(endPoint, {
                        method          : "post",
                        data,
                        withCredentials : true,
                        headers         : { authorization: this.userData.token }
                    })
                    .then((res) => {
    
                        this.$store.commit("User/toggleProgressLoader", false);
                        this.$store.commit("User/showSuccessAlert", res.data.result);
    
                                            
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Pret Flagged on Watch Page");
                    
                    })
                    .catch((err) => {
    
                        this.$store.commit("User/toggleProgressLoader", false);
                        console.log("Error In Watch",err);
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    
                    });
    
                }
    
            },
    
            commentPret() {
    
                if (!this.userData.signedIn) {
                    this.signInWithUsDialog = true;
                } else {
    
                    console.log("comment to be made : ", this.comment);
    
                    this.$store.commit("User/toggleProgressLoader", true);
                    this.goUp();
    
                    let data = {
                    videoId : this.watchResults._id,
                    comment : this.comment,
                    };
    
                    let endPoint = apis.urls.commentPret;
                    
                    axios(endPoint, {
                        method          : "post",
                        data,
                        withCredentials : true,
                        headers         : { authorization: this.userData.token }
                    })
                    .then((res) => {
    
                        console.log("thisi is comment response : ", res.data.result);
                        
                        //pushing the comment in the array of comments
                        this.watchResults.commentsLength += 1;
                        this.commentsPret = res.data.result;
                        
                        // this.commentsPret.push(res.data.result);
                        this.$store.commit("User/toggleProgressLoader", false);
    
                                            
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Comment Added on Watch Page");
                    
                    })
                    .catch((err) => {
                        
                        this.$store.commit("User/toggleProgressLoader", false);
                        console.log("Error In watch",err);
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    
                    });
    
                }
    
            },
    
            gradePret() {
                        
                if (!this.userData.signedIn) {
                    this.signInWithUsDialog = true;
                } else {

                    let gradeNotGiven = false;
                    Object.keys(this.gradeParameters).forEach(element => {
                        if(this.gradeParameters[element] === 0) {
                            gradeNotGiven = true;
                        }                        
                    });
                    
                    console.log("gradeNotGiven : ", gradeNotGiven);

                    if(!gradeNotGiven)
                    {
                        
                        this.$store.commit("User/toggleProgressLoader", true);
                        this.goUp();
    
                        let data = {
                        videoId : this.watchResults._id,
                        grades  : this.gradeParameters
                        };
    
                        console.log("grading the video now : ",data);
                        this.gradeDialog = false;
                
                        let endPoint = apis.urls.gradePret;
                        this.$store.commit("User/toggleProgressLoader", true);        
                        axios(endPoint, {
                            method          : "post",
                            data,
                            withCredentials : true,
                            headers         : {authorization : this.userData.token }
                        })
                        .then((res) => {
    
                            this.gradedFlag = false
                            this.$store.commit("User/toggleProgressLoader", false);
                            this.$store.commit("User/showSuccessAlert", res.data.result);
                            
                            this.gradeDialog    =  false
                            this.gradedFlag     = true
    
                            this.$store.commit("User/toggleProgressLoader", false);
                            // FIREBASE ANLYTICS
                            firebaseAnlytics.logEvent("Pret Graded on Watch Page");
    
                        })
                        .catch((err) => {
    
                            this.$store.commit("User/toggleProgressLoader", false);
                            console.log("Error In watch",err);
                            let errorMsg = utilities.errorHandler(err);
                            this.$store.commit("User/showErrorAlert", errorMsg);
    
                            this.gradeDialog    =  false
                            this.gradedFlag     = true
    
                        });
    
                    }
                    else{
                        this.$store.commit("User/showErrorAlert", "please grade all parameters");
                    }
    
                }
    
            },
    
            likePret() {
    
                if (!this.userData.signedIn) {
                    this.signInWithUsDialog = true;
                } else {
    
                    this.$store.commit("User/toggleProgressLoader", true);
                    this.goUp();
    
                    let data        = { videoId: this.watchResults._id };
                    let endPoint    = apis.urls.likePret;
                    
                    axios(endPoint, {
                        method          : "post",
                        data,
                        withCredentials : true,
                        headers         : { authorization   : this.userData.token } 
                    })
                    .then((res) => {
    
                        this.likeFlag   = true;
                        this.unLikeFlag = false;
                        this.watchResults.likesLength += 1;
                        
                        this.$store.commit("User/toggleProgressLoader", false);
    
                                            
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Pret Liked on Watch Page");
                    
                    })
                    .catch((err) => {
                        
                        this.$store.commit("User/toggleProgressLoader", false);
                        console.log("Error In Watch",err);
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    
                    });
    
                }
            },
    
            unLikePret() {
    
                if (!this.userData.signedIn) {
                    this.signInWithUsDialog = true;
                } else {
    
                    this.$store.commit("User/toggleProgressLoader", true);
                    this.goUp();
    
                    let data        = { videoId: this.watchResults._id };
                    let endPoint    = apis.urls.unLikePret;
                    
                    axios(endPoint, {
                        method          : "post",
                        data,
                        withCredentials : true,
                        headers         : { authorization: this.userData.token }
                    })
                    .then((res) => {
    
                        this.likeFlag   = false;
                        this.unLikeFlag = true;
                        this.watchResults.likesLength -= 1;
                        this.$store.commit("User/toggleProgressLoader", false);
    
                                            
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Pret Unliked on Watch Page");
    
                    })
                    .catch((err) => {
                        
                        this.$store.commit("User/toggleProgressLoader", false);
                        console.log("Error In Watch",err);
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    
                    });
    
                }
    
            },
    
            savePret() {
    
                if (!this.userData.signedIn) {
                    this.signInWithUsDialog = true;
                } else {
                    this.saved = false;
                }
    
            },
    
            unSavePret() {
    
                if (!this.userData.signedIn) {
                    this.signInWithUsDialog = true;
                } else {
                    this.saved = true;
                }
    
            },
    
            followPreskilet() {
                
                if (!this.userData.signedIn) {
                    this.signInWithUsDialog = true;
                } else {
                    
                    this.$store.commit("User/toggleProgressLoader", true);
                    this.goUp();
    
                    let data        = { userId: this.videoUserId };
                    let endPoint    = apis.urls.follow;
                    
                    axios(endPoint, {
                        method          : "post",
                        data,
                        withCredentials : true,
                        headers         : { authorization: this.userData.token }
                    })
                    .then((res) => {
    
                        // just added something in the followers array so that it will increases the length of followers and followers will be updated dynamically
                        this.watchResults.userId.followers.push({ id: "anonymes" });
    
                        this.followFlag     = false;
                        this.unfollowFlag   = true;
                        this.$store.commit("User/toggleProgressLoader", false);
                        this.$store.commit("User/showSuccessAlert", res.data.result);
    
                                            
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("New Follow Operation on Watch Page");
                    
                    })
                    .catch((err) => {
                        
                        this.$store.commit("User/toggleProgressLoader", false);
                        console.log("Error in watch",err);
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    
                    });
    
                }
    
            },
    
            unFollowPreskilet() {
                
                if (!this.userData.signedIn) {
                    this.signInWithUsDialog = true;
                } else {
    
                    // just deleted something in the followers array so that it will increases the length of followers and followers will be updated dynamically
                    this.watchResults.userId.followers.pop();
    
                    this.$store.commit("User/toggleProgressLoader", true);
                    this.goUp();
    
                    let data        = { userId: this.videoUserId };
                    let endPoint    = apis.urls.unfollow;
                    
                    axios(endPoint, {
                        method          : "post",
                        data,
                        withCredentials : true,
                        headers         : { authorization: this.userData.token }
                    })
                    .then((res) => {
    
                        this.followFlag     = true;
                        this.unfollowFlag   = false;
    
                        this.$store.commit("User/toggleProgressLoader", false);
                        this.$store.commit("User/showSuccessAlert", res.data.result);
    
                                            
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("New Unfollow Operation on Watch Page");
                    
                    })
                    .catch((err) => {
                        
                        this.$store.commit("User/toggleProgressLoader", false);
                        console.log("Error In watch",err);
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    
                    });
    
                }
    
            },
    
            getAvgGrades(){
                
                let data        = { videoId: this.watchQuery };
                let endPoint    = apis.urls.getAvgGrades;
                
                axios(endPoint, {
                    method          : "get",
                    params: data,
                })
                .then((res) => {
                    console.log("This are the avg grades : ", res.data.result.data);
                    this.avgGrades = res.data.result.data;
    
                    this.$store.commit("User/toggleProgressLoader", false);
                    
                                        
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Average Grades Retrived on Watch Page");
    
                })
                .catch((err) => {
                
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error In Watch",err);
                    let errorMsg = utilities.errorHandler(err);
                
                });
    
            }
    
        }
    
    };
    
    </script>
<template>
<div class="home">
    
    <v-container>
        
        <v-layout row wrap justify-space-around>
            
            <v-flex xs11 md11>
                
                <v-row justify="center" class="text-center pa-0 ma-0">
                    <v-card-title v-text="'Terms and Conditions'"></v-card-title>
                </v-row>

                <v-card color="white" outlined>
                    
                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                                <v-card-text>

                                    <div class="TermsAndConditions">
                                        <p>Last updated: July 21, 2020</p>

                                        <p>
                                        Please read these Terms and Conditions ("Terms", "Terms
                                        and Conditions") carefully before using the
                                        <a href="https://preskilet.com">https://preskilet.com</a>
                                        website (the "Service") operated by Preskilet ("us", "we",
                                        or "our").
                                        </p>

                                        <p>
                                        Your access to and use of the Service is conditioned on
                                        your acceptance of and compliance with these Terms. These
                                        Terms apply to all visitors, users and others who access
                                        or use the Service.
                                        </p>

                                        <p>
                                        By accessing or using the Service you agree to be bound by
                                        these Terms. If you disagree with any part of the terms
                                        then you may not access the Service.
                                        </p>

                                        <h2>Accounts</h2>

                                        <p>
                                        When you create an account with us, you must provide us
                                        information that is accurate, complete, and current at all
                                        times. Failure to do so constitutes a breach of the Terms,
                                        which may result in immediate termination of your account
                                        on our Service.
                                        </p>

                                        <p>
                                        You are responsible for safeguarding the password that you
                                        use to access the Service and for any activities or
                                        actions under your password, whether your password is with
                                        our Service or a third-party service.
                                        </p>

                                        <p>
                                        You agree not to disclose your password to any third
                                        party. You must notify us immediately upon becoming aware
                                        of any breach of security or unauthorized use of your
                                        account.
                                        </p>

                                        <h2>Links To Other Web Sites</h2>

                                        <p>
                                        Our Service may contain links to third-party web sites or
                                        services that are not owned or controlled by Preskilet.
                                        </p>

                                        <p>
                                        Preskilet has no control over, and assumes no
                                        responsibility for, the content, privacy policies, or
                                        practices of any third party web sites or services. You
                                        further acknowledge and agree that Preskilet shall not be
                                        responsible or liable, directly or indirectly, for any
                                        damage or loss caused or alleged to be caused by or in
                                        connection with use of or reliance on any such content,
                                        goods or services available on or through any such web
                                        sites or services.
                                        </p>

                                        <p>
                                        We strongly advise you to read the terms and conditions
                                        and privacy policies of any third-party web sites or
                                        services that you visit.
                                        </p>

                                        <h2>Termination</h2>

                                        <p>
                                        We may terminate or suspend access to our Service
                                        immediately, without prior notice or liability, for any
                                        reason whatsoever, including without limitation if you
                                        breach the Terms.
                                        </p>

                                        <p>
                                        All provisions of the Terms which by their nature should
                                        survive termination shall survive termination, including,
                                        without limitation, ownership provisions, warranty
                                        disclaimers, indemnity and limitations of liability.
                                        </p>

                                        <p>
                                        We may terminate or suspend your account immediately,
                                        without prior notice or liability, for any reason
                                        whatsoever, including without limitation if you breach the
                                        Terms.
                                        </p>

                                        <p>
                                        Upon termination, your right to use the Service will
                                        immediately cease. If you wish to terminate your account,
                                        you may simply discontinue using the Service.
                                        </p>

                                        <p>
                                        All provisions of the Terms which by their nature should
                                        survive termination shall survive termination, including,
                                        without limitation, ownership provisions, warranty
                                        disclaimers, indemnity and limitations of liability.
                                        </p>

                                        <h2>Governing Law</h2>

                                        <p>
                                        These Terms shall be governed and construed in accordance
                                        with the laws of Maharashtra, India, without regard to its
                                        conflict of law provisions.
                                        </p>

                                        <p>
                                        Our failure to enforce any right or provision of these
                                        Terms will not be considered a waiver of those rights. If
                                        any provision of these Terms is held to be invalid or
                                        unenforceable by a court, the remaining provisions of
                                        these Terms will remain in effect. These Terms constitute
                                        the entire agreement between us regarding our Service, and
                                        supersede and replace any prior agreements we might have
                                        between us regarding the Service.
                                        </p>

                                        <h2>Changes</h2>

                                        <p>
                                        We reserve the right, at our sole discretion, to modify or
                                        replace these Terms at any time. If a revision is material
                                        we will try to provide at least 30 days notice prior to
                                        any new terms taking effect. What constitutes a material
                                        change will be determined at our sole discretion.
                                        </p>

                                        <p>
                                        By continuing to access or use our Service after those
                                        revisions become effective, you agree to be bound by the
                                        revised terms. If you do not agree to the new terms,
                                        please stop using the Service.
                                        </p>

                                        <h2>Contact Us</h2>

                                        <p>
                                        If you have any questions about these Terms, please
                                        contact us.
                                        </p>

                                        <ul>

                                            <li>By email: contactus.preskilet@gmail.com</li>

                                        </ul>

                                    </div>

                                </v-card-text>

                        </div>

                    </div>

                </v-card>

                <br />
                <br />
                <br />

            </v-flex>

        </v-layout>

    </v-container>

  </div>
</template>

<style scoped>

h2 {
  margin-bottom : 3%;
}

</style>

<script>

import { mapGetters } from "vuex";

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

export default {
    
    name        : "TermsAndConditions",
    
    computed    : { ...mapGetters({ userData: "User/userData" }) },

    created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Termsandconditions Page");
    
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
    
    },

};

</script>

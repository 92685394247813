const currentEnv = "prod";

const envAdresses = {
	local: "http://localhost:3000",
	dev: "https://preskiletdev.herokuapp.com",
	prod: "https://preskilet-backend.el.r.appspot.com",
}
const currentHostList = {
	local: "http://localhost:8080",
	dev: "https://preskiletdev.firebaseapp.com",
	prod: "https://preskilet.com",
}

let apis = {
	env: currentEnv,
	configKeys: {
		local: {
			vapidKey: "BGxIb6enZYBfi_CTDv9QCkeN2GkGYuNOg0YFWMeWeCkZw3ej4r6iWFqFdvrdRd9Z7iVU6fHQVQdBWFBUN3edwu8",
			firebaseConfig: {
				apiKey: "AIzaSyD4epbaYSLvOYq-uzPlsf7txLdPw9cfiTo",
				authDomain: "preskiletdev.firebaseapp.com",
				databaseURL: "https://preskiletdev.firebaseio.com",
				projectId: "preskiletdev",
				storageBucket: "preskiletdev.appspot.com",
				messagingSenderId: "910345115417",
				appId: "1:910345115417:web:f14d32b4d8f9a890b71788",
				measurementId: "G-77H4WZYLRL"
			},
		},
		dev: {
			vapidKey: "BGxIb6enZYBfi_CTDv9QCkeN2GkGYuNOg0YFWMeWeCkZw3ej4r6iWFqFdvrdRd9Z7iVU6fHQVQdBWFBUN3edwu8",
			firebaseConfig: {
	
				apiKey: "AIzaSyD4epbaYSLvOYq-uzPlsf7txLdPw9cfiTo",
				authDomain: "preskiletdev.firebaseapp.com",
				databaseURL: "https://preskiletdev.firebaseio.com",
				projectId: "preskiletdev",
				storageBucket: "preskiletdev.appspot.com",
				messagingSenderId: "910345115417",
				appId: "1:910345115417:web:f14d32b4d8f9a890b71788",
				measurementId: "G-77H4WZYLRL"
	
			},
		},
		prod: {
			vapidKey: "BILARIVWuOT1-rOFnWc5kvkKaKLe0FIYZgxMLZD-dmwaJZvraC2Xsk_j-Cce30cjAbvlvEVGBfcyzrDgySRMVoY",
			firebaseConfig: {	
				apiKey: "AIzaSyDh5_aH2Evr-gaf1loa75_F_givatw-qpg",
				authDomain: "preskilet.com",
				databaseURL: "https://preskilet.firebaseio.com",
				projectId: "preskilet",
				storageBucket: "preskilet.appspot.com",
				messagingSenderId: "314123268583",
				appId: "1:314123268583:web:31513aa560b59a6043cd42",
				measurementId: "G-103P4P3RC3"
			},
		}
	},
	urls: {
		// currentEnvHostAddress
		currentHost: currentHostList[currentEnv],

		// signin and signout
		signInUser: `${envAdresses[currentEnv]}/api/signin`,
		signOut: `${envAdresses[currentEnv]}/api/signout`,

		// serach and Preskilet
		search: `${envAdresses[currentEnv]}/api/search`,
		searchVideos: `${envAdresses[currentEnv]}/api/search/video`,
		
		preskilet: `${envAdresses[currentEnv]}/api/user/preskilet`,
		blankPreskilet: `${envAdresses[currentEnv]}/api/user/blank/preskilet`,

		//make a pret
		pret: `${envAdresses[currentEnv]}/api/pret`,
		taskPret: `${envAdresses[currentEnv]}/api/task/pret`,
		deletePret: `${envAdresses[currentEnv]}/api/deletepret`,
		editPret: `${envAdresses[currentEnv]}/api/pret`,

		//profile
		getProfile: `${envAdresses[currentEnv]}/api/user/profile`,
		editProfile: `${envAdresses[currentEnv]}/api/user/profile`,
		follow: `${envAdresses[currentEnv]}/api/follow`,
		followGroup: `${envAdresses[currentEnv]}/api/group/follow`,
		unFollowGroup: `${envAdresses[currentEnv]}/api/group/unfollow`,
		getGroupRequests: `${envAdresses[currentEnv]}/api/group/requests`,
		getInstitutionDetailsById: `${envAdresses[currentEnv]}/api/institutionDetailsById`,
		updateInstitutionId: `${envAdresses[currentEnv]}/api/updateInstitutionId`,
		

		unfollow: `${envAdresses[currentEnv]}/api/unfollow`,
		followers: `${envAdresses[currentEnv]}/api/followers`,
		getGroupfollowers: `${envAdresses[currentEnv]}/api/group/followers`,

		following: `${envAdresses[currentEnv]}/api/following`,
		getFollowingGroupIds: `${envAdresses[currentEnv]}/api/following/groups/ids`,
		getFollowingGroups: `${envAdresses[currentEnv]}/api/following/groups`,
		getGroupRequestsList: `${envAdresses[currentEnv]}/api/group/requests`,
		deleteRequest: `${envAdresses[currentEnv]}/api/group/request/decline`,
		removeFromGroup: `${envAdresses[currentEnv]}/api/group/remove/follower`,
		acceptAllRequest: `${envAdresses[currentEnv]}/api/group/request/accept`,

		//Groups
		createGroup: `${envAdresses[currentEnv]}/api/group`,
		getGroups: `${envAdresses[currentEnv]}/api/groups`,
		deleteGroup: `${envAdresses[currentEnv]}/api/group`,
		getGroupExcelData: `${envAdresses[currentEnv]}/api/group/excel`,

		//Tasks
		createTask: `${envAdresses[currentEnv]}/api/task`,
		getTasksList: `${envAdresses[currentEnv]}/api/task/list`,
		deleteTask: `${envAdresses[currentEnv]}/api/task`,
		getTaskVideos: `${envAdresses[currentEnv]}/api/task/videos`,
		duplicateSubmissionCheck: `${envAdresses[currentEnv]}/api/task/duplicateSubmissionCheck`,

		//Feed
		getFeed: `${envAdresses[currentEnv]}/api/video/feed`,
		getSavedPret: `${envAdresses[currentEnv]}/api/videos/saved`,
		getSuggestions: `${envAdresses[currentEnv]}/api/user/suggestions`,

		// push notifications token
		saveNotificationToken: `${envAdresses[currentEnv]}/api/notifications/token`,

		//Explore Video section
		exploreVideos: `${envAdresses[currentEnv]}/api/video/explore`,
		trendingVideos: `${envAdresses[currentEnv]}/api/video/trending`,

		//watch section
		viewPret: `${envAdresses[currentEnv]}/api/video/view`,
		blankViewPret: `${envAdresses[currentEnv]}/api/video/blankview`,

		gradePret: `${envAdresses[currentEnv]}/api/video/grade`,

		savePret: `${envAdresses[currentEnv]}/api/video/save`,
		unSavePret: `${envAdresses[currentEnv]}/api/video/unsave`,

		getAvgGrades: `${envAdresses[currentEnv]}/api/video/grade/average`,

		likePret: `${envAdresses[currentEnv]}/api/video/like`,
		unLikePret: `${envAdresses[currentEnv]}/api/video/unlike`,
		commentPret: `${envAdresses[currentEnv]}/api/video/comment`,
		deleteComment: `${envAdresses[currentEnv]}/api/video/comment`,
		flagPret: `${envAdresses[currentEnv]}/api/video/unfit`,

		//recommendations
		recommendations         : `${envAdresses[currentEnv]}/api/video/recommendations`,

		//Custom Rubrics Sheet
		createRubricSheet         : `${envAdresses[currentEnv]}/api/rubricSheet/create`,
		deleteRubricSheet         : `${envAdresses[currentEnv]}/api/rubricSheet/delete`,
		getRubricSheetList        : `${envAdresses[currentEnv]}/api/rubricSheets/Parameters`,

		// credits
		addCredits: `${envAdresses[currentEnv]}/api/addCredits`,

		// get latest and treding videos
		getTrendingLatestFeed: `${envAdresses[currentEnv]}/api/videos/trending/latest/feed`,


		// Payment
		getRazorpayOrder: `${envAdresses[currentEnv]}/api/payments/razorpay/order`,
		razorPayPaymentSuccess : `${envAdresses[currentEnv]}/api/payments/razorpay/success`,
		getUserSubscription : `${envAdresses[currentEnv]}/api/user/subscription`,
		getPaymentHistory : `${envAdresses[currentEnv]}/api/user/payments`,
		// get categories
		getCategories        : `${envAdresses[currentEnv]}/api/categories`,

		getInstitutions: `${envAdresses[currentEnv]}/api/institutions`,

		getAnlyticsURL: `${envAdresses[currentEnv]}/api/anlyticsURL`,
  	},
};

export default apis;
<template>
<div>
  <v-snackbar
        top
        multi-line
        v-model="userData.successAlert"
        color="success"
        :timeout="10000">

        <v-layout align-center pr-1>
          <v-icon class="pr-2" dark large>check_circle</v-icon>
          <v-layout column>
            <div>{{ userData.successMsg }}</div>
          </v-layout>
        </v-layout>

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="closeAlert">
            <v-icon>clear</v-icon>
          </v-btn>
        </template>
  </v-snackbar>
</div>
</template>

<script>
//vuex dependancy
import { mapGetters } from "vuex";

export default {
  name:"SuccessAlert",
  computed: {
    ...mapGetters({
      userData: "User/userData"
    })
  },
  methods: {
    closeAlert() {
      this.$store.commit("User/hideSuccessAlert");
    }
  }
};
</script>
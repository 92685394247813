var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(`margin-top: -3%;margin-bottom: 3%;padding-left: 5%;padding-right: 5%;padding-bottom: 5%;background-color: ${_vm.mergedConfig.core.colors.primary};`)},[_c('v-card-title',{staticClass:"text-h3"},[_c('p',{staticStyle:{"font-family":"'Poppins', sans-serif","font-weight":"700","font-size":"52px","line-height":"125%","margin":"0 auto","margin-bottom":"3%"}},[_c('span',{style:(`color:${_vm.mergedConfig.core.colors.primary};-webkit-text-stroke: 2px ${_vm.mergedConfig.core.colors.secondary}`)},[_vm._v(" "+_vm._s(_vm.mergedConfig.info.title1)+" ")]),_c('span',{style:(`color:${_vm.mergedConfig.core.colors.secondary};-webkit-text-stroke: 1px ${_vm.mergedConfig.core.colors.primary}`)},[_vm._v(" "+_vm._s(_vm.mergedConfig.info.title2)+" ")])])]),(_vm.mergedConfig.info.subTitle)?_c('v-card-subtitle',[_c('p',{style:(`color:${_vm.mergedConfig.core.colors.secondary};
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    margin: 0px;
                    margin-bottom: 3%;`)},[_vm._v(" "+_vm._s(_vm.mergedConfig.info.subTitle)+" ")])]):_vm._e(),_c('v-row',{style:(`overflow-y: auto;max-height: ${_vm.mergedConfig.core.galleryMaxHeight};`)},_vm._l((_vm.mergedConfig.imagesList),function(imageItem){return _c('v-col',{key:imageItem.url,staticClass:"d-flex child-flex",attrs:{"cols":"12","md":12 / _vm.mergedConfig.core.tabletImages,"lg":12 / _vm.mergedConfig.core.laptopImages},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[(_vm.mergedConfig.core.onlyImages)?_c('v-img',{class:`grey lighten-2 ${imageItem.isCircle ? _vm.rounded-_vm.circle :  '' }`,attrs:{"eager":"","src":imageItem.url,"aspect-ratio":"1"}}):_vm._e(),(!_vm.mergedConfig.core.onlyImages)?_c('v-card',{style:(`background-color: ${_vm.mergedConfig.core.colors.primary}`),attrs:{"elevation":"0"}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-start align-center"},[_c('v-avatar',{staticClass:"ma-3",attrs:{"tile":imageItem.isTileShape,"size":"125"}},[_c('v-img',{attrs:{"eager":"","src":imageItem.url}})],1),_c('div',[_c('v-card-title',{style:(`
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 700;
                                    font-size: 34px;
                                    color:${_vm.mergedConfig.core.colors.secondary};
                                    margin-bottom: 5%;
                                    `)},[_vm._v(" "+_vm._s(imageItem.title)+" ")]),_c('v-card-subtitle',{style:(`
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16px;
                                    color:${_vm.mergedConfig.core.colors.secondary};
                                `)},[_vm._v(" "+_vm._s(imageItem.subTitle)+" ")])],1)],1)]):_vm._e()],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
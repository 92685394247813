<template>
  <div class="parent" :style="`background-color: ${mergedConfig.core.colors.primary};padding-bottom: 7%;margin-top: -3%;`">
    <v-container>
      <v-row  dense class="pa-0 ma-0" justify-space-around>

          <v-col
          v-for="cardItem in mergedConfig.content"
            cols="12"
            :md="cardItem.mdCols"
            :style="`
              background-color: ${cardItem.theme || mergedConfig.core.colors.primary};
              ${cardItem.borderColor ? 'border: 5px solid ' + cardItem.borderColor : ''};
              ${cardItem.borderColor ? 'border-radius: 8px' : ''} 
            `"
          >
            <v-card flat :style="`background-color: ${cardItem.theme || mergedConfig.core.colors.primary}`">
              
                <h3 v-if="cardItem.topTitleText" :style="`text-align:center;color: ${cardItem.topTitleColor || mergedConfig.core.colors.secondary};`"><br />{{cardItem.topTitleText}}</h3>
                <h3 v-if="cardItem.topTitleText2" :style="`text-align:center;color: ${cardItem.topTitleColor2 || mergedConfig.core.colors.third};`">{{cardItem.topTitleText2}}<br /> <br /></h3>
              
              <v-img eager v-if="cardItem.url" style="border-radius: 10px;" class="hidden-md-and-up white--text align-end allimages" :src="cardItem.url"></v-img>
              
              <v-img eager v-if="cardItem.url && (!cardItem.imagePosition || cardItem.imagePosition === 'top')" style="border-radius: 10px;" aspect-ratio="1" class="grey lighten-2 white--text align-end allimages" :src="cardItem.url"></v-img>
                
                <br v-if="cardItem.url && (!cardItem.imagePosition || cardItem.imagePosition === 'top')" />

                <div v-if="cardItem.imagePosition || bottomTitleText || subTitleText" class="d-flex flex-no-wrap justify-space-between">
                  <v-avatar v-if="cardItem.url && cardItem.imagePosition && cardItem.imagePosition === 'left'" class="ma-3 hidden-sm-and-down" :size="cardItem.avatorSize || '125'" :tile="!cardItem.isAvatorCircle">
                  <v-img eager style="border-radius: 10px;" :src="cardItem.url"></v-img>
                </v-avatar>

                  <div>
                    

                    <h3 :style="`text-align:center;color: ${cardItem.bottomTitleColor || mergedConfig.core.colors.secondary};`">{{cardItem.bottomTitleText}}</h3>
                    <h3 :style="`text-align:center;color: ${cardItem.bottomTitleColor2 || mergedConfig.core.colors.third};`">{{cardItem.bottomTitleText2}}<br /></h3>
                    <v-card-subtitle :style="`color: ${cardItem.subTitleColor || mergedConfig.core.colors.secondary};`">
                      {{cardItem.subTitleText}}
                    </v-card-subtitle>                            
                  </div>

                
                <v-avatar v-if="cardItem.url && cardItem.imagePosition && cardItem.imagePosition === 'right'" class="ma-3 hidden-sm-and-down" :size="cardItem.avatorSize || '125'" :tile="!cardItem.isAvatorCircle">
                  <v-img eager style="border-radius: 10px;" :src="cardItem.url"></v-img>
                </v-avatar>

                </div>

                <br v-if="cardItem.url && cardItem.imagePosition && cardItem.imagePosition === 'bottom'"  />

                <v-img eager v-if="cardItem.url && cardItem.imagePosition && cardItem.imagePosition === 'bottom'" style="border-radius: 10px;" aspect-ratio="1" class="grey lighten-2 white--text align-end allimages" :src="cardItem.url"></v-img>
            </v-card>
          </v-col>
        
      </v-row>      
    </v-container>    
  </div>
  </template>
  
  <style scoped>
  .parent{
  /* background: #2196F3; */
  background: white;
  margin-top: -10px;
  }
  .allimages{
      height: 300px;
  }
  h2 {
      margin-bottom   : 3%;
  }
  
  </style>
  

  <script>
  import { merge } from 'lodash';

  export default {
    name : "Multimedia",
    props: { config: { type: Object, default: () => ({}) } },
    data() { 
        return {
           defaultConfig: {
              core: {
                colors: {
                  primary: "white",
                  secondary: "#2196f3",
                  third: "black"
                },
              },
              content: [
                // {
                //   // Below is only on laptop
                //   // for image at center - 6
                //   // for image at right to corner - 12

                //   theme: "",
                //   borderColor: "",
                //   mdCols: "12",
                //   imagePosition: "right",
                //   url: "https://cdn.dribbble.com/users/1046956/screenshots/5931773/giftboxanimation6x8.gif",
                //   topTitleText: `Our gift to you - "Absolutely Free !`,
                //   topTitleColor: "green",
                  
                //   topTitleText2: "Hope you like it",
                //   topTitleColor2: "yellow",
                  
                //   bottomTitleText: `Our gift to you - "Absolutely Free !`,
                //   bottomTitleColor: "green",

                //   bottomTitleText2: "Hope you like it",
                //   bottomTitleColor2: "yellow",
                  
                //   subTitleText: "A lot of people are technically very sound in their respective field but because they lack soft skills it affects adversely in thier career! Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates animi facilis magni ducimus quas fugit nisi incidunt dolor iste, ab rerum nesciunt at fugiat, laudantium numquam ratione vel corrupti vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates animi facilis magni ducimus quas fugit nisi incidunt dolor iste, ab rerum nesciunt at fugiat, laudantium numquam ratione vel corrupti vitae.",
                //   subTitleColor: "black",
                //   avatorSize: "125",
                //   isAvatorCircle: true,
                // },
              ],
            }
        }
    },
    computed: {
        mergedConfig() {
            return merge({}, this.defaultConfig, this.config);
        },
    },
  };
</script>
<template>
    <div
      class="home"
      :style="`background-color: ${mergedConfig.core.colors.primary}; margin-top: -3%; padding-bottom: -50%`"
    >
      <v-container>
        <v-row dense class="pa-0 ma-0">
          <v-col 
            :cols="mergedConfig.core.info.cols"
            :md="mergedConfig.core.info.md"
            :order-md="mergedConfig.core.info.orderMd"
            :order="mergedConfig.core.info.order"
           class="timelineTop" style="padding: 22% 1% 3%;margin-bottom: 30%;">
            <v-card
              max-width="500"
              flat
              :style="`background-color:${mergedConfig.core.colors.primary};margin-top: 8%`"
            >
              <v-card-title class="text-h3">
                <p
                  style="
                    font-family: 'Poppins', sans-serif;
                    font-weight: 700;
                    font-size: 52px;
                    line-height: 125%;
                  "
                >
                  <span
                    :style="`color:${mergedConfig.core.colors.primary};-webkit-text-stroke: 2px ${mergedConfig.core.colors.secondary}`"
                    >
                    {{mergedConfig.info.title1}}
                  </span>
                  <br />
                  <span 
                    :style="`color:${mergedConfig.core.colors.secondary};-webkit-text-stroke: 1px ${mergedConfig.core.colors.primary}`"
                  >
                  {{mergedConfig.info.title2}}
                  </span>
                </p>
              </v-card-title>
  
              <v-card-subtitle>
                <p
                  :style="`color:${mergedConfig.core.colors.third};
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    margin: 0px;`"
                >
                {{mergedConfig.info.subTitle}}
                </p>
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col :cols="mergedConfig.core.chart.cols"
            :md="mergedConfig.core.chart.md"
            :order-md="mergedConfig.core.chart.orderMd"
            :order="mergedConfig.core.chart.order">
            <v-timeline :dense="$vuetify.breakpoint.smAndDown" align-top style="margin-top: -2.7%;">
              <v-timeline-item v-for="(obj, i) in mergedConfig.processDetails" :key="i" large>
                <template v-slot:icon>
                  <v-avatar>
                    <img :src="obj.img" />
                  </v-avatar>
                </template>
                <template v-slot:opposite>
                  <h2 :style="`color:${mergedConfig.core.colors.secondary};`">{{ obj.entity }}</h2>
                </template>
                <v-card class="elevation-2" max-width="500">
                  <v-card-title
                    class="text-h5"
                    :style="`background-color:${mergedConfig.core.colors.secondary};color:${mergedConfig.core.colors.primary};`"
                    >
                    {{ obj.title }}
                  </v-card-title>
                  <v-card-text
                    :style="`background-color:${mergedConfig.core.colors.primary};color:${mergedConfig.core.colors.secondary};padding: 5%;`"
                  >{{ obj.details }}</v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>

  <script>
  import { merge } from 'lodash';

  export default {
    name : "Multimedia",
    props: { config: { type: Object, default: () => ({}) } },
    data() { 
        return {
           defaultConfig: {
            // order is for -> mobile phones
            // order MD is for -> medim device
                core: {
                    colors: {
                      primary: "white",
                      secondary: "#2196f3",
                      third: "#2196f3",
                    },
                    info: {
                        cols: "12",
                        md: "5",
                        orderMd: "1",
                        order: "1",
                    },
                    chart: {
                        cols: "12",
                        md: "7",
                        orderMd: "2",
                        order: "2",
                    },
                },
                info: {
                    title1: "What we do is a",
                    title2: "3 Step process",
                    subTitle: "With our simple 3-step process, Preskilet empowers colleges and students alike by seamlessly transitioning from traditional physical presentations to a digital format.",
                },
                processDetails: [
                    // {
                    //     entity: "College",
                    //     img: "https://img.freepik.com/premium-vector/isolated-school-building-landscape-with-grass-clouds-urban-exterior_912383-537.jpg?w=360",
                    //     title: "College create activity",
                    //     details: "Colleges create presentation activities for students, such as academic presentations, project presentations, video resumes, and internship presentations, transforming the traditional physical presentation process into a digital one.",
                    // },
                    // {
                    //     entity: "Student",
                    //     img: "https://static.vecteezy.com/system/resources/thumbnails/000/350/111/small/Education__28193_29.jpg",
                    //     title: "Student upload video",
                    //     details: "Students record their presentation videos and upload them to the platform. They receive constructive feedback from both teachers and peers, helping them pinpoint areas for improvement. With this valuable input, they refine their skills in subsequent videos.",
                    // },
                    // {
                    //     entity: "Profile",
                    //     img: "https://img.freepik.com/premium-vector/video-blogger-internet-blogger-recording-media-content-watching-video-internet-vector-illustration_123447-3630.jpg?w=2000",
                    //     title: "Studet get Profile",
                    //     details: "When students are ready for job placements, all the presentation videos they've uploaded from their first academic year to their final academic year along with a video resume are transformed into a comprehensive video profile that they can share with recruiters",
                    // },
                ],
            }
        }
    },
    computed: {
        mergedConfig() {
            return merge({}, this.defaultConfig, this.config);
        },
    },
  };
</script>
  
<style scoped>
.theme--light.v-timeline:before {
    /* background: #2196f3; */
    height: 100%;
    width: 0.5%;
}
</style>
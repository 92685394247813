<template>
    <div class="home"
        :style="`background-color: ${mergedConfig.core.colors.primary}; margin-top: 1%`"
        >
      <v-container>
        <v-row dense class="pa-0 ma-0">
          <v-col
            :cols="mergedConfig.core.semiBlock1.cols"
            :md="mergedConfig.core.semiBlock1.md"
            :order-md="mergedConfig.core.semiBlock1.orderMd"
            :order="mergedConfig.core.semiBlock1.order"
            >
            <v-card-title class="text-h3 justify-center">
              <p
                style="
                  font-family: 'Poppins', sans-serif;
                  font-weight: 700;
                  font-size: 52px;
                  line-height: 125%;
                "
              >
                <span
                    :style="`color: ${mergedConfig.core.colors.primary}; -webkit-text-stroke: 2px ${mergedConfig.core.colors.secondary}`"
                    >
                    {{mergedConfig.info.title1}}
                </span>
                <span
                    :style="`color: ${mergedConfig.core.colors.secondary}; -webkit-text-stroke: 1px ${mergedConfig.core.colors.primary}`"
                    >
                    {{mergedConfig.info.title2}}
                </span>
              </p>
            </v-card-title>
          <v-card-title class="justify-center">
            <v-card-subtitle 
            :style="`
                   font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: ${mergedConfig.core.colors.secondary};
                    margin: 0px;
                    border: 2px dotted ${mergedConfig.core.colors.secondary};
                    border-radius: 15px;
            `">
                {{mergedConfig.info.subTitle}}
              </v-card-subtitle>
  
          </v-card-title>
  
  
          </v-col>
  
          <v-col :cols="mergedConfig.core.semiBlock2.cols"
            :md="mergedConfig.core.semiBlock2.md"
            :order-md="mergedConfig.core.semiBlock2.orderMd"
            :order="mergedConfig.core.semiBlock2.order">
            <div style="margin-top: -5%">
              <v-img
                eager
                style="
                  margin-left: auto;
                  margin-right: auto;
                  height: 100%;
                  width: 100%;
                "
                :src="mergedConfig.imgUrl"
              ></v-img>
            </div>
          </v-col>
          <v-col :cols="mergedConfig.core.semiBlock3.cols"
            :md="mergedConfig.core.semiBlock3.md"
            :order-md="mergedConfig.core.semiBlock3.orderMd"
            :order="mergedConfig.core.semiBlock3.order">
            <v-card
              max-width="500"
              flat
              :style="`background-color: ${mergedConfig.core.colors.primary}; margin-top: 5%`"
              
            >
              <v-card-subtitle>
                <p
                  :style="`
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: ${mergedConfig.core.colors.secondary};
                    margin: 0px;
                  `"
                >
                  {{mergedConfig.description}}
                </p>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  

  <script>
  import { merge } from 'lodash';

  export default {
    name : "Multimedia",
    props: { config: { type: Object, default: () => ({}) } },
    data() { 
        return {
           defaultConfig: {
            // order is for -> mobile phones
            // order MD is for -> medim device
                core: {
                    colors: {
                      primary: "white",
                      secondary: "#2196f3",
                      third: "#2196f3",
                    },
                    semiBlock1: {
                        cols: "12",
                        md: "12",
                        orderMd: "1",
                        order: "1",
                    },
                    semiBlock2: {
                        cols: "12",
                        md: "6",
                        orderMd: "2",
                        order: "2",
                    },
                    semiBlock3: {
                        cols: "12",
                        md: "6",
                        orderMd: "3",
                        order: "3",
                    },
                },
                info: {
                    title1: "Why college should have",
                    title2: "preskilet?",
                    subTitle: "Elevate Student Presentation Skills and Attract Higher-Paying Opportunities for Students with Preskilet",
                },
                imgUrl: "https://firebasestorage.googleapis.com/v0/b/githubsearch-acbac.appspot.com/o/Placement%20package%20anlytics.png?alt=media&token=5e970502-33f3-4e63-91ae-94aad971eb0b&_gl=1*moqq5y*_ga*MTY4NDYwMzMzNi4xNjkxMjMwNjM4*_ga_CW55HF8NVT*MTY5NjQyNjExNy44LjEuMTY5NjQyNjEyNC41My4wLjA.",
                description: "Preskilet has proven to be a game-changer in student success and college excellence. In 2022, students equipped with Preskilet profiles achieved placements at 10 LPA, and within a year, in 2023, they secured remarkable offers at 44 LPA. This substantial growth is a testament to how students improve their confidence and presentation skills through our platform, resulting in a higher placement percentage. Moreover, they gain a valuable video profile that can be utilized anytime they desire. Additionally, colleges benefit by showcasing their impressive student video repository to the world, further enhancing their reputation and attracting top talent.",
            }
        }
    },
    computed: {
        mergedConfig() {
            return merge({}, this.defaultConfig, this.config);
        },
    },
  };
</script>
  
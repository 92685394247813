<template>
    <div
      class="home"
      :style="`background-color:${mergedConfig.core.colors.primary};margin-top: -3%; padding-bottom: -50%`"
    >
      <v-container>
        <v-row dense class="pa-0 ma-0">
          <v-col
            :cols="mergedConfig.core.info.cols"
            :md="mergedConfig.core.info.md"
            :order-md="mergedConfig.core.info.orderMd"
            :order="mergedConfig.core.info.order"
            >
             
            <v-card
              max-width="500"
              flat
              :style="`background-color:${mergedConfig.core.colors.primary};`"
              class="responsive-margin-block1"
            >
              <v-card-title class="text-h3">
                <p
                  style="
                    font-family: 'Poppins', sans-serif;
                    font-weight: 700;
                    font-size: 52px;
                    line-height: 125%;
                  "
                >
                  <span
                    :style="`color:${mergedConfig.core.colors.primary};-webkit-text-stroke: 2px ${mergedConfig.core.colors.secondary}`"
                  >
                    {{ mergedConfig.info.title1 }}
                  </span>
                  <span
                    :style="`color:${mergedConfig.core.colors.secondary};-webkit-text-stroke: 1px ${mergedConfig.core.colors.primary}`"
                  >
                    {{ mergedConfig.info.title2 }}
                  </span>
                </p>
              </v-card-title>

              <v-card-subtitle v-if="mergedConfig.info.subTitle">
                <p
                  :style="`color:${mergedConfig.core.colors.third};
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    margin: 0px;`"
                >
                  {{ mergedConfig.info.subTitle }}
                </p>
              </v-card-subtitle>

              <v-card-actions v-if="mergedConfig.info.ctaButton.isShow">

                <router-link style="text-decoration: none;" :to="mergedConfig.info.ctaButton.link">
                  <v-btn
                    outlined
                    :rounded="mergedConfig.info.ctaButton.isRounded"
                    :style="`color:${mergedConfig.core.colors.secondary};`"
                    > {{ mergedConfig.info.ctaButton.title }} </v-btn>
                </router-link>
            </v-card-actions>
              

  
              <span v-for="videoItem in mergedConfig.videos">
                <v-card-actions
                :style="`color:${mergedConfig.core.colors.third};
                    font-size: 18px;
                  font-weight: 400;
                  line-height: 40px;
                  margin: 0px;`"
              >
              {{ videoItem.title }}
              </v-card-actions>
  
              <v-card-actions style="margin-top: 5%;">
                <iframe
                  :style="`border: 5px double ${mergedConfig.core.colors.secondary};border-radius: 10px;`"
                  width="560" height="315" :src="videoItem.URL" title="YouTube video player" frameborder="0" allow="" allowfullscreen></iframe>
              </v-card-actions>
  
              </span>
              </v-card>
          </v-col>
          <v-col
            :cols="mergedConfig.core.media.cols"
            :md="mergedConfig.core.media.md"
            :order-md="mergedConfig.core.media.orderMd"
            :order="mergedConfig.core.media.order"
          >
            <div class="responsive-margin-block2">
              <span v-for="imageItem in mergedConfig.images">
  
              <v-img eager
                style="
                  margin-left: auto;
                  margin-right: auto;
                  margin-bottom: 15%;
                  height: 100%;
                  width: 100%;
                "
                :src="imageItem.URL"
                
              ></v-img>
            </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  
  <script>
  import { merge } from 'lodash';

  export default {
    name : "Multimedia",
    props: { config: { type: Object, default: () => ({}) } },
    data() { 
        return {
           defaultConfig: {
            // order is for -> mobile phones
            // order MD is for -> medim device
            // timeline, why choose, recruiters list (only logos), client list or placements until now  (logos of avatars with some info)
                core: {
                    colors: {
                      primary: "#2196f3",
                      secondary: "white",
                      third: "whitesmoke",
                    },
                    info: {
                        cols: "12",
                        md: "6",
                        orderMd: "1",
                        order: "2",
                    },
                    media: {
                        cols: "12",
                        md: "6",
                        orderMd: "2",
                        order: "1",
                    },                
                },
                info: {
                    title1: "Whats there in Preskilet for",
                    title2: "Recruiters?",
                    subTitle: "Recruiters benefit from our platform by accessing a comprehensive video profile, including video resumes and technical presentation videos, of students from all our client colleges in one centralized location, tailored to their specific needs. This streamlined process significantly reduces the time and resources required to interact with individual colleges, making recruitment more efficient and effective.",
                    ctaButton: {
                        title: "Sign in / Sign Up",
                        isRounded: false,
                        isShow: true,
                        link: "/signin",
                    }
                },
                videos: [
                    // {
                    //     title: "Watch what Recruiters witnessed on Preskilet",
                    //     URL: "https://www.youtube.com/embed/qe41pmUrPdI?si=AR90fZzN7KbXjxop&amp;start=1"
                    // },
                ],
                images: [
                    // {
                    //     URL: "https://firebasestorage.googleapis.com/v0/b/githubsearch-acbac.appspot.com/o/Student%20presenting%20for%20recruiter.png?alt=media&token=2a688e4a-9438-4e0c-8129-53c526f7c95b&_gl=1*zftbng*_ga*MTY4NDYwMzMzNi4xNjkxMjMwNjM4*_ga_CW55HF8NVT*MTY5NjQyMDcwMi43LjEuMTY5NjQyMjc5MC41NS4wLjA."
                    // },
                ],
            }
        }
    },
    computed: {
        mergedConfig() {
            return merge({}, this.defaultConfig, this.config);
        },
    },
  };
</script>
  
<style scoped>
.responsive-margin-block1 {
  margin-top: 20%;
}

.responsive-margin-block2 {
  margin-top: -11.5%;
  margin-bottom: 15%;
}

@media (max-width: 600px) {
  .responsive-margin-block1 {
    margin-top: 3%;
  }
  .responsive-margin-block2 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
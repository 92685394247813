<template>
<div>
    
    <v-snackbar
        bottom
        multi-line
        v-model="snackbarOnline"
        color="info"
        :timeout="timeoutOnline"
    >
        
        <v-layout align-center pr-1>
            
            <v-icon class="pr-2" dark large>info</v-icon>
            
            <v-layout column> <div>{{ onlinetext }}</div> </v-layout>
            
        </v-layout>
        
        <template v-slot:action="{ attrs }">
            
            <v-btn color="white" text v-bind="attrs" @click="snackbarOnline = false">
                <v-icon>clear</v-icon>
            </v-btn>
        
        </template>
    
    </v-snackbar>
    
    <v-snackbar
        top
        multi-line
        v-model="snackbarOffline"
        color="red"
        :timeout="timeoutOffline"
    >
        <v-layout align-center pr-1>
        
            <v-icon class="pr-2" dark large>error</v-icon>
        
            <v-layout column> <div>{{ offlinetext }}</div> </v-layout>
        
        </v-layout>
        
        <template v-slot:action="{ attrs }">
            
            <v-btn color="white" text v-bind="attrs" @click="snackbarOffline = false">
                <v-icon>clear</v-icon>
            </v-btn>
        
        </template>
    
    </v-snackbar>

</div>
</template>

<script>

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

export default {
    
    name: "NetConnectivity",
    
    data() {

        return {
            snackbarOnline      :   true,
            snackbarOffline     :   false,
            onlinetext          :   'You are online',
            offlinetext         :   'You are offline',
            timeoutOffline      :   10000000,
            timeoutOnline       :   2000
        }

    },
    
    created() {
        
        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("NetConnectivity Component");
        
        var that = this;
        
        window.addEventListener("online", function() {
            
            that.snackbarOnline     = true;
            that.snackbarOffline    = false;
        
        });
        
        window.addEventListener("offline", function() {
            
            that.snackbarOnline     = false;
            that.snackbarOffline    = true;

        });
    
    }
    
};

</script>
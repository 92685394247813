<template>
    <div>
   
        <CentralInfoBlock :config="centralInfoBlock"  />
        <GalleryBlock :config="clientsConfig" />
        <GalleryBlock :config="placementsConfig" />
        <GalleryBlock :config="recruitersConfig" />
        

    </div>
</template>
    
<script>

import MultimediaBlock from "@/staticPagesModule/blocks/MultimediaBlock";
import GalleryBlock from "@/staticPagesModule/blocks/GalleryBlock";
import CentralInfoBlock from "@/staticPagesModule/blocks/CentralInfoBlock";

export default {
    name: "StaticPagesTemplate",
    components: { MultimediaBlock, GalleryBlock, CentralInfoBlock },
    data() { 
        return {


            centralInfoBlock: {
                core: {
                    colors: {
                      primary: "white",
                      secondary: "#2196f3",
                      third: "#2196f3",
                    },
                    semiBlock1: {
                        cols: "12",
                        md: "12",
                        orderMd: "1",
                        order: "1",
                    },
                    semiBlock2: {
                        cols: "12",
                        md: "6",
                        orderMd: "2",
                        order: "2",
                    },
                    semiBlock3: {
                        cols: "12",
                        md: "6",
                        orderMd: "3",
                        order: "3",
                    },
                },
                info: {
                    title1: "",
                    title2: "Recognized By Government Of India",
                    subTitle: "Preskilet Recognized by Government of India under Startup India Initiative and DIPP",
                },
                imgUrl: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2FinfopageAssets%2FAbout%20Us%20(1).png?alt=media&token=e9e415fa-8d7c-4b22-b937-65446d1b6f48",
                description: "Preskilet is proudly recognized by the Government of India under the Startup India initiative and is registered with the Department for Promotion of Industry and Internal Trade (DIPP). This recognition signifies the platform's innovative approach to enhancing student employability through video profiles, live resumes, and industry-relevant activities. Being part of this prestigious initiative further strengthens Preskilet's commitment to empowering students across India, bridging the gap between education and employment, and contributing to the nation's growth in the startup ecosystem.",
            },

            clientsConfig: {
                info: {
                    title1: "",
                    title2: "Our Clients",
                    subTitle: "",
                },
                core: {
                    galleryMaxHeight: "100vh",
                    onlyImages: true,
                    tabletImages: 3,
                    laptopImages: 4,

                    media: {
                        blocksPerLine: "12",
                    },
                    colors: {
                      primary: "white",
                      secondary: "#2196f3",
                    },
                },
                imagesList: [
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/Client%20Logo%2FMIT%20ADT.jpeg?alt=media&token=5bf04cd3-98f8-4a5d-905a-99fe90294790",isTileShape: true,
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/Client%20Logo%2FMIT%20AOE.png?alt=media&token=458e6b5f-3459-44e9-af8c-98122f7088a4",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/Client%20Logo%2FmitAcscLogo.jpg?alt=media&token=62129d4f-d4c5-4c67-8d0d-3929455a6c45",
                    },

                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/Client%20Logo%2Fdpulogo.jpg?alt=media&token=8b375ab7-183d-4a54-8983-fa5ba79bd983",
                    },
                    
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/Client%20Logo%2FJSPM-logo.jpeg?alt=media&token=5cbbd238-cff8-43c8-b0a2-dec43457d0cf",
                    },


                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/Client%20Logo%2Fi2itlogo.jpeg?alt=media&token=3b3a746c-7f41-47ea-8b64-04006ca9f9de",
                    },

                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/Client%20Logo%2FSRCOE.png?alt=media&token=a43ca2fe-929d-4294-b60c-7dadfda2df35",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/Client%20Logo%2FJCOE.png?alt=media&token=867264be-a060-4ad2-b08b-4a2520a72d85",
                    }
                ],
            },

            placementsConfig: {
                info: {
                    title1: "",
                    title2: "Placements",
                    subTitle: "",
                },
                core: {
                    galleryMaxHeight: "100vh",
                    onlyImages: false,
                    tabletImages: 3,
                    laptopImages: 4,
                    colors: {
                      primary: "white",
                      secondary: "black",
                    },
                    media: {
                        blocksPerLine: "12",
                    },
                    
                },
                imagesList: [
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fpooja.png?alt=media&token=60d7fc42-e262-4ff4-a362-65471a8961ee",
                        title: "44 LPA",
                        subTitle: "Pooja Kumari",
                        // isTileShape: true,
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fprasad.jpg?alt=media&token=18f3c5fc-a5ed-4846-8d33-2909813df4cc",
                        title: "44 LPA",
                        subTitle: "Prasad Borse",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fneahaal.jpg?alt=media&token=5750bdb1-4aea-469e-a788-caa0632948ab",
                        title: "44 LPA",
                        subTitle: "Nehaal Pandey",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Faditya.jpg?alt=media&token=1df809ea-fa5f-4f3e-be54-9ee48b8c4c92",
                        title: "22 LPA",
                        subTitle: "aditya barve",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Frahul.jpg?alt=media&token=a225a34d-eaa6-44e8-9650-9b15140e2f5a",
                        title: "10 LPA",
                        subTitle: "Rahul tah",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Frushikesh.jpg?alt=media&token=284a076d-be6d-4bf0-8f9d-a39fa3753122",
                        title: "10 LPA",
                        subTitle: "Rushikesh Bagal",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fsoham.jpg?alt=media&token=c1ec71e2-1031-4c40-ad50-600b35df42e8",
                        title: "10 LPA",
                        subTitle: "Soham Kumbhar",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fdivya.png?alt=media&token=e0dbf4ab-3034-4094-9152-ea6a8c537c36",
                        title: "10 LPA",
                        subTitle: "Divya Chaudhari",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2FLippi.png?alt=media&token=0e46ac42-250f-4557-8368-a5ddf99ae054",
                        title: "4 LPA",
                        subTitle: "Lipi Choudhary",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fkhushi.jpg?alt=media&token=440fd657-2ec2-4b6b-825e-a281e24940fb",
                        title: "4 LPA",
                        subTitle: "khushi Khan",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fvaishnav.png?alt=media&token=b82bee6f-713d-4e6d-a057-257bd81cf192",
                        title: "4 LPA",
                        subTitle: "Vaishnav Katare",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Fplacements%2Fmadhvi.jpg?alt=media&token=a26cb933-8eb2-4270-a56e-11c0e543e586",
                        title: "4 LPA",
                        subTitle: "Madhavi Mandave",
                    },
                    
                ],
            },

            recruitersConfig: {
                info: {
                    title1: "",
                    title2: "Our Recruiters",
                    subTitle: "",
                },
                core: {
                    galleryMaxHeight: "100vh",
                    onlyImages: true,
                    tabletImages: 3,
                    laptopImages: 4,
                    colors: {
                      primary: "white",
                      secondary: "black",
                    },
                    media: {
                        blocksPerLine: "12",
                    },
                },
                imagesList: [
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fdentsu.jpg?alt=media&token=654c5215-59d1-4833-8b57-bbd9cedba118",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fonline%20sales.jpg?alt=media&token=8a938af0-2153-47a8-af61-14a4c5e0cbb9",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fimages.jpg?alt=media&token=8bcfc210-ee07-4eb6-80a4-0879eb2770d3",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fradiant.jpg?alt=media&token=aff0adbd-1236-461c-8c08-cba7857dc410",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fabsolutereports.jpg?alt=media&token=f39353e5-a5c8-41d3-a7be-539091f9efbd",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fartiset.jpg?alt=media&token=746e5989-351f-46d3-b9b8-7fb62e79f60a",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fbpointer.jpg?alt=media&token=9da00465-a58b-42a0-ad70-75b3858709c6",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fcampuscomponent.jpg?alt=media&token=f217b7d8-588e-49fd-b9d2-217a37991360",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fdigtinctive.jpg?alt=media&token=e0d0ef25-97c2-43c0-ae1e-8021685a44b0",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fexecutive%2081.jpg?alt=media&token=9aee3386-a328-4fc5-a654-0f836f8526a4",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Ffortunebi.png?alt=media&token=0e35c746-9535-4cbf-b370-0fc3fde44f4d",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fgullakaari_logo.jpg?alt=media&token=dc127550-c096-4e6e-90af-7ee403366ccb",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fhan%20digital.webp?alt=media&token=44164df2-a4f6-4b15-8677-cb51afdfd5a7 ",
                    },
                    {
                        url: "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fturtlemint.png?alt=media&token=6d491424-7491-4012-a1e2-13cbeb7c3ada",
                    }, 
                    {
                        url:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2F1717146717595.jpg?alt=media&token=edcf4484-d725-4ce8-ad65-2c0736196aac"
                    },
                    {
                        url:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fsahal.jpg?alt=media&token=dcbeb2ac-eef9-42e3-8ac4-ae19cbb1d07e"
                    },
                    {
                        url:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fstorybot.jpg?alt=media&token=94d75c8c-d36b-4e85-9851-e95b25f57e73"
                    },
                    {
                        url:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Fxcelliuan.jpg?alt=media&token=cf10fd3e-f174-48ed-9355-ebc30945f8e1"
                    },
                    {
                        url:"https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Frecruiters%2Finternlabs.png?alt=media&token=6a183017-03e2-4fe5-bffd-5221902ae50a"
                    }
                ],
            }
        }
    },
};
</script>
    
<template>
    <div>
        
        <v-container>
            
            <v-layout row wrap justify-space-around>
    
                <v-flex xs11 md6>
    
                    <v-text-field
                        prepend-inner-icon="mdi-account"
                        type="search"
                        v-model="searchQuery"
                        label="Search Profiles by Names"
                        required
                        v-on:keyup.enter="pressEnter"
                        v-on:keyup="typingInProfileSearch"
                        solo
                        append-icon="mdi-magnify"
                        style="margin-left: 10px;margin-right: 10px;margin-top: 10px;"
                        
                    ></v-text-field>
                    <!-- hint="Institution & Filters are currently not available for profile searches" -->
    
                    <v-text-field
                        prepend-inner-icon="mdi-youtube"
                        style="margin-left: 10px;margin-right: 10px;"
                        v-model="searchQueryForTags"
                        type="search"
                        v-on:keyup.enter="pressEnterForTags"
                        v-on:keyup="typingInTags"
                        solo
                        label="Search Videos by tags, title, description"
                        append-icon="mdi-magnify"
                        persistent-hint
                        hint="For example you can search for video resume, operating system, linux, aws, america, Spectrum, 5G Technology, world bank, wireless, model making, data visualisation, cyber, chemistry, factorial, recursion, Jquery, Java, Advance Java, SQL, DBMS, Javascript, artificial intelligence, Capstone Project, BlockChain, presentation video, computer network, ai, finance, topology, software engineering, python, string, one way video interview, management, case study, mini project, major project, IOT project, Job search etc."
                    ></v-text-field>
                    <br />
    
                    <!-- <v-select
                        v-if="searchQueryForTags"
                        item-text="name"
                        item-value="_id"
    
                        v-model="selectedInstitution"
                        :items="institutionList"
                        label="Select Institution"
                        data-vv-name="select"
                        :rules="[(v) => !!v || 'Institution is required']"
                        required
                    ></v-select>
    
                    <v-select
                        v-if="searchQueryForTags"
                        item-text="name"
                        item-value="id"
    
                        v-model="selectedFilter"
                        :items="filterOptions"
                        label="Select filter"
                        data-vv-name="select"
                        return-object
                        required
                    ></v-select>
    
                    <div v-if="searchQueryForTags" class="text-right">
                        <v-btn
                            class="ma-2"
                            color="primary"
                            depressed
                        >
                            <v-icon dark left>mdi-checkbox-marked-circle</v-icon>Search with filters
                        </v-btn>
                    </div> -->
    
    
                    <!-- Profile search results -->
                    <v-card
                        v-for="item in searchResults"
                        :key="item._id"
                        flat
                        class="mx-auto "
                    >
                        
                        <router-link
                        style="text-decoration: none;color:black;"
                        :to="'/' + item._id"
                        >
    
                            <v-list two-line>
    
                                <v-list-item>
    
                                    <v-list-item-avatar size="50">
                                        <v-img :src="item.profile.profilePhoto"></v-img>
                                    </v-list-item-avatar>
    
                                    <v-list-item-content>
    
                                        <v-list-item-title
                                        v-html="item.profile.name"
                                        ></v-list-item-title>
    
                                        <v-list-item-subtitle
                                         v-if='false'>{{
                                            item.followers.length
                                        }}
                                        Followers</v-list-item-subtitle>
    
                                    </v-list-item-content>
    
                                </v-list-item>
    
                                <v-divider inset></v-divider>
    
                            </v-list>
    
                        </router-link>
    
                    </v-card>
                    
                    <!-- Videos search results -->
    
    <div v-if="searchResultsVideos.length != 0" class="ma-2 hidden-md-and-up">
               
                <div v-for="(item, index) in searchResultsVideos"
                :key="item._id">
    
                <v-card            
                :flat="cardFlatFlag"
                :outlined="cardOutlineFlag"
                class="mx-auto my-6"
            >
                
                <v-list-item two-line>
                    
                    <v-list-item-avatar><img :src="item.userId.profile.profilePhoto" /></v-list-item-avatar>
                    
                    <v-list-item-content>
                        
                        <router-link style="text-decoration: none;color:black;" :to="'/' + item.userId._id">
                            <v-list-item-title>{{ item.userId.profile.name }}</v-list-item-title>
                        </router-link>
                        
                        <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                    
                    </v-list-item-content>
    
                    <v-btn
                    v-if="item.type === 'latest'"
          color="primary"
          plain
          class="pa-2"
          style="text-transform: unset !important;"
        >
          #Latest{{ item.typeIndex }}
          <v-icon size="30" right>
            mdi-history
          </v-icon>
    
        </v-btn>
    
                    <v-btn
                    v-if="item.type === 'trending'"
          color="red"
          plain
          class="pa-2"
          style="text-transform: unset !important;"
        >
          #Trending{{ item.typeIndex }}
          <v-icon size="30" right>
            mdi-fire
          </v-icon>
    
        </v-btn>
    
    
    <!-- 
                    <v-btn color="primary" size="35" tile large icon router :to="'/watch?v=' + item._id">
                        <v-icon left> mdi-compass-outline</v-icon>
                    </v-btn> -->
    
                
                </v-list-item>
                
                <!-- <vue-plyr :options="options">
                    <video :data-poster="item.thumbnailUrl" :src="item.videoUrl" controls playsinline style="width: 100%;height:auto;" ></video>
                </vue-plyr> -->
    
        <router-link style="text-decoration: none;color:black;" :to="'/watch?v=' + item._id" >
                        
                            <v-img 
                                :src="item.thumbnailUrl"
                                class="white--text align-end videoRecommendationThubmnail"
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            >
                                <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title class="white--text">
                                    {{ item.title }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="white--text"
                                    >{{ item.viewsLength }} Views</v-list-item-subtitle
                                    >
                                </v-list-item-content>
                                </v-list-item>
                            </v-img>
                        
                        </router-link>
                <!-- <v-card-actions>
                    <v-list-item class="grow">
                        <v-row align="center" class="pa-0 ma-0">
                            
                            <v-icon 
                                @click="unLikePret(index)" 
                                style="cursor:pointer;" 
                                v-if="item.likes.includes(userId)" 
                                color="red" 
                                size="30"
                            >
                                mdi-heart
                            </v-icon>
                            
                            <v-icon
                                @click="likePret(index)"
                                v-if="!item.likes.includes(userId)"
                                style="cursor:pointer;"
                                color="whitesmoke"
                                size="30"
                            >
                                mdi-heart-outline
                            </v-icon>
    
    
                            <a 
                        style="text-decoration:none;"
                        class="pa-2"
                        target="_blank"
                    >
    
                            <v-icon 
                                style="cursor:pointer;" 
                                color="primary"
                                size="30"
                            >
                                mdi-fire
                            </v-icon>
    
    
                       #TrendingNumber{{ index + 1 }}
                    </a>
    
                            <v-spacer></v-spacer>  
                  
                            <v-icon 
                                @click="unsave(item._id)"
                                v-if="savedprets.includes(item._id)" 
                                style="cursor:pointer;" 
                                color="primary"
                                size="30"
                            >
                                mdi-bookmark
                            </v-icon>
    
    
                            <v-icon 
                                @click="save(item._id)"
                                v-if="!savedprets.includes(item._id)" 
                                style="cursor:pointer;" 
                                color="primary"
                                size="30"
                            >
                                mdi-bookmark-outline
                            </v-icon>
                         
                        </v-row>
    
                    </v-list-item>
    
                </v-card-actions> -->
            
            </v-card>
    
    <!-- ads -->
      <!-- <v-card   v-if="index % 4 == 0 && index != 0"
                :flat="cardFlatFlag"
                :outlined="cardOutlineFlag"
                class="mx-auto my-6"
            >
                
                <v-list-item two-line>
                    
                    <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                    
                    <v-list-item-content>
                        
                            <v-list-item-title>Google Ads</v-list-item-title>
                        
                        <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                    
                    </v-list-item-content>
                    
                    <v-btn color="primary" size="35" tile large icon router >
                        <v-icon left> mdi-compass-outline</v-icon>
                    </v-btn>
                
                </v-list-item>
                
    <div  class="ad-container">
                    <InFeedAdsense
                        style="display:block"
                        data-ad-format="fluid"
                        data-ad-layout-key="-6t+ed+2i-1n-4w"
                        data-ad-client="ca-pub-2789565594210548"
                        data-ad-slot="2873874849"
                    ></InFeedAdsense>
                </div>
       
                <v-card-actions>
                    <v-list-item class="grow">
                        <v-row align="center" class="pa-0 ma-0">
                        
                            <v-icon
                                style="cursor:pointer;"
                                color="whitesmoke"
                                size="30"
                            >
                                mdi-heart-outline
                            </v-icon>
                            
                            <v-spacer></v-spacer>  
              
    
                            <v-icon 
                                style="cursor:pointer;" 
                                color="primary"
                                size="30"
                            >
                                mdi-bookmark-outline
                            </v-icon>
                        
                        </v-row>
    
                    </v-list-item>
    
                </v-card-actions>
            
            </v-card> 
     -->
    
      
      
    
    
    
            </div>
    
    
            <div class="text-center">
                
                <v-progress-circular
                    v-if="loadMoreVideosProgress"
                    :size="50"
                    color="blue"
                    dark
                    indeterminate>
                </v-progress-circular>
    
                <br><br>
                
    
                                <v-btn
                                v-if="!loadMoreVideosProgress && !noMorevideos"
                rounded
                color="primary"
                dark
                @click="searchVideosAPI()"
                >
                    Load more videos
                </v-btn>
    
    
                <v-chip
                    v-if="noMorevideos"
                    class="ma-6 pa-6"
                    color="blue"
                    outlined
                >
                    <v-icon left>mdi-blinds</v-icon>
                    <b>
                        No More Videos
                    </b>
                    
                </v-chip>

    
                <br><br>
            
                
            
            </div>
    
           
            <br>
            <br>
            <br>
                </div>
    
    
                    <v-flex xs12 md8 v-if="searchResultsNotFound" justify-space-around>
    
                        <v-img
                        src="https://thumbs.gfycat.com/FrigidMinorHorseshoecrab-size_restricted.gif"
                        ></v-img>
                        <p style="padding: 5%;">No Results Found !</p>
    
                    </v-flex>
                
                </v-flex>
                
                <v-flex xs11 md4>
       
                    <v-card 
                        :flat="cardFlatFlag"
                        :outlined="cardOutlineFlag"
                        class="mx-auto sticky"
                        v-if="userData.signedIn && userData.profile.account === 'Student'"
                    >
                
                        <v-list-item two-line class="RightSideAd">
                            
                            <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                            
                            <v-list-item-content>
                                
                                <v-list-item-title>Google Ads</v-list-item-title>
                                
                                <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                            
                            </v-list-item-content>
                                        
                            <v-btn color="primary" size="35" tile large icon router >
                                <v-icon left> mdi-compass-outline</v-icon>
                            </v-btn>
    
    
                        </v-list-item>
                        
                            <div class="ad-container">
                                <Adsense
                                    style="display:block"
                                    data-ad-client="ca-pub-2789565594210548"
                                    data-ad-slot="3030605753"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"
                                ></Adsense>
                            </div>
            
                    </v-card> 
                  
                </v-flex>
    
                <v-container v-if="searchResultsVideos.length != 0" fluid class="ma-2 hidden-sm-and-down">
                    <v-row dense>
                        <v-col
                        v-for="item in searchResultsVideos"
                        :key="item.title"
                        cols="4"
                        >
                        <v-card 
                        :flat="cardFlatFlag"
                            :outlined="cardOutlineFlag"
                        style="margin: 10px;border-radius: 3%;" >
                    
                            <router-link style="text-decoration: none;color:black;" :to="'/watch?v=' + item._id" >
    
                                
                                    <v-img 
                                        :src="item.thumbnailUrl"
                                        class="white--text align-end videoRecommendationThubmnail"
                                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                        height="250px"
                                        style="border-radius: 3%;"
                                    >
                                        <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="white--text">
                                            {{ item.title }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="white--text"
                                            >{{ item.viewsLength }} Views</v-list-item-subtitle
                                            >
                                        </v-list-item-content>
                                        </v-list-item>
                                    </v-img>
                                
                                </router-link>
                        </v-card>
    
                        <v-list-item two-line>
                                
                                <v-list-item-avatar><img :src="item.userId.profile.profilePhoto" /></v-list-item-avatar>
                                
                                <v-list-item-content>
                                    
                                    <router-link style="text-decoration: none;color:black;" :to="'/' + item.userId._id">
                                        <v-list-item-title>{{ item.userId.profile.name }}</v-list-item-title>
                                    </router-link>
                                    
                                    <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                                
                                </v-list-item-content>
                            
                            </v-list-item>
    
                        </v-col>

                        <v-col
                        cols="12"
                        > 
                        <div class="text-center">
                
                <v-progress-circular
                    v-if="loadMoreVideosProgress"
                    :size="50"
                    color="blue"
                    dark
                    indeterminate>
                </v-progress-circular>
    
                <br><br>
                
    
                                <v-btn
                                v-if="!loadMoreVideosProgress && !noMorevideos"
                rounded
                color="primary"
                dark
                @click="searchVideosAPI()"
                >
                    Load more videos
                </v-btn>
    
                <v-chip
                    v-if="noMorevideos"
                    class="ma-6 pa-6"
                    color="blue"
                    outlined
                >
                    <v-icon left>mdi-blinds</v-icon>
                    <b>
                        No More Videos
                    </b>
                    
                </v-chip>
                
            
            </div>
    

            <br>
            <br>
            <br>

            <br>
                    </v-col>

           

                    </v-row>
                </v-container>
    
            </v-layout>
    
        </v-container>
    
    </div>
    </template>
    
    <style scoped>
    .videoRecommendationThubmnail{
    width: 100%;
    height:350px;
    }
    
    .form-container {
      padding   : 20px;
    }
    
    </style>
    
    <script>
    
    import { mapGetters } from "vuex";
    import utilities from "@/helpers/utilities";
    import apis from "../helpers/apiConfig";
    const axios = require("axios").default;
    
    import firebase from "firebase/app";
    import "firebase/firebase-analytics";
    var firebaseAnlytics;
    
    // Google Adsense    
    import Vue from "vue";
    import Ads from 'vue-google-adsense'
    Vue.use(require('vue-script2'))
    Vue.use(Ads.Adsense)
    Vue.use(Ads.InFeedAdsense)
    
    export default {
        
        name        : "Search",
        
        computed    : { ...mapGetters({ userData: "User/userData" }) },
    
        mounted() {
    
            console.log("this.$route.query : ", this.$route.query);
            console.log("this.$route.query.type : ", this.$route.query.type);
            console.log("this.$route.params.q : ", this.$route.params.q);
    
             if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
            else { this.cardOutlineFlag = true; }
    
    
            // FIREBASE ANLYTICS
            firebaseAnlytics = firebase.analytics();
            firebaseAnlytics.logEvent("Search Page");
    
            this.$store.commit("User/toggleProgressLoader", false);
            this.$store.dispatch("User/updateUserToken", null, { root: true });
            this.$store.commit("User/isSignedIn");
            this.$store.commit("User/toggleProgressLoader", false);
    
            this.getInstitutions();
        
            if (this.$route.params.q == undefined) {
                this.$store.commit("User/toggleProgressLoader", false);
            } else if (this.$route.query?.type === "tags") {
                this.searchResultsVideos = [];
                this.searchResults       = [];
                this.searchQueryForTags = this.$route.params.q;
    
                this.searchVideosAPI();
            }else {
                this.searchResultsVideos = [];
                this.searchResults       = [];
    
                this.searchQuery = this.$route.params.q;
                
                let endPoint = apis.urls.search + "?q=" + this.searchQuery;
                this.$store.commit("User/toggleProgressLoader", true);
                
                axios(endPoint, {
                    method          : "get",
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
    
                    console.log("serach result from back : ", res.data.result.serachResult);
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    let data = res.data.result.serachResult;
                    this.searchResults = data;
                    
                    if (this.searchResults.length == 0) {
                        this.searchResultsNotFound = true;
                    }
                    
                    console.log("data for email ", data);
                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Search Results Displayed");
                
                })
                .catch((err) => {
              
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in Search",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });
            
            }
    
        },
    
        data() {
            
            return {
                cards: [
                    { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 3 },
                    { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 3 },
                    { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 3 },
                    { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 3 },
                    { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 3 },
                    { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 3 },
                    { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 3 },
                    { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 3 },
                    { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 3 },
                ],
                limit: 21,
                offset: 0,
                loadMoreVideosProgress: false,
                  cardFlatFlag    : true,
                cardOutlineFlag : false,
                noMorevideos: false,
                
                searchResults           : [],
                searchResultsVideos     : [],
                searchResultsNotFound   : false,
                searchQuery             : "",
                searchQueryForTags      : "",
                listOfskills            : utilities.listOfskills,
                institutionList         : [],    
                selectedFilter          : 0,
                selectedInstitution     : 0,
                filterOptions           : [
                    {id: 0, name: "None"},
                    {id: 1, name: "Latest Videos"},
                    {id: 2, name: "Oldest Videos"},
                    {id: 3, name: "Top score Videos"},
                    {id: 4, name: "Low score Videos"},
                ]
            };
    
        },
    
        methods: {
            searchVideosAPI() {
                this.loadMoreVideosProgress = true;
    
                let endPoint = apis.urls.searchVideos + "?q=" + this.searchQueryForTags +"&offset="+ this.offset +"&limit=" + this.limit;
                this.$store.commit("User/toggleProgressLoader", true);
                
                axios(endPoint, {
                    method          : "get",
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
    
                    this.loadMoreVideosProgress = false;
    
                    console.log("serach result of videos from back : ", res.data.result.serachResult);
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    let data = res.data.result.serachResult;
                    
                    console.log("length of data : ", data.length);
                    
                    if (data.length === 0 && this.offset === 0) {
                        this.searchResultsNotFound = true;
                    } else {
                        data.forEach(element => {
                            this.searchResultsVideos.push(element)
                        });
                    }
    
                    if(data.length < this.limit || this.offset > 4999) {
                        this.noMorevideos = true;
                        console.log("There are no more videos");
                        // this.$store.commit("User/showErrorAlert", "There are no more videos");
                    } else
                    
                    console.log("data for email ", data);
                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Search Results Displayed");
    
                    this.offset = this.offset + this.limit;
                })
                .catch((err) => {
                    this.loadMoreVideosProgress = false;
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in Search",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });
            },
            getInstitutions()
            {
                let endPoint = apis.urls.getInstitutions;
                this.$store.commit("User/toggleProgressLoader", true);
                
                axios(endPoint, {
                    method          : "get",
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
                    console.log("Institutions data : ",res);
                    
                    let data = res.data.result.data;
                    this.institutionList = data;
    
                    this.institutionList.push(
                        {_id: 0, name: "All"},
                    )
                    this.$store.commit("User/toggleProgressLoader", false);
    
                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Institution Data Displayed");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in institution data",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });
            },
    
            typingInProfileSearch: function() {
                this.searchQueryForTags = ""
            },
    
            typingInTags: function() {
                this.searchQuery = ""
            },
    
    
            pressEnter: function() {
                
                window.location.href = "/search/" + this.searchQuery;
            },
    
            pressEnterForTags: function() {            
                window.location.href = "/search/" + this.searchQueryForTags + "?type=tags";
            }
    
            
        }
        
    };
    </script>
    
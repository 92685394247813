<template>
    <div>
      <SigninPopup v-if="isLocalHost" />
      <SigninRedirect v-if="!isLocalHost" />
    </div>
  </template>
  
  <script>
  //vuex dependency
  import { mapGetters } from "vuex";
  import apis from "@/helpers/apiConfig";
  
  import SigninPopup from "@/views/SigninPopup";
  import SigninRedirect from "@/views/SigninRedirect";
  
  export default {
    name: "Signin",
    components: { SigninPopup, SigninRedirect },
    computed: {
      ...mapGetters({
        userData: "User/userData",
      }),
    },
    mounted() {
      console.log("this.isLocalHost : ", this.isLocalHost);

    },
    data() {
      return {
        isLocalHost: apis.env === "local" ? true : false,
      };
    },
  };
  </script>
  
<template>
    <div>
                  <!-- Sign in with us Dialogue -->
      <v-dialog v-model="signInWithUsDialog" persistent max-width="350">
                <v-card>
                    <v-card-title class="headline"> Sign in required to proceed </v-card-title>
                    <v-card-text>After Sign in 
                      <span style="color: red;"> redirect to Recharge & Payment History </span>
                        to make payment.</v-card-text>
                    
                    <v-card-actions>
                    
                        <v-spacer></v-spacer>
                        
                        <v-btn color="green darken-1" text @click="signInWithUsDialog = false"> Not Now </v-btn>
                        <v-btn color="green darken-1" text router @click="signInWithUsRedirect"> Sign in </v-btn>
                    
                    </v-card-actions>
                </v-card>
            </v-dialog>


      <button style="display:none;" id="rzp-button1">Pay</button>
      
      <v-container>
        <v-layout row wrap justify-space-around>

          <v-flex xs12 md6>
            <v-flex xs12 md12 v-if="userData.signedIn && !allowPayment">
              <v-card outlined flat>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="headline" style="color: green;" v-text="'You have an Active Recharge '"></v-card-title>
                        <v-card-subtitle>
                          You have balance remained total of <span style="color: green"> <b> {{ userSubscription[userSubscription.length-1].balance }} </b> </span>
                          <b> 
                            , so you can still upload
                            {{ userSubscription[userSubscription.length-1].balance }} Videos
                          </b>
                        </v-card-subtitle>
                    </div>
                    
                    <v-avatar class="ma-3" size="150" tile>
                        <v-img :src="vectorImages.signout"></v-img>
                    </v-avatar> 
                </div>

                
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="headline" style="color: #2196F3;" v-text="'You can do the next payment either one of the following case'"></v-card-title>
                        <v-card-subtitle>
                          <ul>
									<li>
										Your video upload balance reaches to <span style="color: red">  0 (zero credits) </span>
									</li>
									<li>
										After your subscription expires i.e. <span style="color: red"> after {{ new Date(this.userSubscription[this.userSubscription.length - 1].expiryDate).toDateString()}} </span>
									</li>
								</ul>
                        </v-card-subtitle>
                    </div>                    
                </div>

            

            <v-expansion-panels flat>
              <v-card-title class="headline" style="color: #2196F3;" v-text="'Meanwhile you can also explore below benifits'"></v-card-title>

<v-expansion-panel>
  <v-expansion-panel-header>IEB</v-expansion-panel-header>
  <v-expansion-panel-content>Get to know what industry wants</v-expansion-panel-content>
</v-expansion-panel>


<v-expansion-panel>
  <v-expansion-panel-header>Recruiters</v-expansion-panel-header>
  <v-expansion-panel-content>Acess to openings of all recruiters</v-expansion-panel-content>
</v-expansion-panel>

<v-expansion-panel>
  <v-expansion-panel-header>Benefits on Preskilet Platform</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-simple-table>
        <thead>
            <tr>
                <th class="text-left">Title</th>
                <th class="text-left">Description</th>
            </tr>
        </thead>
        
        <tbody>
          <tr v-for="offer in benefitsToStudent.preskilet" :key="offer.title">
            <td> {{ offer.title }} </td>
            <td> {{ offer.description }} </td>
            </tr>
        </tbody>
      </v-simple-table>
  </v-expansion-panel-content>
</v-expansion-panel>

<v-expansion-panel>
  <v-expansion-panel-header>Benefits on Curiculo Live Platform</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-btn depressed outlined>
        <a
        href="https://curiculo.live/"
        style="text-decoration:none;"
        class="pa-2"
        target="_blank"
        >
        Open Curiculo live
        <v-icon color="primary" size="20">mdi-launch</v-icon>
      </a>

      </v-btn>
    
      <v-simple-table>
        <thead>
            <tr>
                <th class="text-left">Title</th>
                <th class="text-left">Description</th>
            </tr>
        </thead>
        
        <tbody>
          <tr v-for="offer in benefitsToStudent.curiculoLive" :key="offer.title">
            <td> {{ offer.title }} </td>
            <td> {{ offer.description }} </td>
            </tr>
        </tbody>
      </v-simple-table>
  </v-expansion-panel-content>
</v-expansion-panel>
</v-expansion-panels>

            </v-card>
            </v-flex>

            <v-flex xs12 md12 v-if="userData.signedIn && userSubscription[userSubscription.length-1].balance === 0 || (new Date(userSubscription[userSubscription.length-1]?.expiryDate) < new Date(Date.now()))">
              <v-card flat>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title class="headline" style="color: red;" v-text="'Your recharge is expired'"></v-card-title>
                        <v-card-subtitle v-if="userSubscription[userSubscription.length-1].balance === 0">
                          Recharge is expired because of <span style="color: red"> <b>credits are finished</b> </span>
                        </v-card-subtitle>
                        <v-card-subtitle v-if="new Date(userSubscription[userSubscription.length-1].expiryDate) < new Date(Date.now())">
                          Recharge is expired because of <span style="color: red"> <b>expiry date is passed</b> </span>
                        </v-card-subtitle>
                    </div>
                    
                    <v-avatar class="ma-3" size="150" tile>
                        <v-img :src="vectorImages.signout"></v-img>
                        <!-- <v-img :src="vectorImages.signout"></v-img> -->
                    </v-avatar> 
                </div>
            </v-card>
            </v-flex>

            <v-flex xs12 md12 v-if="userData.signedIn && allowPayment">
              <v-card flat>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <v-avatar class="ma-3" size="150" tile>
                        <v-img :src="emptyStateImage"></v-img>
                    </v-avatar> 

                    <div>
                        <v-card-title class="headline" style="color: #2196F3;" v-text="'Please select your plan from below'"></v-card-title>
                        <v-card-subtitle>
                          <i> by selecting plan you agree to our
                            <router-link router to="/paymentdeclaration">Payment Declaration</router-link>
                          </i>
                        </v-card-subtitle>
                    </div>                    
                </div>
            </v-card>
            </v-flex>
            
            <v-card
                class="mx-auto my-12 ma-3"
                outlined
                v-if="(!userData.signedIn) || (userData.signedIn && allowPayment)"
                v-for="singlePlan in plans"
                :key="singlePlan.name"
              >
                <v-list two-line >
                  <v-list-item>

                    <v-list-item-avatar size="60">
                      <v-btn class="ma-2" color="primary" icon>
                        <v-icon large>{{singlePlan.icon}}</v-icon>
                      </v-btn>
                      <!-- <v-img :src="paymentLog.creditor.profile.profilePhoto"></v-img> -->
                    </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <v-list-item-title>
                                {{singlePlan.name}}
                            </v-list-item-title>
                            
                            <v-list-item-subtitle style="color: red;"> ₹ {{ (singlePlan.pricing.itemTotal / 12).toFixed(2) }} per month</v-list-item-subtitle>
                        </v-list-item-content>
                        
                        <div class="text-right">
                          <div class="text-center">
                              <v-btn
                                rounded
                                color="primary"
                                dark
                                @click="initiatePayment( singlePlan )"
                              >
                                <v-icon dark left>mdi-wallet</v-icon>
                                Pay
                              </v-btn>
                            </div>
                        </div>

                    </v-list-item>
                </v-list>
              
              <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left" style="padding-left: 1%;">Annual</th>
                            <th class="text-left" style="padding: 0%;">SGST - 9%</th>
                            <th class="text-left" style="padding: 0%;">CGST - 9%</th>
                            <th class="text-left" style="padding: 0%;">Transaction Fee - 3%</th>
                            <th class="text-left" style="padding: 0%;">Total Amount</th>
                        </tr>
                    </thead>
                        
                    <tbody>
                        <tr>
                            <td style="padding-left: 1.5%;">₹ {{ singlePlan.pricing.itemTotal }}</td>
                            <td style="padding: 0%;">₹ {{ singlePlan.pricing.sgst }}</td>
                            <td style="padding: 0%;">₹ {{ singlePlan.pricing.cgst }}</td>
                            <td style="padding: 0%;">₹ {{ singlePlan.pricing.paymentGatewayFee }}</td>
                            <td style="padding: 0%;">₹ {{ singlePlan.pricing.grandTotal }}</td>
                        </tr>
                    </tbody>
              </v-simple-table>
                
                <v-divider></v-divider>
                                        
                <v-expansion-panels flat>

                  <v-expansion-panel>
                    <v-expansion-panel-header>IEB</v-expansion-panel-header>
                    <v-expansion-panel-content>Get to know what industry wants</v-expansion-panel-content>
                  </v-expansion-panel>


                  <v-expansion-panel>
                    <v-expansion-panel-header>Recruiters</v-expansion-panel-header>
                    <v-expansion-panel-content>Acess to openings of all recruiters</v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>Benefits on Preskilet Platform</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <thead>
                              <tr>
                                  <th class="text-left">Title</th>
                                  <th class="text-left">Description</th>
                              </tr>
                          </thead>
                          
                          <tbody>
                            <tr v-for="offer in benefitsToStudent.preskilet" :key="offer.title">
                              <td> {{ offer.title }} </td>
                              <td> {{ offer.description }} </td>
                              </tr>
                          </tbody>
                        </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>Benefits on Curiculo Live Platform</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-btn depressed outlined>
                          
                          <a
                          href="https://curiculo.live/"
                          style="text-decoration:none;"
                          class="pa-2"
                          target="_blank"
                          >
                          Open Curiculo live   
                          <v-icon color="primary" size="20">mdi-launch</v-icon>
                        </a>

                        </v-btn>
                      
                        <v-simple-table>
                          <thead>
                              <tr>
                                  <th class="text-left">Title</th>
                                  <th class="text-left">Description</th>
                              </tr>
                          </thead>
                          
                          <tbody>
                            <tr v-for="offer in benefitsToStudent.curiculoLive" :key="offer.title">
                              <td> {{ offer.title }} </td>
                              <td> {{ offer.description }} </td>
                              </tr>
                          </tbody>
                        </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-simple-table v-if="singlePlan.benefits">
                    <thead>
                        <tr>
                            <th class="text-left">Validity</th>
                            <th class="text-left">Video Credits on Preskilet</th>
                            <th class="text-left">Edits on Curiculo Live</th>
                            <th class="text-left">Ads</th>
                        </tr>
                    </thead>
                        
                    <tbody>
                        <tr>
                          <td>{{ singlePlan.benefits.validity }} Days</td>
                          <td style="color: green;"> <b>{{ (singlePlan.benefits.credits).toString().padStart(3, '0') }}</b> </td>
                          <td style="color: green;">{{ singlePlan.benefits.edits }}</td>
                          <td>{{ singlePlan.benefits.ads ? "Yes" : "No" }}</td>                        
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
				<br>

      </v-flex>
      <v-flex xs11 md5 v-if="!userData.signedIn">
        <v-card class="mx-auto my-12 ma-3" flat>
              <v-card-title >
                <v-btn
                  class="ma-2"
                  color="primary"
                  icon
                >
                  <v-icon large>mdi-history</v-icon>
                </v-btn>
                Your Recharge History
              </v-card-title>
              <v-card-text>
                <div>
                  <ul v-if="userSubscription.length == 0">
                    <li>
                      No data Available
                    </li>
                  </ul>
                </div>
              </v-card-text>
				    </v-card>
      </v-flex>
          <v-flex xs11 md5 v-if="userData.signedIn">

            <v-card 
                        :flat="cardFlatFlag"
                        :outlined="cardOutlineFlag"
                        class="mx-auto sticky"
                        v-if="userData.profile.account === 'Student'"
                    >
                
                        <v-list-item two-line class="RightSideAd">
                            
                            <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                            
                            <v-list-item-content>
                                
                                <v-list-item-title>Google Ads</v-list-item-title>
                                
                                <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                            
                            </v-list-item-content>
                                        
                            <v-btn color="primary" size="35" tile large icon router >
                                <v-icon left> mdi-compass-outline</v-icon>
                            </v-btn>
    
    
                        </v-list-item>
                        
                            <div class="ad-container">
                                <Adsense
                                    style="display:block"
                                    data-ad-client="ca-pub-2789565594210548"
                                    data-ad-slot="2961311238"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"
                                ></Adsense>
                            </div>
            
            
                    </v-card>
         
            <v-card class="mx-auto my-12 ma-3" flat>
              <v-card-title >
                <v-btn
                  class="ma-2"
                  color="primary"
                  icon
                >
                  <v-icon large>mdi-history</v-icon>
                </v-btn>
                Your Recharge History
              </v-card-title>
              <v-card-text>
                <div>
                  <ul v-if="userSubscription.length == 0">
                    <li>
                      No data Available
                    </li>
                  </ul>
                </div>
              </v-card-text>
				    </v-card>

            <v-card
              class="mx-auto my-12 ma-3"
              outlined
              v-for="paymentLog in userSubscription.slice().reverse()"
              :key="paymentLog._id"
            >

              <v-list two-line v-if="paymentLog?.creditor?.email === userData.email">
                  <v-list-item :key="paymentLog?.creditor?.email">
                      
                      <v-list-item-avatar size="60">
                          <v-img :src="paymentLog.creditor.profile.profilePhoto"></v-img>
                      </v-list-item-avatar>
                      
                      <v-list-item-content>
                          <v-list-item-title>
                              {{paymentLog.creditor.profile.name}}
                          </v-list-item-title>
                          
                          <v-list-item-subtitle> Recharge is done by You</v-list-item-subtitle>
                      </v-list-item-content>
                      
                      <div class="text-right">
                        <v-list-item-content>
                          <v-list-item-title> {{ paymentLog.planName }} </v-list-item-title>
                          <v-list-item-subtitle> plan </v-list-item-subtitle>
                        </v-list-item-content>
                      </div>

                  </v-list-item>
              </v-list>

              <v-list two-line v-if="paymentLog.creditorInstitution">
                <v-list-item :key="paymentLog.creditorInstitution.name">
                    
                    <v-list-item-avatar size="60">
                        <v-img :src="paymentLog.creditorInstitution.logo"></v-img>
                    </v-list-item-avatar>
                    
                    <v-list-item-content>
                        <v-list-item-title>
                            {{paymentLog.creditorInstitution.name}}
                            <a
                              :href="paymentLog.creditorInstitution.websiteLink"
                              style="text-decoration:none;"
                              class="pa-2"
                              target="_blank"
                              >
                              <v-icon color="primary" size="20">mdi-launch</v-icon>
                            </a>
                        </v-list-item-title>
                        <v-list-item-subtitle> Recharge is done by above institution </v-list-item-subtitle>
                    </v-list-item-content>

                    <div class="text-right">
                      <v-list-item-content>
                        <v-list-item-title> {{ paymentLog.planName }} </v-list-item-title>
                        <v-list-item-subtitle> plan </v-list-item-subtitle>
                      </v-list-item-content>
                    </div>

                </v-list-item>
              </v-list>
             
              <v-simple-table v-if="paymentLog.paymentId">
                  <thead>
                      <tr>
                          <th class="text-left">Item Total</th>
                          <th class="text-left">SGST</th>
                          <th class="text-left">CGST</th>
                          <th class="text-left">Payment Gateway Fee</th>
                          <th class="text-left">Grand Total</th>
                      </tr>
                  </thead>
                      
                  <tbody>
                      <tr>
                          <td>{{ paymentLog.paymentId.itemTotal }}</td>
                          <td>{{ paymentLog.paymentId.sgst }}</td>
                          <td>{{ paymentLog.paymentId.cgst }}</td>
                          <td>{{ paymentLog.paymentId.paymentGatewayFee }}</td>
                          <td>{{ paymentLog.paymentId.grandTotal }}</td>
                      </tr>
                  </tbody>
              </v-simple-table>
              
              <v-divider v-if="paymentLog.paymentId"></v-divider>
                                      
              <v-expansion-panels flat>
                <v-expansion-panel v-if="paymentLog.benefits">
                  <v-expansion-panel-header>Benefits you got</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <thead>
                            <tr>
                              <th class="text-left">Validity</th>
                              <th class="text-left">Video Credits on Preskilet</th>
                              <th class="text-left">Edits on Curiculo Live</th>
                              <th class="text-left">Ads</th>
                            </tr>
                        </thead>
                        
                        <tbody>
                          <tr>
                            <td>{{ paymentLog.benefits.validity }} Days</td>
                            <td style="color: green;"> <b>{{ (paymentLog.benefits.credits).toString().padStart(3, '0') }}</b> </td>
                            <td style="color: green;">{{ paymentLog.benefits.edits }}</td>
                            <td>{{ paymentLog.benefits.ads ? "Yes" : "No" }}</td>
                          </tr>
                        </tbody>
                       </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>Remarks</v-expansion-panel-header>
                  <v-expansion-panel-content>{{ paymentLog.remark }}</v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>

              <v-simple-table>
                  <thead>
                      <tr>
                          <th class="text-left">Recharge Date</th>
                          <th class="text-left">Expiry Date</th>
                          <th class="text-left">Video Credits</th>
                          <th class="text-left">Balance</th>
                      </tr>
                  </thead>
                      
                  <tbody>
                      <tr>
                        <td>{{ new Date(paymentLog.date).toDateString() }}</td>
                        <td>{{ new Date(paymentLog.expiryDate).toDateString() }}</td>
                        <td> <b>{{ (paymentLog.credits).toString().padStart(3, '0') }}</b> </td>
                        <td>{{ paymentLog.balance }}</td>
                      </tr>
                  </tbody>
              </v-simple-table> 
          </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </template>
  
  <style scoped>
  .form-container {
    padding: 20px;
  }
  </style>
  
  <script>
  //vuex dependancy
  import { mapGetters } from "vuex";
  // Other dependancies
  import utilities from "@/helpers/utilities";
  import apis from "../helpers/apiConfig";
  const axios = require("axios").default;
 
  
  export default {
    name: "Payments",
    computed: {
      ...mapGetters({
        userData: "User/userData",
      }),
    },
    mounted() {
      this.$store.commit("User/toggleProgressLoader", false);
      this.$store.dispatch("User/updateUserToken", null, { root: true });
      this.$store.commit("User/isSignedIn");
      this.$store.commit("User/toggleProgressLoader", false);

      if(this.userData.signedIn) {
        this.getUserSubscription()
        this.getUserPayments()  
        this.loadPayButton();
      }

      
      if (this.userData.accountType == "Organization") {
        this.emptyStateMessage = "Pay for Monthly Subscription."
      }
  
	    console.log("subscription length:", this.userData);

      //Calculating Pricing
      // const allPlansList = Object.keys(this.plans);
      // allPlansList.forEach(singlePlan => {
      //   console.log("singlePlan : ", singlePlan);
      //   const basicItemTotal = parseFloat(this.plans[singlePlan].pricing.itemTotal);
      //   const sgst = parseFloat((basicItemTotal * 9 / (100)).toFixed(2))
      //   const cgst = parseFloat((basicItemTotal * 9 / (100)).toFixed(2))
      //   const paymentGatewayFee = parseFloat(((basicItemTotal + sgst + cgst) * 3 / (100)).toFixed(2));
      //   const grandTotal = parseFloat((basicItemTotal + sgst + cgst + paymentGatewayFee).toFixed(2));
        
      //   this.plans[singlePlan].pricing = {
      //     itemTotal: basicItemTotal,
      //     sgst, 
      //     cgst,
      //     paymentGatewayFee,
      //     grandTotal,
      //   }
      // });
    },
    data() {
      return {
        signInWithUsDialog: false,
        benefitsToStudent: {
          preskilet: [
            {title: "Watch", description: "Watch as many videos as you want"},
            {title: "Grade", description: "Grade as many videos you can "},
            {title: "Get Graded", description: "Get gradd by as many users you want"},
          ],
          curiculoLive: [
            {title: "Explore", description: "Explore as many resumes as you want"},
            {title: "Share", description: "Share you resume with as many recruiters as you want, on-campus, off-campus, Preskilet Campus"},
            {title: "Save", description: "Save as many resumes as you want"},
            {title: "Apply", description: "Apply to unlimited openings"},
          ],
        },
        benefitsToTeacher: {},
        plans: {
          BASIC: {
            pricing: {
              itemTotal: 199,
              sgst: 17.91, 
              cgst: 17.91,
              paymentGatewayFee: 6.73,
              grandTotal: 241.55,
            },
            
            icon: "mdi-cash",
            name: "BASIC",
            benefits: {
              credits: 6,
              edits: 100,
              ads: true,
              validity: 365,
            },
          },
          STANDARD: {
            pricing: {
              itemTotal: 299,
              sgst: 26.91, 
              cgst: 26.91,
              paymentGatewayFee: 10.12,
              grandTotal: 362.94,
            }, 
            
            icon: "mdi-cash-multiple",
            name: "STANDARD",
            benefits: {
              credits: 12,
              edits: 300,
              ads: true,
              validity: 365,
            },
          },
          PREMIUM: {
            pricing: {
              itemTotal: 499,
              sgst: 44.91, 
              cgst: 44.91,
              paymentGatewayFee: 16.88,
              grandTotal: 605.70,
            }, 

            icon: "mdi-credit-card-outline",
            name: "PREMIUM",
            benefits: {
              credits: 500,
              edits: 500,
              ads: false,
              validity: 365,
            },
          },
        },
        
        payments: [],
        payButton: false,
        emptyStateImage: utilities.emptyState.payments.image,
        emptyStateMessage: utilities.emptyState.payments.message,
        userSubscription : "",
        allowPayment : true,
        paymentsArray : [],
        vectorImages    : utilities.vectorImages,
      };
    },
    methods: {
    signInWithUsRedirect() { window.location.replace("/signin") },
		getUserSubscription() {
            let endPoint = apis.urls.getUserSubscription;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
              console.log("GetUserSubscriptionDetails: ", res);
                let data = res.data.result.data.subscription;
                this.userSubscription = data;
				let today = new Date();
				let expiryDate = new Date(this.userSubscription[this.userSubscription.length -1]?.expiryDate)

				if(this.userSubscription.length == 1 || this.userSubscription[this.userSubscription.length -1].balance === 0 || expiryDate < today) {
					this.allowPayment = true
				} else {
					this.allowPayment = false
				}
                this.$store.commit("User/toggleProgressLoader", false);
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Following",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

		},

		getUserPayments() {
            let endPoint = apis.urls.getPaymentHistory;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                let data = res.data.result.data;
                this.paymentsArray = data;
			    this.$store.commit("User/toggleProgressLoader", false);
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Following",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

		},

		getFormattedDate(d){
					
			// Convert expiryDate to a Date object
			var date = new Date(d);

			// Extract day, month, and year
			var day = date.getDate();
			var month = date.getMonth() + 1; // Month starts from 0, so adding 1
			var year = date.getFullYear();

			// Pad day and month with leading zeros if needed
			day = day < 10 ? '0' + day : day;
			month = month < 10 ? '0' + month : month;

			// Format the date as "dd-mm-yyyy"
			var formattedDate = day + '/' + month + '/' + year;
			return formattedDate

		},

		getTotalAmount(amount) {
			const sgst = (amount * 9) / 100;
			const cgst = (amount * 9) / 100;
			const processingFee = ((cgst+sgst+amount) * 3) / 100;
			const totalAmount = amount + sgst + cgst + processingFee;
			return totalAmount.toFixed(2);
    	},


      async loadPayButton() {
        const res = await this.loadRazorpayScript();
        if (!res) {
          alert("Razorpay SDK failed , Are you online ?");
          this.payButton = false;
          return;
        } else {
          this.payButton = true;
        }
      },
      loadRazorpayScript() {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = "https://checkout.razorpay.com/v1/checkout.js";
          script.onload = () => {
            resolve(true);
          };
          script.onerror = () => {
            resolve(false);
          };
          document.body.appendChild(script);
        });
      },
      initiatePayment(singlePlan) {
        if(this.userData.signedIn) {
          this.pay(singlePlan);
        } else {
          this.signInWithUsDialog = true;
        }
      },
      pay(singlePlan) {
        this.$store.commit("User/toggleProgressLoader", true);
        let endPoint = apis.urls.getRazorpayOrder;
        
        let that = this;

        axios(endPoint, {
          method: "get",
          params: { grandTotal: singlePlan.pricing.grandTotal },
          withCredentials: true,
          headers: {
            authorization: this.userData.token,
          },
        })
        .then((res) => {
          
          let data = res.data.result.data;
          
        
          var options = {
              key: data.frontendkey,
              amount: data.amount,
              currency: data.currency,
              name: "Preskilet",
              description: "Payment for preskilet "+singlePlan.name+ " Account",
              image:
                "https://firebasestorage.googleapis.com/v0/b/preskilet.appspot.com/o/assets%2Flogo.png?alt=media&token=e40fcc85-bc5e-443f-b71a-9338a3c6f6ac",
              order_id: data.id,
              handler: function(response) {
                
                // payment success
                console.log("response by handler :", response)
                console.log("data in handler :", data)

                let successEndPoint = apis.urls.razorPayPaymentSuccess;
                
                let successData = {
                  planDetails: singlePlan,
                  orderId:data.id,
                  razorpayResponse:response
                }

                console.log("successData:", successData);
                that.$store.commit("User/toggleProgressLoader", true);

                axios(successEndPoint, {
                  method: "post",
                  data: successData,
                  withCredentials: true,
                  headers: { authorization: that.userData.token }
                }).then((res) => {
                        
                  console.log("success : ", res.data.result.message);
                  that.$store.commit("User/toggleProgressLoader", false);
                  that.$store.commit("User/showSuccessAlert", res.data.result.message);
                  window.location.reload()
                  
                })
                .catch((err) => {
                  
                  that.$store.commit("User/toggleProgressLoader", false);
                  let errorMsg = utilities.errorHandler(err);
                  console.log("Error in adding video credits after recharge",err);
                  that.$store.commit("User/showErrorAlert", errorMsg);
                
                });

              },
              modal: {
                escape: false,
                ondismiss: function() {
                    // action after dismissing of dialog box
                    that.$store.commit("User/showErrorAlert", "Payment Incomplete");
                },
              },
              prefill: {
                name: data.name,
                email: data.email,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#651FFF",
              },
            };

            var rzp1 = new window.Razorpay(options);

            document.getElementById("rzp-button1").onclick = function(e) {
              rzp1.open();
              e.preventDefault();
            };
            document.getElementById("rzp-button1").click();

            this.$store.commit("User/toggleProgressLoader", false);
          })
          .catch((err) => {
            this.$store.commit("User/toggleProgressLoader", false);
            let errorMsg = utilities.errorHandler(err);
  
            this.$store.commit("User/showErrorAlert", errorMsg);
          });
      },
    },
  };
  </script>
  
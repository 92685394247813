<template>
<div>

    <v-btn color='primary' v-if="installButton" @click="promptInstallBanner()">
        Install
    </v-btn>

</div>
</template>

<script>

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

export default {
    
    name    : "InstallApp",
    data() {

        return {

            installButton   : false,
            dPrompt         : ''

        }

    },
    
    created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("InstallApp Component");

        var that = this

        // App install Prompt
        window.addEventListener("beforeinstallprompt", function(e) {

            that.dPrompt = e;
            console.log("deferredPrompt : ", that.dPrompt);
            
            if (that.dPrompt === undefined) {
                that.installButton = false;
            } else {

                e.preventDefault();
                that.installButton = true;

            }

        })
        
        window.addEventListener('appinstalled', (evt) => {
            that.installButton = false;
        });

    },
    
    methods: {
        
        promptInstallBanner() {
            
            try {
                this.dPrompt.prompt();
            }
            catch {
                console.log("Promt failed, so show install info")
            }
            
            this.dPrompt.userChoice.then((choiceResult) => {
                
                this.dPrompt = undefined
                
                if (choiceResult.outcome === "accepted") {
                    that.installButton = false;
                } else {
                    console.log("User dismissed the A2HS prompt");
                }

            });

        }

    }
    
}

</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('v-container',[_c('v-dialog',{attrs:{"persistent":"","max-width":"560"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Copy link")]),_c('v-card-text',[_vm._v("Click on copy button to copy the link")]),_c('v-text-field',{staticClass:"ma-3",attrs:{"id":"myinput"},model:{value:(_vm.shareableLink),callback:function ($$v) {_vm.shareableLink=$$v},expression:"shareableLink"}}),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"tooltip"},[_c('button',[_c('span',{staticClass:"tooltiptext",attrs:{"id":"myTooltip"}},[_vm._v("Copy to clipboard")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.myLink,"mouseout":_vm.outFunc}},[_vm._v("Copy")])],1)]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")])],1)],1)],1),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"headline blue--text",domProps:{"textContent":_vm._s('Preskilet for Students')}}),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",attrs:{"src":_vm.vectorImages.Welcome}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Preskilet')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'Welcome to preskilet, before you start creating your own videos for your live video profile, go through a brief introduction of sections in our website, it will help you create profile easily.'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{staticStyle:{"border-radius":"10%"},attrs:{"src":_vm.vectorImages.Welcome}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",attrs:{"src":_vm.vectorImages.search}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Search')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'Search people by names with the help of preskilet'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":_vm.vectorImages.search}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",attrs:{"src":_vm.vectorImages.profile}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Profile')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'Profile is one of the most important part of the Preskilet Profile. Make sure you enter corrent information, because if any recruiter likes your Profile, he will try to contact you based on your contact information.'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":_vm.vectorImages.profile}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",staticStyle:{"border-radius":"100%"},attrs:{"src":_vm.vectorImages.pret}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Video')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'Using Video section you can upload your videos.'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":_vm.vectorImages.pret}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",staticStyle:{"border-radius":"100%"},attrs:{"src":_vm.vectorImages.editPret}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Edit Videos')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'In Edit videos section you can edit or delete your previosly uploaded videos.'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"src":_vm.vectorImages.editPret}})],1)],1)],1),_c('br'),_c('v-card-title',{staticClass:"headline blue--text",domProps:{"textContent":_vm._s('Preskilet for Teachers')}}),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",staticStyle:{"border-radius":"100%"},attrs:{"src":_vm.vectorImages.group}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Groups')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'You can create groups (Batch-wise, Division-wise, Department-wise, College-wise) on preskilet to create tasks (assignments) for that group. Interested Users can follow those groups and you will get a group request. As soon as you accept the request, each group member will get notifications whenever you assign task (Assignment) for that group.'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{staticStyle:{"border-radius":"10%"},attrs:{"src":_vm.vectorImages.group}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",staticStyle:{"border-radius":"100%"},attrs:{"src":_vm.vectorImages.task}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Tasks')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'You can create tasks on various topics so that your group members can upload prets (presentation videos) for respective task and then you can grade those presentation videos and generate a grade report in excel sheet.'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{staticStyle:{"border-radius":"10%"},attrs:{"src":_vm.vectorImages.task}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"hidden-md-and-up white--text align-end",staticStyle:{"border-radius":"100%"},attrs:{"src":_vm.vectorImages.excel}}),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s('Download Excel')}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(
                                'You can download excel sheet for a particular group containing grades of every task pret (presentation video for assigned task) along with details of group members such as Registration number, Roll number, Batch name, etc in just a single click!'
                            )}})],1),_c('v-avatar',{staticClass:"ma-3 hidden-sm-and-down",attrs:{"size":"125","tile":""}},[_c('v-img',{staticStyle:{"border-radius":"10%"},attrs:{"src":_vm.vectorImages.excel}})],1)],1)],1),_c('br')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>

        <v-container>

            <v-layout row wrap justify-space-around>

                <v-flex xs11 md6>

                    <v-card color="white" outlined>

                        <v-form ref="form" v-model="valid" lazy-validation class="form-container">

                            <v-textarea v-model="emails" :rules="emailsRules" label="Email List" clearable></v-textarea>
                            <v-text-field v-model="credits" :rules="creditsRules" label="Credits" clearable></v-text-field>
                            <v-text-field v-model="balance" :rules="balanceRules" label="Balance" clearable></v-text-field>
                            <v-text-field v-model="remark" :rules="remarkRules" label="Remark" clearable></v-text-field>

                            <v-select
                                item-text="name"
                                item-value="_id"

                                v-model="selectedInstitution"
                                :items="institutionList"
                                label="Select Institution"
                                data-vv-name="select"
                                :rules="[(v) => !!v || 'Institution is required']"
                                required
                            ></v-select>

                            <v-select
                                item-text="name"
                                item-value="_id"

                                v-model="selectedPlan"
                                :items="plans"
                                label="Select Plan"
                                data-vv-name="select"
                                :rules="[(v) => !!v || 'Plan is required']"
                                return-object
                                required
                            ></v-select>

                            <div class="text-right">

                                <v-btn class="ma-2" color="primary" depressed v-on:click="validate">

                                    <v-icon dark left>mdi-checkbox-marked-circle</v-icon>Save

                                </v-btn>
                            </div>
                        </v-form>
                    </v-card>

                </v-flex>
            </v-layout>

        </v-container>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;
import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

export default {

    name: "AddCredits",
    computed: { ...mapGetters({ userData: "User/userData" }) },

    mounted() {


        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Add credits Page");
        this.getInstitutions();
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

    },

    data() {
        return {
            valid: true,

            emails: "",
            emailsRules: [
                (v) => !!v || "Email List is required"
            ],

            credits: "",
            creditsRules: [
                (v) => !!v || "Credit is required"
            ],

            balance: "",
            balanceRules: [
                (v) => !!v || "Balance is required"
            ],

            remark: "",
            remarkRules: [
                (v) => !!v || "Remark is required"
            ],

            institutionList : [],
            selectedInstitution:"",

            plans: [

                {
                    _id:1,
                    name : "DEFAULT",
                    benefits: {
                        edits: 100,
                        ads: true,
                        validity: 365,
                    },
                },
                {
                    _id:2,
                    name : "BASIC",
                    benefits: {
                        edits: 100,
                        ads: true,
                        validity: 365,
                    },
                },
                {
                    _id:3,
                    name :"STANDARD",
                    benefits: {
                        edits: 300,
                        ads: true,
                        validity: 365,
                    },
                },
                {
                    _id:4,
                    name:"PREMIUM",
                    benefits: {
                        edits: 500,
                        ads: false,
                        validity: 365,
                    },
                },
            ],
            selectedPlan:"",

        }
    },

    methods: {

        getInstitutions()
        {
            let endPoint = apis.urls.getInstitutions;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                console.log("Institutions data : ",res);
                
                let data = res.data.result.data;
                this.institutionList = data;
                this.$store.commit("User/toggleProgressLoader", false);

                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Institution Data Displayed");
            
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in institution data",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });
        },

        validate() {

            this.$refs.form.validate();

            if (this.$refs.form.validate()) {
                this.addCredits()
            }

        },

        addCredits() {

            this.$store.commit("User/toggleProgressLoader", false);
            let data = {
                emails: this.emails,
                credits: this.credits,
                balance: this.balance,
                remark: this.remark,
                institutionId:this.selectedInstitution,
                plan: this.selectedPlan
            };

            console.log("Profile setup data : ", data);
            let endPoint = apis.urls.addCredits;
            this.$store.commit("User/toggleProgressLoader", true);

            axios(endPoint, {
                method: "post",
                data,
                withCredentials: true,
                headers: { authorization: this.userData.token }
            })
                .then((res) => {

                    console.log("success : ", res.data.result.message);
                    this.$store.commit("User/toggleProgressLoader", false);
                    this.disabled = true;
                    this.$store.commit("User/showSuccessAlert", res.data.result.message);

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Credits added");

                })
                .catch((err) => {

                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error In credits", err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);

                });
        }

    }
}
</script>
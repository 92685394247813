<template>
    <div>

        <v-container>

            <v-layout row wrap justify-space-around>

                <v-flex xs11 md6>
                    <v-card color="white" outlined>

                        <v-card-title>Proxy User Mode View</v-card-title>
                                                
                        <v-card-subtitle v-if="userData.proxyUserEmail">You are currently proxying user : {{ userData.proxyUserEmail }}

                            <v-btn class="ma-2" color="red" depressed v-on:click="stopProxy">
                            <v-icon dark left>mdi-checkbox-marked-circle</v-icon>Stop Proxy
                        </v-btn>

                        </v-card-subtitle>

                        <v-card-title>Click to quick proxy below users</v-card-title>

                        <table>
                            <tr>
                                <th>User name</th>
                                <th>Email id</th>
                                <th>College Name</th>
                                <th>Proxy button</th>
                            </tr>
                            <tr v-for="userItem in quickProxyList">
                                <th>{{ userItem.userName }}</th>
                                <th>{{ userItem.emailId }}</th>
                                <th>{{ userItem.collegeName }}</th>
                                <th>
                                    <v-btn v-if="userItem.emailId !== userData.proxyUserEmail" class="ma-2" color="green" depressed v-on:click="setProxy(userItem.emailId, userItem.accountType)">
                                        Start Proxy
                                    </v-btn>
                                    <v-btn v-if="userItem.emailId === userData.proxyUserEmail" class="ma-2" color="red" depressed v-on:click="setProxy(userItem.emailId)">
                                        Stop Proxy
                                    </v-btn>

                                </th>
                            </tr>
                        </table>

<!-- 
                        <v-card-title>Set new proxy with custom email</v-card-title>
                        

                        <v-form ref="form" v-model="valid" lazy-validation class="form-container">

                            <v-text-field v-model="email" :rules="emailsRules" label="Email of user" clearable></v-text-field>
                            
                            <div class="text-right">

                                <v-btn class="ma-2" color="green" depressed v-on:click="validate">

                                    <v-icon dark left>mdi-checkbox-marked-circle</v-icon>Start Proxy

                                </v-btn>
                            </div>
                        </v-form> -->

                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<style scoped>
.blink_me {
  animation: blinker 1s linear infinite;
  color: red;
  margin: 1%;
  font-weight: 700;
  font-size: 125px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

</style>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

export default {

    name: "ProxyUserMode",
    computed: { ...mapGetters({ userData: "User/userData" }) },

    mounted() {
        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("ProxyUserMode Page");
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
    },

    data() {
        return {
            valid: true,
            email: "",
            emailsRules: [
                (v) => !!v || "Email List is required"
            ],
            quickProxyList: [
               {
                    userName: "S. A. Jain",
                    emailId: "sajain@comp.maepune.ac.in",
                    collegeName: "MIT AOE, Alandi",
                    accountType: "Teacher",
                },
                {
                    userName: "Vijay Patil",
                    emailId: "tpo.dypsst@dpu.edu.in",
                    collegeName: "Dr. D. Y. Patil School of Science and Technology",
                    accountType: "Teacher",
                },
                {
                    userName: "Rudra Patil",
                    emailId: "rgpatil@comp.mitaoe.ac.in",
                    collegeName: "MMCOE",
                    accountType: "Teacher",
                },
                {
                    userName: "Shoaib Shaikh",
                    emailId: "shoaibabdulganishaikh@gmail.com",
                    collegeName: "Preskilet Private Limited",
                    accountType: "Student",
                },                
            ],
        }
    },

    methods: {
        validate() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.setProxy(this.email)
            }
        },
        setProxy(email, proxyAccountType) {
            console.log("setProxy email : ", email);
            console.log("setProxy this.userData.accountType : ", this.userData.accountType);
            if(!email) {
                this.$store.commit("User/showErrorAlert", "No email found for Proxy");
            } if(this.userData.accountType !== proxyAccountType) {
                this.$store.commit("User/showErrorAlert", `Please login by ${proxyAccountType} account`);
            } else 
            {
                this.$store.commit("User/setProxyUser", email);
            }
        },
        stopProxy() {
            this.$store.commit("User/setProxyUser", "");
        }
    }
}
</script>
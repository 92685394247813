var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",style:(`background-color: ${_vm.mergedConfig.core.colors.primary}; margin-top: 1%`)},[_c('v-container',[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":_vm.mergedConfig.core.semiBlock1.cols,"md":_vm.mergedConfig.core.semiBlock1.md,"order-md":_vm.mergedConfig.core.semiBlock1.orderMd,"order":_vm.mergedConfig.core.semiBlock1.order}},[_c('v-card-title',{staticClass:"text-h3 justify-center"},[_c('p',{staticStyle:{"font-family":"'Poppins', sans-serif","font-weight":"700","font-size":"52px","line-height":"125%"}},[_c('span',{style:(`color: ${_vm.mergedConfig.core.colors.primary}; -webkit-text-stroke: 2px ${_vm.mergedConfig.core.colors.secondary}`)},[_vm._v(" "+_vm._s(_vm.mergedConfig.info.title1)+" ")]),_c('span',{style:(`color: ${_vm.mergedConfig.core.colors.secondary}; -webkit-text-stroke: 1px ${_vm.mergedConfig.core.colors.primary}`)},[_vm._v(" "+_vm._s(_vm.mergedConfig.info.title2)+" ")])])]),_c('v-card-title',{staticClass:"justify-center"},[_c('v-card-subtitle',{style:(`
               font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: ${_vm.mergedConfig.core.colors.secondary};
                margin: 0px;
                border: 2px dotted ${_vm.mergedConfig.core.colors.secondary};
                border-radius: 15px;
        `)},[_vm._v(" "+_vm._s(_vm.mergedConfig.info.subTitle)+" ")])],1)],1),_c('v-col',{attrs:{"cols":_vm.mergedConfig.core.semiBlock2.cols,"md":_vm.mergedConfig.core.semiBlock2.md,"order-md":_vm.mergedConfig.core.semiBlock2.orderMd,"order":_vm.mergedConfig.core.semiBlock2.order}},[_c('div',{staticStyle:{"margin-top":"-5%"}},[_c('v-img',{staticStyle:{"margin-left":"auto","margin-right":"auto","height":"100%","width":"100%"},attrs:{"eager":"","src":_vm.mergedConfig.imgUrl}})],1)]),_c('v-col',{attrs:{"cols":_vm.mergedConfig.core.semiBlock3.cols,"md":_vm.mergedConfig.core.semiBlock3.md,"order-md":_vm.mergedConfig.core.semiBlock3.orderMd,"order":_vm.mergedConfig.core.semiBlock3.order}},[_c('v-card',{style:(`background-color: ${_vm.mergedConfig.core.colors.primary}; margin-top: 5%`),attrs:{"max-width":"500","flat":""}},[_c('v-card-subtitle',[_c('p',{style:(`
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: ${_vm.mergedConfig.core.colors.secondary};
                margin: 0px;
              `)},[_vm._v(" "+_vm._s(_vm.mergedConfig.description)+" ")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }